/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados aos circle maps do projeto
 * @typedef {ProjectCircleMapService}
 */
export default class ProjectCircleMapService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'project-circle-maps/';

    getAll (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }
}

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="dialog.open"
    max-width="400px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog.open = toggle(dialog.open)">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ dialog.text }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row wrap>
          <v-col xs="12">
            <supplier-select v-model="supplier" />
            <v-label>Selecione os itens que deseja gerar</v-label>
            <v-checkbox
              v-model="optionsSelected"
              label="Lista de apiários (.pdf)"
              value="apiary-list"
              style="height: 30px"
            />
            <v-checkbox
              v-model="optionsSelected"
              label="Mapas dos apiários (.kml)"
              value="apiary-map-kml"
              style="height: 30px"
            />
            <v-checkbox
              v-model="optionsSelected"
              label="Caderno de campo (.pdf)"
              value="field-notes"
              style="height: 30px"
            ></v-checkbox>
            <v-checkbox
              v-model="optionsSelected"
              label="Formulários aprovados (.pdf)"
              value="forms"
              style="height: 30px"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          style="color: white"
          color="green"
          @click="download"
          :loading="loading"
        >
          Gerar
        </v-btn>
        <v-btn color="error" text @click="dialog.open = toggle(dialog.open)">
          Voltar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NotificationHelper from "@/shared/helpers/NotificationHelpers";
import SupplierSelect from "./components/SupplierSelect.vue";

export default {
  name: "ProjectDate",
  props: [
    "registerUsers",
    "project",
    "projectDates",
    "projectApiary",
    "dialog",
    "projectDocument",
  ],
  components: {
    SupplierSelect,
  },
  data() {
    return {
      token: this.minamelServices.getToken(),
      optionsSelected: [],
      zipFiles: [],
      projectCircleMaps: [],
      supplier: null,
      loading: false,
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  methods: {
    toggle(value) {
      this.optionsSelected = [];

      if (value) {
        return false;
      }
      return true;
    },

    async download() {
      if (!this.optionsSelected || !this.optionsSelected.length) {
        this.snackbar.color = "error";
        this.snackbar.message = "Selecione uma opção para download";
        this.$root.$SnackBar.show(this.snackbar);

        return;
      }
      this.loading = true;

      try {
        const query = {
          params: {
            supplier_id: this.supplier ? this.supplier.id : null,
          },
        };

        let response = await this.minamelServices
          .ProjectServiceV2()
          .getProjectFiles(this.project.id, this.optionsSelected, query);

        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        const filename = this.supplier
          ? this.supplier.name.replaceAll(" ", "_")
          : "download";

        link.href = url;
        link.setAttribute("download", `${filename}.zip`);

        document.body.appendChild(link);

        link.click();
        this.loading = false;
      } catch (err) {
        NotificationHelper.defaultCatchError(err, this);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <v-form ref="form" v-if="register" v-model="formValid" class="ma-2">
    <default-form-layout>
      <template v-slot:title>
        <h2>Editar Menu</h2>
      </template>

      <template v-slot:body>
        <v-row
          ><v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="formRules"
              v-model="register.name"
              label="Nome"
              class="required"
              ref="name"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="formRules"
              v-model="register.route"
              label="Rota"
              class="required"
              ref="route"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :prepend-inner-icon="register.icon"
              v-model="register.icon"
              label="Ícone"
              ref="icon"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text @click="cancel">Cancelar</v-btn>
      </template>
    </default-form-layout>
  </v-form>
</template>

<script>
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";
export default {
  components: { DefaultFormLayout },
  name: "RegisterRole",
  data: () => ({
    headerText: "Novo",
    formValid: true,
    formRules: [(v) => !!v || "Campo obrigatório!"],
    roles: [],
    edit: false,
    blockButton: false,
    snackbar: {
      message: "",
      visible: false,
      color: "success",
      timeout: 6000,
      x: "right",
      y: "top",
      multiLine: false,
    },
    register: {
      id: "",
      name: "",
      desc: "",
      url: "",
      color: "",
      role_ids: [],
    },
    registerRoles: [],
  }),
  mounted() {
    this.register.id = this.$route.params.id;

    if (this.register.id) {
      this.headerText = "Editar";
      this.edit = true;
      this.getRoles();
    } else {
      this.edit = false;
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: "Roles" });
    },
    save() {
      let formValidate = this.$refs.form.validate();

      if (formValidate) {
        this.send();
      }
    },
    send() {
      this.$root.$LoadingBar.show("Carregando...");
      this.minamelServices
        .RoleService()
        .createRole(this.register.id)
        .create(this.register)
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this, response)) {
            this.$router.push({ name: "Roles" });
          }
        })
        .catch((error) => {
          this.minamelServices.TreatRequest().error(this, error);
          this.$router.push({ name: "Roles" });
        });
    },
    getRoles() {
      this.minamelServices
        .RoleService()
        .getRole()
        .search({ id: this.register.id })
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this)) {
            this.register = response.data[0];
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
  },
};
</script>

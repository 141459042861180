<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row row wrap>
          <v-col md="12">
            <v-card id="onPrint" class="elevation-1 pa-4 mb-4">
              <v-subheader>
                <h2>Operações</h2>
              </v-subheader>
              <v-divider></v-divider>
              <v-row row>
                <v-col v-if="isEditing || isViewing" xs="4" class="pa-3">
                  <v-text-field
                    v-model="register.id"
                    class="required"
                    required="name"
                    label="Nº Documento"
                    :readonly="true"
                    return-object
                  ></v-text-field>
                </v-col>
                <v-col v-if="!sameAmount" xs="4" class="pa-3">
                  <v-select
                    v-model="register.movement_type"
                    class="required"
                    item-text="name"
                    item-value="id"
                    required="name"
                    :items="movement_types"
                    label="Tipo de movimentação"
                    :readonly="isViewing"
                    return-object
                  ></v-select>
                </v-col>
                <v-col v-if="!isViewing && showSupplier" xs="4" class="pa-3">
                  <v-autocomplete
                    style="margin-left: -30px"
                    :class="
                      register.movement_type.needs_supplier == 1 || sameAmount
                        ? 'required'
                        : ''
                    "
                    v-model="register.supplier"
                    clearable
                    :items="suppliers"
                    :loading="isLoading"
                    :search-input.sync="term"
                    hide-no-data
                    hide-selected
                    :item-text="getText"
                    item-value="id"
                    label="Fornecedor"
                    placeholder="Pesquisar..."
                    prepend-icon="mdi-database-search"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <template v-if="showBalance">
                  <v-col
                    xs1
                    style="
                      margin-left: 15px;
                      padding-right: 5px;
                      padding-top: 20px;
                    "
                  >
                    <v-card max-width="400">
                      <v-card-title>
                        <v-icon large left>mdi-barrel</v-icon>
                        <span class="title font-weight-light">AV</span>
                      </v-card-title>

                      <v-card-text style="font-size: 20px">{{
                        balance["AV"]
                      }}</v-card-text>
                    </v-card>
                  </v-col>
                  <v-col xs1 style="padding-right: 5px; padding-top: 20px">
                    <v-card max-width="400">
                      <v-card-title>
                        <v-icon large left>mdi-barrel</v-icon>
                        <span class="title font-weight-light">BV</span>
                      </v-card-title>

                      <v-card-text style="font-size: 20px">{{
                        balance["BV"]
                      }}</v-card-text>
                    </v-card>
                  </v-col>
                  <v-col xs1 style="padding-right: 5px; padding-top: 20px">
                    <v-card max-width="400">
                      <v-card-title>
                        <v-icon large left>mdi-barrel</v-icon>
                        <span class="title font-weight-light">AC</span>
                      </v-card-title>

                      <v-card-text style="font-size: 20px">{{
                        balance["AC"]
                      }}</v-card-text>
                    </v-card>
                  </v-col>
                  <v-col xs1 style="padding-right: 5px; padding-top: 20px">
                    <v-card max-width="400">
                      <v-card-title>
                        <v-icon large left>mdi-barrel</v-icon>
                        <span class="title font-weight-light">BC</span>
                      </v-card-title>

                      <v-card-text style="font-size: 20px">{{
                        balance["BC"]
                      }}</v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
              <v-row v-if="showSupplier" row>
                <v-col xs="4" class="pa-3">
                  <v-text-field
                    v-model="register.supplier_code"
                    :readonly="true"
                    label="Código do fornecedor"
                  ></v-text-field>
                </v-col>
                <v-col xs="4" class="pa-3">
                  <v-text-field
                    v-model="register.supplier_business_name"
                    :readonly="true"
                    label="Nome fantasia do fornecedor"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row row>
                <v-col xs="4" class="pa-3">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        class="required"
                        v-model="formattedMovementDate"
                        label="Data de movimentação"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-if="!isViewing"
                      locale="pt-br"
                      v-model="register.movement_date"
                      type="date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(register.movement_date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col xs="4" class="pa-3">
                  <v-text-field
                    v-model="register.a_type_barrel_quantity"
                    prefix="A:"
                    label="Quantidade"
                    class="required"
                    :error="errors.balanceA"
                    @keyup="updateDebit"
                    :error-messages="errors.balanceA"
                    type="number"
                    :readonly="isViewing"
                  ></v-text-field>
                </v-col>
                <v-col xs="4" class="pa-3">
                  <v-text-field
                    v-model="register.b_type_barrel_quantity"
                    @keyup="updateDebit"
                    prefix="B:"
                    class="required"
                    label="Quantidade"
                    :error="errors.balanceB"
                    :error-messages="errors.balanceB"
                    type="number"
                    :readonly="isViewing"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row row>
                <v-col lg class="pa-3">
                  <v-textarea
                    auto-grow
                    height="70"
                    v-model="register.observation"
                    label="Observações"
                    :readonly="isViewing"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row row>
                <v-col
                  v-if="!isViewing"
                  @click="eventSameAmount"
                  xs="4"
                  class="pa-3"
                >
                  <v-checkbox
                    color="#b9041c"
                    v-model="sameAmount"
                    label="Entrada/saída com mesma quantidade"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <div
                v-if="
                  sameAmount ||
                  register.movement_type.financial_generation == 'S'
                "
              >
                <v-subheader>
                  <h2 style="color: #b9041c">GERAR DÉBITO</h2>
                </v-subheader>
                <v-divider></v-divider>
                <v-row row>
                  <v-col xs="4" class="pa-3">
                    <v-text-field
                      prefix="R$"
                      :readonly="true"
                      v-model="register.debit"
                      label="Valor bruto"
                    ></v-text-field>
                  </v-col>
                  <v-col xs="4" class="pa-3">
                    <v-text-field
                      prefix="R$"
                      @keyup="updateDebit"
                      suffix="="
                      v-model="register.discount"
                      label="Desconto"
                      :readonly="isViewing"
                    ></v-text-field>
                  </v-col>
                  <v-col xs="4" class="pa-3">
                    <v-text-field
                      prefix="R$"
                      :readonly="true"
                      v-model="register.final_debit"
                      label="Valor líquido"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row row>
                  <v-col lg class="pa-3">
                    <v-textarea
                      class="required"
                      auto-grow
                      height="70"
                      v-model="register.justification"
                      label="Justificativa para o desconto"
                      :readonly="isViewing"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>

              <div class="noPrint">
                <v-row row mt-4 mb-2>
                  <v-col xs="12">
                    <span class="caption">
                      <span class="text-red">*</span>
                      Campos obrigatórios
                    </span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!isViewing"
                    style="color: white"
                    color="green"
                    @click="saveAndConfirm"
                    >Salvar e confirmar</v-btn
                  >
                  <v-btn v-if="!isViewing" color="green" text @click="save"
                    >Salvar</v-btn
                  >
                  <v-btn v-if="!isViewing" color="error" text @click="cancel"
                    >Cancelar</v-btn
                  >
                  <v-btn v-if="isViewing" color="error" text @click="cancel"
                    >Voltar</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationHelper from "@/shared/helpers/NotificationHelpers";
import jwt from "vue-jwt-decode";

const moment = require("moment");

export default {
  name: "RegisterOperation",
  props: {},
  async mounted() {
    this.token = await this.minamelServices.getToken();
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.systemId = (await sessionStorage.systemId)
      ? sessionStorage.systemId
      : null;
    this.systemData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).systemData[this.systemId]
      : null;
    const id = this.$route.params.id;

    this.isViewing = false;
    this.isEditing = false;

    await this.getMovementTypes();

    if (id) {
      this.$http
        .get(this.minamelServices.getUrl() + "operations/get", {
          params: {
            id: id,
          },
          headers: { token: this.token },
        })
        .then((response) => {
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);

            return;
          }

          const operation = response.body.data[0];

          this.register = {
            ...operation,
            movement_type: {
              id: operation.movement_type_id,
              name: operation.movement_type_name,
              recipient_barrel_situation:
                operation.movement_type_recipient_barrel_situation,
              sender_barrel_situation:
                operation.movement_type_sender_barrel_situation,
              financial_generation:
                operation.movement_type_financial_generation,
              type: operation.movement_type_type,
              is_adjustment: operation.movement_type_is_adjustment,
              needs_supplier: operation.movement_type_needs_supplier,
              emit_receipt: operation.movement_type_emit_receipt,
              fills_quantity: operation.movement_type_fills_quantity,
            },
            supplier: operation.supplier_name,
            supplier_business_name: operation.supplier_name,
          };

          if (this.$route.name == "ViewOperation") {
            this.isViewing = true;
            this.isEditing = false;
          } else {
            if (this.register.status == 1) {
              this.isEditing = true;
              this.isViewing = false;
            } else {
              this.isEditing = false;
              this.isViewing = true;
            }
          }

          if (operation.movement_type_id == 15) {
            this.isEditing = true;
            this.sameAmount = true;
            this.getBalance();
          }

          this.suppliers = [this.register.supplier];
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        })
        .finally(() => {});
    }
  },
  computed: {
    formattedMovementDate: {
      get: function () {
        return this.formatDate(this.register.movement_date);
      },
      set: function (newDate) {
        this.register.movement_date = newDate;
      },
    },
  },
  methods: {
    getText(value) {
      return `${value.erp_code} - ${value.name}`;
    },
    eventSameAmount() {
      if (this.sameAmount && !this.isEditing) {
        this.showSupplier = true;
        this.register.supplier_id = "";
        this.register.supplier_code = "";
        this.register.supplier_name = "";
        this.register.supplier_business_name = "";
        this.register.supplier = "";
        this.register.movement_type_id = 15;
        this.register.debit = 0;
        this.register.discount = 0;
        this.register.final_debit = 0;
        this.showBalance = false;
      } else {
        if (this.register.movement_type_id) {
          this.movementTypeHandler(this.register.movement_type);
        }

        if (this.register.movement_type_id && this.register.supplier_id) {
          this.register.a_type_barrel_quantity = 0;
          this.register.b_type_barrel_quantity = 0;
        }

        this.updateDebit();
      }
    },
    getBalance() {
      this.doRequest({
        endpoint: "inventory-movements/get-balance",
        method: "GET",
        dataToSend: {
          params: {
            supplier_id: this.register.supplier_id,
            is_editing: this.isEditing,
            operation_id: this.register.id,
          },
          headers: { token: this.token },
        },
      }).then((response) => {
        const balance = response.body.data[0];

        this.balance = {
          filled: true,
          AV: balance.AV,
          BV: balance.BV,
          AC: balance.AC,
          BC: balance.BC,
        };

        this.showBalance = true;
        this.updateDebit();
      });
    },
    discountFromDebit() {
      if (this.register.discount) {
        if (this.register.discount <= this.register.debit) {
          this.register.final_debit -= this.register.discount;
        } else {
          this.register.final_debit = 0;
        }
      }
    },
    updateDebit() {
      this.errors.balanceA = null;
      this.errors.balanceB = null;

      if (
        !this.balance.filled ||
        !this.register.movement_type ||
        !this.register.supplier ||
        this.register.movement_type.financial_generation != "S" ||
        this.isViewing
      ) {
        this.register.debit = 0;
        this.register.final_debit = 0;

        return;
      }

      const quantityDiffA =
        this.register.a_type_barrel_quantity - this.balance["AV"];
      const quantityDiffB =
        this.register.b_type_barrel_quantity - this.balance["BV"];

      if (quantityDiffA > 0 || quantityDiffB > 0) {

        this.errors.balanceA = quantityDiffA > 0 ? "Número de tambores maior que o disponível no extrato do cliente" : null;
        this.errors.balanceB = quantityDiffB > 0 ? "Número de tambores maior que o disponível no extrato do cliente" : null;
      }

      const debitA = this.register.a_type_barrel_quantity * this.DEBIT_BARREL_PRICE;
      const debitB = this.register.b_type_barrel_quantity * this.DEBIT_BARREL_PRICE;

      if (debitA) {
        this.register.debit = debitA;
        this.register.final_debit = debitA;
      } else {
        this.register.debit = debitB;
        this.register.final_debit = debitB;
      }

      this.discountFromDebit();
    },
    doRequest({ endpoint, method, dataToSend }) {
      const url = this.minamelServices.getUrl() + endpoint;

      switch (method) {
        case "GET":
          return this.$http.get(url, dataToSend);
        case "POST":
          return this.$http.post(url, dataToSend);
        case "PUT":
          return this.$http.put(url, dataToSend);
        case "DELETE":
          return this.$http.delete(url, dataToSend);
      }
    },
    error(message) {
      this.$root.$LoadingBar.hide();
      this.snackbar.color = "error";
      this.snackbar.message = message;
      this.$root.$SnackBar.show(this.snackbar);
    },
    success(message) {
      this.snackbar.color = "success";
      this.snackbar.message = message;
      this.$root.$SnackBar.show(this.snackbar);
    },
    async getMovementTypes() {
      try {
        let response = await this.doRequest({
          endpoint: "movement-types/get",
          method: "GET",
          dataToSend: {
            params: {},
            headers: { token: this.token },
          },
        });

        if (!response.body.error) {
          this.movement_types = response.body.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    cancel() {
      this.$router.push({ name: "Operations" });
    },
    validate() {
      if (!this.register.movement_type_id && !this.sameAmount) {
        return "O campo tipo de movimentação deve ser informado";
      }

      if (!this.sameAmount) {
        if (
          this.register.movement_type &&
          this.register.movement_type.needs_supplier == 1 &&
          !this.register.supplier_id
        ) {
          return "O campo fornecedor deve ser ser válido.";
        }
      } else {
        if (!this.register.supplier_id) {
          return "O campo fornecedor deve ser ser válido.";
        }
      }

      if (!this.register.movement_date) {
        return "O campo data da movimentação deve ser informado";
      }

      if (
        this.register.a_type_barrel_quantity < 0
      ) {
        return "A quantidade de tambores deve ser maior ou igual a zero!";
      }

      if (
        this.register.b_type_barrel_quantity < 0
      ) {
        return "A quantidade de tambores deve ser maior ou igual que zero!";
      }

      if (this.register.discount > 0 && !this.register.justification) {
        return "É necessário informar um justificativa para o desconto informado!";
      }

      return false;
    },
    save() {
      const invalidMessage = this.validate();

      if (invalidMessage) {
        this.error(invalidMessage);
        return;
      }

      this.send();
    },
    openPdf(confirm) {
      this.$http
        .post(
          this.minamelServices.getUrl() + "pdf/receipt",
          {
            ...this.register,
            ...{ confirm: confirm },
          },
          {
            headers: { token: this.token },
          }
        )
        .then((response) => {
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);
            return;
          }

          let date = new Date();

          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + response.body.response;
          a.download =
            "RECIBO_" +
            date.getFullYear() +
            "_" +
            date.getMonth() +
            "_" +
            date.getDate() +
            "_" +
            date.getMilliseconds() +
            ".pdf";
          a.click();
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        })
        .finally(() => {});
    },
    generate(confirm) {
      this.$http
        .post(
          this.minamelServices.getUrl() + "inventory-movements/generate",
          {
            ...this.register,
            ...{ balance: this.balance },
            ...{ confirm: confirm },
            ...{ is_editing: this.isEditing },
            ...{ same_amount: this.sameAmount },
          },
          {
            headers: { token: this.token },
          }
        )
        .then((response) => {
          this.$root.$LoadingBar.show("Carregando...");

          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);
            this.$root.$LoadingBar.hide();
          }

          const movement_type = this.register.movement_type;

          if (movement_type.emit_receipt) {
            this.openPdf(confirm);
          }

          setTimeout(() => {
            this.snackbar.color = "success";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);

            this.$root.$LoadingBar.hide();

            this.$router
              .push({
                name: "Operations",
              })
              .catch(() => {});
          }, 1500);
        })
        .catch((rejected) => {
          const result =
            NotificationHelper.getExceptionResponseMessage(rejected);

          this.showErrorModal(result);
        });
    },
    showErrorModal(error) {
      this.$swal.fire({
        title: "Erro",
        text: error,
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        allowOutsideClick: false,
      });
    },
    saveAndConfirm() {
      const invalidMessage = this.validate();

      if (invalidMessage) {
        this.error(invalidMessage);
        return;
      }

      this.$swal
        .fire({
          title: "Atenção",
          text: "Deseja realizar esta movimentação?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return;
          }

          this.generate(true);
        });
    },
    send() {
      this.generate(false);
    },
    movementTypeHandler(movementType) {
      this.register.movement_type_id = movementType.id;

      if (!this.isViewing && !this.isEditing) {
        this.register.debit = 0;
        this.register.final_debit = 0;
      }

      if (this.register.movement_type.financial_generation == "N") {
        this.register.discount = 0;
      }

      if (movementType.needs_supplier) {
        this.showSupplier = true;
      } else {
        this.showSupplier = false;

        if (this.register.supplier_id) {
          this.register.supplier = null;
          this.register.supplier_id = null;
          this.register.supplier_code = null;
          this.register.supplier_business_name = null;
        }
      }

      if (this.register.supplier_id) {
        this.getBalance();
      } else {
        this.showBalance = false;
      }
    },
    verifyPermission(supplier) {
      let allowed = true;

      if (supplier.erp_code && supplier.erp_code.includes("A")) {
        allowed = true;
        if (supplier.systems_ids && this.systemId) {
          allowed = supplier.systems_ids
            .split(",")
            .some((value) => value.trim() == this.systemId);
        } else {
          allowed = true;
        }

        if (!allowed) {
          this.snackbar.color = "error";
          this.snackbar.message = `Este fornecedor não tem permissão para ${this.systemData.name}`;
          this.$root.$SnackBar.show(this.snackbar);
        }
      }
      return allowed;
    },
  },
  data() {
    return {
      userData: {},
      systemId: null,
      systemData: null,
      token: null,
      sameAmount: false,
      showSupplier: true,
      DEBIT_BARREL_PRICE: 50,
      term: "",
      isLoading: false,
      isViewing: false,
      isEditing: false,
      showBalance: false,
      errors: {
        balanceA: null,
        balanceB: null,
      },
      balance: {
        filled: false,
        av: "",
        bv: "",
        ac: "",
        bc: "",
      },
      movement_types: [],
      suppliers: [],
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
      register: {
        id: "",
        status: 1,
        movement_type: "",
        movement_type_id: "",
        supplier: "",
        supplier_id: "",
        supplier_code: "",
        supplier_business_name: "",
        a_type_barrel_quantity: "",
        b_type_barrel_quantity: "",
        debit: 0,
        discount: 0,
        final_debit: 0,
        justification: "",
        observation: "",
        movement_date: moment().format("YYYY-MM-DD"),
      },
    };
  },
  watch: {
    term(val) {
      if (!val) return;

      if (this.isLoading) return;

      this.isLoading = true;

      this.doRequest({
        endpoint: "suppliers/get",
        method: "GET",
        dataToSend: {
          params: {
            term: val,
            active: 1,
          },
          headers: { token: this.token },
        },
      })
        .then((response) => {
          this.suppliers = [...response.body.data, this.register.supplier];
        })
        .catch((err) => {
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    "register.supplier": {
      handler(supplier) {
        if (supplier && supplier.id) {
          if (supplier.aggregate_id) {
            this.snackbar.color = "error";
            this.snackbar.message =
              "Este fornecedor está inserido no agregado " +
              supplier.aggregate_name;
            this.$root.$SnackBar.show(this.snackbar);
            return;
          }

          if (!this.verifyPermission(supplier)) {
            this.register.supplier_id = null;
            this.register.supplier_code = null;
            this.register.supplier_business_name = null;
            return;
          }

          this.register.supplier_id = supplier.id;
          this.register.supplier_code = supplier.erp_code;
          this.register.supplier_business_name = supplier.name;

          if (this.register.movement_type_id || this.sameAmount) {
            this.getBalance();
          } else {
            this.showBalance = false;
          }
        } else if (this.register.movement_type) {
          if (this.register.movement_type.needs_supplier) {
            this.showSupplier = true;
          } else {
            this.showSupplier = false;
          }
        }
      },
      deep: true,
    },
    "register.movement_type": {
      handler(movementType) {
        this.movementTypeHandler(movementType);
      },
    },
  },
};
</script>

<style>
@media print {
  .noPrint {
    display: none;
  }

  .elevation-1 {
    -webkit-box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2),
      0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px 0 rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2),
      0 0px 0px 0 rgba(0, 0, 0, 0.14), 0 0px 0px 0 rgba(0, 0, 0, 0.12) !important;
  }

  #onPrint {
    left: -100px;
    top: -50px;
    width: 800px;
  }
}
</style>

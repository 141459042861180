<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { equiv: "Content-Type", content: "text/html" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
};
</script>

<style>
.w-100 {
  width: 100%;
}
.flex-one {
  flex: 1;
}
.v-application--wrap {
  background-color: rgba(0, 0, 0, 0.04);
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.swal2-popup {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.borderGreen {
  border-bottom: 4px solid green;
  text-align: center;
}
.borderBlue {
  border-bottom: 4px solid blue;
  text-align: center;
}
.borderRed {
  border-bottom: 4px solid red;
  text-align: center;
}
.borderPurple {
  border-bottom: 4px solid purple;
  text-align: center;
}
.borderOrange {
  border-bottom: 4px solid orange;
  text-align: center;
}
.borderCyan {
  border-bottom: 4px solid cyan;
  text-align: center;
}
.borderLightGreen {
  border-bottom: 4px solid lightgreen;
  text-align: center;
}
.border-left {
  border-left: 1px solid #efefef;
}
.border-right {
  border-right: 1px solid #efefef;
}
.border-bottom {
  border-bottom: 1px solid #efefef;
}
.border-bottom-3px {
  border-bottom: 3px solid orange;
}
.font-bold {
  font-weight: bold;
}
.text-left {
  text-align: left;
}
.cursorPointer {
  cursor: pointer;
}
.required label::after {
  content: " *";
  color: red;
}
.text-red {
  color: red;
}
.actionPagination {
  padding-top: 0 !important;
  margin-top: 0 !important;
  font-size: 12px !important;
}
.actionPaginationText {
  color: rgba(0, 0, 0, 0.54);
}
.searchInTab {
  padding-top: 2px;
}
.btnInTab {
  margin-top: 9px;
}
table.v-table tbody td:first-child,
table.v-table tbody td:not(:first-child),
table.v-table tbody th:first-child,
table.v-table tbody th:not(:first-child),
table.v-table thead td:first-child,
table.v-table thead td:not(:first-child),
table.v-table thead th:first-child,
table.v-table thead th:not(:first-child) {
  padding: 0 10px !important;
}
.inputUpper input {
  text-transform: uppercase;
}
.memoFormat {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

.project-margin-adjustment {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.swal2-popup {
  padding: 0px !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.swal2-container {
  font-family: "Roboto", sans-serif !important;
}

.swal2-cancel {
  color: #ff5252 !important;
  border: thin solid !important;
  border-radius: 4px !important;
  border-color: inherit !important;
  background-color: transparent !important;
  box-shadow: none !important;
  order: -1;
  border-top: thin solid #ff5252 !important;
}

.swal2-cancel:hover {
  background-image: linear-gradient(
    rgba(255, 82, 82, 0.1),
    rgba(255, 82, 82, 0.1)
  ) !important;
}

.swal2-styled {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 36px !important;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-transform: uppercase;
}

.swal2-actions {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  padding: 8px 21px 8px 21px !important;
}

.swal2-header,
.swal2-content {
  padding-left: 10px;
  padding-right: 10px;
}

/*vue recycle scroller*/

.resize-observer {
  height: 0;
}

.vue-recycle-scroller__item-view {
  transform: none !important;
}
</style>

/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * @typedef {DiseaseTreatmentRecordService}
 */
export default class DiseaseTreatmentRecordService extends Rest {
    /**
	 * @type {String}
	 */
    static resource = 'disease-treatment-records/';

    get (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    importToProject (id, data) {
    	return super.post(`/${id}/import-project`, data);
    }
}

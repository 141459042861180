<template>
  <v-card
    class="elevation-0"
    :class="!projectId ? 'ma-2' : 'ma-0 project-margin-adjustment'"
  >
    <v-form ref="form">
      <v-row row wrap>
        <v-col xs="12" md="12" pa-3>
          <span class="required grey--text">* Preenchimento obrigatório.</span>
        </v-col>

        <v-col xs="12" md="4" pl-3 pr-3 pt-2>
          <v-autocomplete
            :rules="[formRules.required]"
            :cache-items="true"
            chips
            :deletable-chips="true"
            v-model="register.supplier_id"
            :items="suppliers"
            item-text="name"
            item-value="id"
            label="Produtor"
            @change="onChangeSupplier()"
            class="required"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.name }}
              <span class="font-weight-bold">
                - COD.
                {{ data.item.id }}</span
              >
            </template>
          </v-autocomplete>
        </v-col>

        <v-col xs="12" md="4" pl-3 pr-3 pt-2 v-if="!register.there_is_not">
          <v-select
            chips
            :deletable-chips="true"
            :rules="[formRules.required]"
            v-model="register.apiary_import_key"
            :items="mutableApiaries"
            item-text="name"
            item-value="import_key"
            label="Apiário"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pl-3 pr-3 pt-3 v-if="!register.there_is_not">
          <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            full-width
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :rules="[formRules.required]"
                :value="$functions.formatDate(register.date)"
                clearable
                label="Data"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="register.date"
              @change="datePickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
          <v-text-field
            :rules="[formRules.required]"
            v-model="register.food_quantity"
            label="Quantidade alimentada"
            suffix="Kg"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
          <v-select
            :rules="[formRules.required]"
            :items="systemVariablesFood"
            item-value="id"
            item-text="value"
            v-model="register.food_system_variable_id"
            label="Alimento"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
          <v-text-field
            :rules="[formRules.required]"
            v-model="register.food_origin"
            label="Origem do alimento"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
          <v-text-field
            :rules="[formRules.required]"
            v-model="register.concentration"
            label="Concentração"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
          <v-select
            :rules="[formRules.required]"
            v-model="register.organic_quality"
            :items="qualityItems"
            item-value="id"
            item-text="value"
            label="Qualidade"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
          <v-select
            :rules="[formRules.required]"
            v-model="register.reason_system_variable_id"
            :items="systemVariablesReason"
            item-value="id"
            item-text="value"
            label="Por quê foi alimentado"
            class="required"
          />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text :to="`${routeName}/feed-records`"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import jwt from "vue-jwt-decode";

export default {
  name: "FeedRecordsRegister",
  props: {
    apiaries: { type: Array },
    suppliers: { type: Array },
    projectId: { type: Number, default: null },
    routeName: { type: String, default: "/field-notes" },
  },
  data() {
    return {
      userData: null,
      formRules: {
        required: (value) => !!value || "Campo obrigatório",
        validApiary: () =>
          !!this.register.supplier_id || "Selecione um produtor",
      },
      systemVariablesFood: [],
      systemVariablesReason: [],
      datePickerMenu: false,
      register: {
        there_is_not: 0,
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
      qualityItems: [
        { value: "Orgânico", id: "Orgânico" },
        { value: "Comum", id: "Comum" },
      ],
      mutableApiaries: this.apiaries,
    };
  },
  async mounted() {
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;

    this.mutableApiaries = this.apiaries;

    this.getSystemVariables();

    this.id = this.projectId
      ? this.$route.params.childrenId
      : this.$route.params.id;
    if (this.id) {
      this.getData();
    }
  },
  methods: {
    canViewOrganicQuality() {
      let token = jwt.decode(sessionStorage.token);
      let adminGroupId = 16;

      return token.userData.user_group_id == adminGroupId;
    },
    async getData() {
      const response = await this.minamelServices
        .FeedRecordService()
        .show(this.id);

      this.register = response.data;
      this.register.date = this.register.date.split("T")[0];
    },

    async getSystemVariables() {
      const paramsFood = { type: "FEED_RECORD_FOOD" };
      const paramsreason = { type: "FEED_RECORD_REASON" };

      const responseFood = await this.minamelServices
        .SystemVariableService()
        .get(paramsFood);

      const responseReason = await this.minamelServices
        .SystemVariableService()
        .get(paramsreason);

      this.systemVariablesFood = responseFood.data;
      this.systemVariablesReason = responseReason.data;
    },

    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.register.id) {
        this.update();
        return;
      }

      this.create();
    },

    onChangeSupplier() {
      const filtered = this.apiaries.filter((obj) => {
        return this.register.supplier_id === obj.supplier_id;
      });

      if (filtered.length) {
        this.mutableApiaries = filtered;
        this.register.apiary_id = null;

        return;
      }

      if (!this.register.supplier_id) {
        this.mutableApiaries = this.apiaries;
        this.register.apiary_id = null;
        return;
      }

      this.mutableApiaries = [];
    },

    async create() {
      this.register.user_id = this.userData.id;
      this.register.type = "RECORD";
      this.register.food_quantity = parseFloat(this.register.food_quantity);

      try {
        const response = await this.minamelServices
          .FeedRecordService()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Alimentação incluída com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`${this.routeName}/feed-records`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      this.register.food_quantity = parseFloat(this.register.food_quantity);

      try {
        const response = await this.minamelServices
          .FeedRecordService()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Alimentação atualizada com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`${this.routeName}/feed-records`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao atualizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
  watch: {
    "register.there_is_not": {
      handler(val) {
        if (val) {
          this.register.apiary_id = null;
          this.register.food_quantity = null;
          this.register.date = null;
          this.register.food_system_variable_id = null;
          this.register.reason_system_variable_id = null;
          this.register.food_origin = null;
          this.register.concentration = null;
          this.register.organic_quality = null;
        }
      },
    },
  },
};
</script>

<template>
  <v-form ref="form" v-if="register" v-model="formValid">
    <default-form-layout>
      <template v-slot:title>
        <h2>{{ headerText }} Sistema</h2>
      </template>
      <template v-slot:body>
        <v-row>
          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="formRules"
              v-model="register.name"
              label="Nome"
              class="required"
              ref="name"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-textarea
              rows="1"
              v-model="register.desc"
              label="Descrição"
              class="required"
              ref="desc"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              class="required"
              clearable
              color="primary"
              multiple
              chips
              deletable-chips
              :items="roles"
              v-model="registerRoles"
              item-value="id"
              item-text="name"
              label="Menus"
              :rules="[verifyRoles]"
              style="margin-top: -5px"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >+{{ registerRoles.length - 1 }}
                  {{ registerRoles.length > 2 ? "Menu(s)" : "Menu" }}</span
                >
              </template>
            </v-select>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="formRules"
              v-model="register.url"
              label="Url"
              class="required"
              ref="url"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <label for="color-picker">Cor</label>
            <!-- <v-text-field v-model="register.color" label="Cor" class="required" ref="color" /> -->
            <template>
              <div class="form__input">
                <v-swatches
                  id="color-picker"
                  :swatches="colorListValues"
                  v-model="register.color"
                ></v-swatches>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col xs="12" md="4" class="pa-3">
            <label for="color-picker">Imagem Sistema</label>
            <v-avatar
              @click="trigger"
              class="mx-auto d-block"
              style="color: grey"
              size="130"
            >
              <img :src="avatar" />
            </v-avatar>
            <v-card-actions class="text-xs-center">
              <v-file-input
                @change="uploadImage"
                ref="myImage"
                type="file"
                name="file"
                id="file"
                prepend-icon=""
                append-icon="mdi-paperclip"
                accept="image/*"
                class="inputfile"
                label="Alterar imagem"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text @click="cancel">Cancelar</v-btn>
      </template>
    </default-form-layout>
  </v-form>
</template>

<script>
import colors from "vuetify/es5/util/colors";

// Import the styles too, typically in App.vue or main.js
import "vue-swatches/dist/vue-swatches.css";
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";

export default {
  name: "RegisterSystem",
  components: { DefaultFormLayout },
  data: () => ({
    headerText: "Novo",
    formValid: true,
    formRules: [(v) => !!v || "Campo obrigatório!"],
    avatar: "",
    roles: [],
    edit: false,
    blockButton: false,
    snackbar: {
      message: "",
      visible: false,
      color: "success",
      timeout: 6000,
      x: "right",
      y: "top",
      multiLine: false,
    },
    register: {
      id: "",
      name: "",
      desc: "",
      url: "",
      color: "",
      role_ids: [],
    },
    registerRoles: [],
  }),
  computed: {
    arrayRoleIds: function () {
      // return 123;
      let array = [];
      for (let index = 0; index < this.registerRoles.length; index++) {
        const element = this.registerRoles[index];
        if (element.id) {
          array.push(element.id);
        } else {
          array.push(element);
        }
      }
      return array;
    },
    colorListValues: () => {
      let new_colors = [];
      for (const color in colors) {
        // eslint-disable-next-line no-prototype-builtins
        if (colors.hasOwnProperty(color)) {
          const element = colors[color];
          if (element != "") {
            new_colors.push(element.base);
          }
        }
      }
      return new_colors;
    },
  },
  async mounted() {
    this.register.id = await this.$route.params.id;
    await this.getRoles();

    if (this.register.id) {
      this.headerText = "Editar";
      this.edit = true;
      await this.getSystems();
    } else {
      this.edit = false;
    }
  },
  methods: {
    verifyRoles(roles) {
      if (!roles || !roles.length || roles.length <= 0) {
        return "É necessário informar ao menos um menu!";
      }
      return true;
    },
    trigger() {
      this.$refs.myImage.click();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    uploadImage(event) {
      this.getBase64(event.target.files[0]).then((result) => {
        this.minamelServices
          .UploadService()
          .sendUpload()
          .send({ system_id: this.register.id, base64: result })
          .then((response) => {
            if (this.minamelServices.TreatRequest().result(this)) {
              this.avatar = response.data;
            }
          });
      });
    },
    cancel() {
      this.$router.push({ name: "Systems" });
    },
    save() {
      let formValidate = this.$refs.form.validate();

      if (formValidate) {
        this.send();
      }
    },
    send() {
      this.$root.$LoadingBar.show("Carregando...");
      let headerRoles = {};
      delete this.register.role_ids;
      headerRoles.role_ids = this.arrayRoleIds;
      this.minamelServices
        .SystemService()
        .createSystem(this.register.id)
        .create(this.register)
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this, response)) {
            headerRoles.id = response.data.insertId
              ? response.data.insertId
              : this.register.id;
            this.minamelServices
              .SystemService()
              .updateSystemsRoles()
              .create(headerRoles)
              .then((response) => {
                if (
                  this.minamelServices.TreatRequest().result(this, response)
                ) {
                  this.$router.push({ name: "Systems" });
                }
              })
              .catch((error) => {
                this.minamelServices.TreatRequest().error(this, error);
                this.$router.push({ name: "Systems" });
              });
          }
        })
        .catch((error) => {
          this.minamelServices.TreatRequest().error(this, error);
          this.$router.push({ name: "Systems" });
        });
    },
    getUpload() {
      this.minamelServices
        .UploadService()
        .getUpload()
        .search({ system_id: this.register.id })
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this)) {
            this.avatar = response.data;
          }
        });
    },
    getRoles() {
      this.minamelServices
        .RoleService()
        .getRole()
        .search()
        .then((response) => {
          this.minamelServices.TreatRequest().result(this);
          this.roles = response.data;
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    populateRoles() {
      if (this.register.role_ids) {
        this.register.role_ids = this.register.role_ids
          .split(", ")
          .map(function (value) {
            return parseInt(value, 10);
          });

        for (let index = 0; index < this.roles.length; index++) {
          const flowObj = this.roles[index];

          if (this.register.role_ids.includes(flowObj.id)) {
            this.registerRoles.push(flowObj);
          }
        }
      }
    },
    async getSystems() {
      await this.minamelServices
        .SystemService()
        .getSystem()
        .search({ id: this.register.id })
        .then(async (response) => {
          let treat = await this.minamelServices.TreatRequest().result(this);
          if (treat) {
            const System = response.data[0];
            this.register = {
              id: System.id,
              name: System.name,
              desc: System.desc,
              url: System.url,
              color: System.color,
              role_ids: System.role_ids,
            };
            await this.populateRoles();
            await this.getUpload();
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
  },
};
</script>

<style>
.vue-swatches__container {
  display: flex;
  box-shadow: 6px 8px 11px -3px #757575ff;
  border-radius: 4px;
  margin-top: 4px;
  padding: 0px 0px 2px 16px;
}

.vue-swatches__wrapper {
  flex: 1;
  flex-wrap: wrap;
  margin-left: -15px;
}
</style>

/**
 * Lida com todos os servicos relacionados à cordenadas
 * @typedef {LatLngService}
 */
export default class LatLngService {
	constructor(latLngType) {
		this.latLngType = latLngType;
		this.locationRegex = {
			lat: [
				{
					type: "DMS",
					exp: /^(([1-8]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|90\D+0\D+0)\D+[NSns]$/
				},
				{
					type: "DDM",
					exp: /^(([1-8]?[0-9])\D+[1-6]?[0-9](\.[0-9]*)?|90(\D+0)?)\D+([NSns])$/
				},
				{
					type: "DD",
					exp: /^[+-]?(([1-8]?[0-9])(\.[0-9]*)?|90)\D*[NSns]?$/
				}
			],
			lng: [
				{
					type: "DMS",
					exp: /^((1[0-7][0-9]|[1-9]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|180\D+0\D+0)\D+[LOlo]$/
				},
				{
					type: "DDM",
					exp: /^((1[0-7][0-9]|[1-9]?[0-9])\D+[1-6]?[0-9](\.[0-9]*)?|180(\D+0)?)\D+([LOlo])$/
				},
				{
					type: "DD",
					exp: /^[+-]?((1[0-7][0-9]|[1-9]?[0-9])(\..[0-9]*)?|180)\D*[LOlo]?$/
				}
			]
		};
	}

	checkLatLngType(value, config = {}) {
		try {
			if (!value) return {};

			let defaultConfig = {
				typeLatLng: "lat",
				toFormat: this.latLngType || "DMS",
				...config
			};

			if (!this.locationRegex[defaultConfig.typeLatLng]) return {};

			// if typeLatLng is true, é Latitude, else Longitude;
			let obj = this.locationRegex[defaultConfig.typeLatLng].find(reg => {
				return reg.exp.test(value) ? reg : null;
			});

			if (!obj) return {};

			let newValue = value;
			let latLng = value;
			let arrayExtraLatLng = [];

			if (obj.type == "DD") {
				// DD to DMM
				arrayExtraLatLng.push({ type: "DD", newValue: latLng });
				arrayExtraLatLng.push({
					type: "DDM",
					newValue: this.ddToDdm(value, defaultConfig.typeLatLng)
				});
				arrayExtraLatLng.push({
					type: "DMS",
					newValue: this.ddToDms(value, defaultConfig.typeLatLng)
				});
				if (defaultConfig.toFormat == "DDM") {
					newValue = this.ddToDdm(value, defaultConfig.typeLatLng);
				}
				if (defaultConfig.toFormat == "DMS") {
					newValue = this.ddToDms(value, defaultConfig.typeLatLng);
				}
			}

			if (obj.type == "DDM") {
				latLng = this.ddmToDd(value, defaultConfig.typeLatLng);

				arrayExtraLatLng.push({
					type: "DD",
					newValue: this.ddmToDd(value, defaultConfig.typeLatLng)
				});
				arrayExtraLatLng.push({
					type: "DDM",
					newValue: this.ddToDdm(latLng, defaultConfig.typeLatLng)
				});
				arrayExtraLatLng.push({
					type: "DMS",
					newValue: this.ddmToDms(value, defaultConfig.typeLatLng)
				});

				if (defaultConfig.toFormat == "DD") {
					newValue = this.ddmToDd(value, defaultConfig.typeLatLng);
				}
				if (defaultConfig.toFormat == "DMS") {
					newValue = this.ddmToDms(value, defaultConfig.typeLatLng);
				}
			}

			if (obj.type == "DMS") {
				latLng = this.dmsToDd(value, defaultConfig.typeLatLng);

				arrayExtraLatLng.push({
					type: "DD",
					newValue: this.dmsToDd(value, defaultConfig.typeLatLng)
				});
				arrayExtraLatLng.push({
					type: "DDM",
					newValue: this.dmsToDdm(value, defaultConfig.typeLatLng)
				});
				arrayExtraLatLng.push({
					type: "DMS",
					newValue: this.ddToDms(latLng, defaultConfig.typeLatLng)
				});

				if (defaultConfig.toFormat == "DDM") {
					newValue = this.dmsToDdm(value, defaultConfig.typeLatLng);
				}
				if (defaultConfig.toFormat == "DD") {
					newValue = this.dmsToDd(value, defaultConfig.typeLatLng);
				}
			}
			const data = {
				type: obj.type,
				value,
				newValue,
				latLng,
				extra: arrayExtraLatLng
			};
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	}
	ddToDdm(value, type) {
		value = parseFloat(value);
		let Degrees = Math.abs(value);
		let DegreesRound = parseInt(Degrees);
		let Minutes = (Degrees - DegreesRound) * 60;
		return `${DegreesRound}° ${Minutes}'${
			value < 0 ? (type == "lat" ? "S" : "O") : type == "lat" ? "N" : "L"
		}`;
	}
	ddToDms(value, type) {
		value = parseFloat(value);
		let Degrees = Math.abs(value);
		let DegreesRound = parseInt(Degrees);
		let Minutes = (Degrees - DegreesRound) * 60;
		let MinutesRound = parseInt(Minutes);
		let Seconds = (Minutes - MinutesRound) * 60;
		return `${DegreesRound}° ${MinutesRound}'${Seconds}"${
			value < 0 ? (type == "lat" ? "S" : "O") : type == "lat" ? "N" : "L"
		}`;
	}
	ddmToDd(value, type) {
		let Degrees = parseInt(value.split("°")[0].trim());
		let Minutes = value.split("°")[1].trim();
		Minutes = parseFloat(Minutes.split(`'`)[0].trim());
		return `${
			value.includes(
				value < 0
					? type == "lat"
						? "N"
						: "L"
					: type == "lat"
					? "S"
					: "O"
			)
				? "-"
				: ""
		}${Minutes / 60 + Degrees}`;
	}
	ddmToDms(value, type) {
		let Degrees = parseInt(value.split("°")[0].trim());
		let DegreesRound = parseInt(Degrees);
		let Minutes = value.split("°")[1].trim();
		Minutes = parseFloat(Minutes.split(`'`)[0].trim());
		let MinutesRound = parseInt(Minutes);
		let Seconds = (Minutes - MinutesRound) * 60;
		return `${DegreesRound}° ${MinutesRound}'${Seconds}"${
			value < 0 ? (type == "lat" ? "N" : "L") : type == "lat" ? "S" : "O"
		}`;
	}
	dmsToDd(value, type) {
		let Degrees = parseInt(value.split("°")[0].trim());
		let Minutes = value.split("°")[1].trim();
		Minutes = parseFloat(Minutes.split(`'`)[0].trim());
		let Seconds = parseFloat(
			value
				.split("°")[1]
				.trim()
				.split(`'`)[1]
				.trim()
				.split(`"`)[0]
				.trim()
		);
		return `${
			value.includes(
				value < 0
					? type == "lat"
						? "N"
						: "L"
					: type == "lat"
					? "S"
					: "O"
			)
				? "-"
				: ""
		}${Minutes / 60 + Seconds / 3600 + Degrees}°`;
	}
	dmsToDdm(value, type) {
		let Degrees = parseInt(value.split("°")[0].trim());
		let Minutes = value.split("°")[1].trim();
		Minutes = parseFloat(Minutes.split(`'`)[0].trim());
		let Seconds = parseFloat(
			value
				.split("°")[1]
				.trim()
				.split(`'`)[1]
				.trim()
				.split(`"`)[0]
				.trim()
		);
		return `${Degrees}° ${Minutes + Seconds / 60}'${
			value < 0 ? (type == "lat" ? "N" : "L") : type == "lat" ? "S" : "O"
		}`;
	}

	static build(latLngType) {
		this.latLngType = latLngType;
		return new this(this.latLngType);
	}
}

<template>
  <v-form ref="form">
    <default-form-layout>
      <template v-slot:title>
        <h2>Alterar variável do sistema</h2>
      </template>
      <template v-slot:body>
        <v-row>
          <v-col xs="12" md="4" class="pa-2">
            <v-select
              class="required"
              clearable
              color="primary"
              chips
              deletable-chips
              :items="CONSTANTS.standardTypesHoneyRecord"
              v-model="register.type"
              item-value="value"
              item-text="name"
              label="Tipo"
              :rules="formRules"
            ></v-select>
          </v-col>
          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="formRules"
              v-model="register.value"
              label="Valor"
              class="required"
              ref="name"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text to="/system-variables">Cancelar</v-btn>
      </template>
    </default-form-layout>
  </v-form>
</template>
<script>
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";
export default {
  components: { DefaultFormLayout },
  name: "RegisterSystemVariables",
  data() {
    return {
      id: null,
      headerText: "Nova",
      formRules: [(v) => !!v || "Campo obrigatório!"],
      register: {
        type: "",
        value: "",
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.headerText = "Alterar ";
      this.getData();
    }
  },
  methods: {
    async getData() {
      const response = await this.minamelServices
        .SystemVariableService()
        .show(this.id);

      this.register = response.data;
    },
    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.id) {
        this.update();
        return;
      }

      this.create();
    },
    async create() {
      try {
        const response = await this.minamelServices
          .SystemVariableService()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Variável de sistema incluída com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/system-variables");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
    async update() {
      try {
        const response = await this.minamelServices
          .SystemVariableService()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Variável de sistema alterada com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/system-variables");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

import Vue from 'vue'

Vue.directive('upper', {
	inserted: (el, _, vnode) => {
		el.addEventListener('input', async (e) => {
			e.target.value = e.target.value.toUpperCase()
			vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
		})
	}
})

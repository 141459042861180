<template>
  <v-card class="elevation-0 pa-2">
    <ItemQuestion
      v-for="(item, i) in data.lastForm.formFields"
      :item="item"
      :key="i"
      :onlyView="true"
    />

    <v-divider></v-divider>

    <v-card-actions class="pa-3">
      <v-spacer></v-spacer>

      <v-btn color="error" text to="/documents/forms">Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ItemQuestion from "./ItemQuestion";

export default {
  name: "DocumentsView",
  components: {
    ItemQuestion,
  },
  data() {
    return {
      id: this.$route.params.id,
      data: {},
    };
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        const res = await this.minamelServices.DocumentService().show(this.id);

        this.data = res.data;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<template>
  <default-list-layout>
    <template v-slot:title> <h2>Sistemas</h2></template>

    <template v-slot:actions>
      <v-container fluid>
        <v-row wrap>
          <v-col xs="7" md="8">
            <v-text-field
              v-model="search.term"
              ref="search"
              label="Faça a sua busca"
              clear-icon="close"
              clearable
              append-icon="search"
              @click:append="getData"
              @keyup="searchData"
              @keyup.enter="getData"
            />
          </v-col>
          <v-tooltip bottom xs="5" md="4">
            <template v-slot:activator="{ on }">
              <v-btn
                large
                color="normal"
                class="mt-3"
                v-on="on"
                @click="filters = !filters"
                v-shortkey="['ctrl', 'f']"
                @shortkey="searchFocus"
              >
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>
            <span>Clique para selecionar os filtros</span>
          </v-tooltip>
          <v-col xs="12" md="3" class="text-xs-right">
            <v-btn large @click="register" color="primary">+ Incluir</v-btn>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row row wrap v-show="filters">
            <v-col md="12">
              <v-card class="elevation-1 pa-3 mb-4">
                <v-row row>
                  <v-col xs="12">
                    <v-row row>
                      <v-col xs="6" class="caption"
                        >Selecione os campos para fazer a busca</v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-row row wrap>
                  <v-col
                    v-for="field in search.fieldToSearch"
                    :key="field.field"
                    xs="3"
                  >
                    <v-select
                      clearable
                      style="padding: 15px; margin-top: 15px"
                      color="primary"
                      :items="field.options"
                      v-model="field.value"
                      item-value="id"
                      item-text="text"
                      :label="field.description"
                      @change="getData"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </template>

    <template v-slot:content>
      <v-data-table
        :headers="sales.headers"
        :items="sales.items"
        :loading="table.loading"
        :options.sync="table.pagination"
        :no-results-text="table.noResultText"
        :no-data-text="table.noDataText"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:[`item.color`]="{ item }">
          <div
            v-bind:style="{
              backgroundColor: item.color,
              width: '40px',
              height: '40px',
              borderRadius: '10px',
            }"
          />
        </template>

        <template v-slot:[`item.role_ids`]="{ item }">
          {{ nomeRoles(item.role_ids) }}
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn @click="edit(item)" icon text v-on="on">
                <v-icon color="#707070">edit</v-icon>
              </v-btn>
            </template>
          </v-menu>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn @click="destroy(item)" icon text v-on="on">
                <v-icon color="#707070">delete</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </template>

    <template v-slot:pagination
      ><v-pagination
        class="mx-auto"
        v-model="table.currentPage"
        :length="table.totalPages"
        v-bind:disabled="table.disabled"
        @input="pageChange"
        :total-visible="table.totalPagesVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </template>
  </default-list-layout>
</template>

<script>
import DefaultListLayout from "@/shared/components/layout/DefaultListLayout.vue";
export default {
  components: { DefaultListLayout },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Systems",
  data() {
    return {
      roles: [],
      currentItem: 1,
      timer: null,
      clearPagination: false,
      systems: [],
      search: {
        checkAll: true,
        onSearch: false,
        setDelete: false,
        term: "",
        fieldToSearch: [
          {
            description: "Ativo?",
            field: "active",
            value: [],
            options: [
              { id: "1", text: "Sim" },
              { id: "0", text: "Não" },
            ],
          },
        ],
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
      dialogTimelineVisible: false,
      filters: false,
      table: {
        disabled: false,
        loading: false,
        totalPagesVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,
        currentPage: 1,
        totalPages: 1,
        rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
        rowPerPageItems: this.CONSTANTS.arrays.table.itensToView,
        pagination: {
          rowsPerPage: 0,
          sortBy: "id",
          descending: false,
          page: 1,
          totalItems: 0,
        },
        totalItems: 0,
      },
      selected: {},
      timelineData: [],
      sales: {
        headers: [
          { text: "Código", align: "left", value: "id" },
          { text: "Nome", align: "left", value: "name" },
          { text: "Descrição", align: "left", value: "desc" },
          { text: "Url", align: "left", value: "url" },
          { text: "Cor", align: "left", value: "color" },
          {
            text: "Menus Permitidos",
            align: "left",
            value: "role_ids",
            sortable: false,
          },
          {
            text: "",
            align: "right",
            value: "active",
            sortable: false,
            width: "108px",
          },
        ],
        items: [],
      },
    };
  },
  mounted() {
    // this.getSystems();
    this.getRoles();
    this.getData();
  },
  methods: {
    getRoles() {
      this.minamelServices
        .RoleService()
        .getRole()
        .search()
        .then((response) => {
          this.minamelServices.TreatRequest().result(this);
          this.roles = response.data;
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    nomeRoles(arrayRoles) {
      if (arrayRoles && this.roles) {
        arrayRoles = arrayRoles.split(", ").map(function (value) {
          return parseInt(value, 10);
        });
        let arrayNomes = [];
        for (let index = 0; index < this.roles.length; index++) {
          const flowObj = this.roles[index];
          if (arrayRoles.includes(flowObj.id)) {
            arrayNomes.push(flowObj.name);
          }
        }
        return arrayNomes.join(", ");
      }
    },
    destroy(item) {
      this.$swal
        .fire({
          title: "Atenção",
          text: `Deseja excluir o sistema "${item.name}"?`,
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return;
          }
          this.minamelServices
            .SystemService()
            .deleteSystem()
            .destroy({ id: item.id })
            .then((response) => {
              if (this.minamelServices.TreatRequest().result(this, response)) {
                this.getData();
              }
            });
        });
    },
    edit(item) {
      this.$router.push({
        name: "EditSystem",
        params: {
          id: item.id,
        },
      });
    },
    getData() {
      if (this.table.loading) {
        return;
      }

      if (this.clearPagination) {
        this.clearPagination = !this.clearPagination;
        this.table.currentPage = 1;
        this.table.totalPages = 1;
      }

      const { sortBy, descending } = this.table.pagination;

      this.loadTable(true);
      this.table.noDataText = this.CONSTANTS.texts.table.loadingText;
      this.table.noResultText = this.CONSTANTS.texts.table.loadingText;
      this.table.disabled = false;

      let itensToSearch = [];

      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        if (this.search.fieldToSearch[i].value) {
          itensToSearch.push({
            field: this.search.fieldToSearch[i].field,
            value: this.search.fieldToSearch[i].value,
          });
        }
      }

      const params = {
        sort: sortBy,
        orientation: descending ? "DESC" : "ASC",
        page: this.table.currentPage,
        rowsPerPage: this.table.rowsPerPage,
        term: this.search.term,
        fieldsToSearch:
          !itensToSearch || itensToSearch.length <= 0 ? "" : itensToSearch,
      };
      this.minamelServices
        .SystemService()
        .getSystem()
        .search(params)
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this)) {
            this.sales.items = response.data;
            this.table.totalItems = response.total_records;

            if (this.search.onSearch) {
              this.search.onSearch = !this.search.onSearch;
            }

            this.table.totalPages = Math.ceil(
              this.table.totalItems / this.table.rowsPerPage
            );
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        })
        .finally(() => {
          this.loadTable(false);
          this.table.noDataText = this.CONSTANTS.texts.table.noDataText;
          this.table.noResultText = this.CONSTANTS.texts.table.noResultText;
        });
    },
    searchFocus() {
      this.$refs.search.focus();
    },
    pageChange(page) {
      this.table.currentPage = page;
    },
    loadTable(status) {
      this.table.loading = status;

      if (this.search.onSearch) {
        this.table.currentPage = 1;
        this.table.totalPages = 1;
      }

      if (status) {
        this.sales.items = [];
        this.sales.totalItems = 0;
      }
    },
    searchData() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.search.onSearch = true;
        this.getData();
      }, 800);
    },
    register() {
      this.$router.push({
        name: "RegisterSystem",
      });
    },
    rowAction(item) {
      this.$router.push({
        name: "RegisterSystem",
        params: { type: "edit", code: item.item.CODIGO },
      });
    },
  },
  watch: {
    "table.currentPage": {
      handler() {
        if (!this.search.onSearch) {
          this.getData();
        }
      },
      deep: true,
    },
    "table.pagination.sortBy": {
      handler() {
        if (!this.search.onSearch) {
          this.getData();
        }
      },
      deep: true,
    },
    "table.rowsPerPage": {
      handler() {
        if (!this.search.onSearch) {
          this.clearPagination = true;
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.tableButton {
  margin: 6px 0px 6px 0px;
}
.active-item {
  background-color: red;
}
</style>

<template>
    <v-container grid-list-md fluid>
        <v-row row>
            <v-col xs="12" >
                <v-subheader>
                    <h2>Agenda</h2>
                </v-subheader>
            </v-col>
        </v-row>
        <v-row wrap>
            <v-col xs="12" class="mb-3">
                <v-card>
                    <v-card-title>
                        <v-col sm4 xs="12" class="text-sm-left text-xs-center">
                            <v-btn @click="$refs.calendar.prev()">
                                <v-icon dark left>chevron-left</v-icon
                                >Anterior
                            </v-btn>
                        </v-col>

                        <v-col sm4 xs="12" class="text-xs-center">
                            <v-select
                                v-model="type"
                                :items="typeOptions"
                                label="Visualização"
                            ></v-select>
                        </v-col>

                        <v-col sm4 xs="12" class="text-sm-right text-xs-center">
                            <v-btn @click="$refs.calendar.next()">
                                Próximo
                                <v-icon right dark>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                         <v-col xs="12" class="text-xs-center" primary-title>
                            <h2 class="headline mb-0">{{ currentDate }}</h2>
                        </v-col>
                    </v-card-title>
                    <v-card-text>
                        <v-sheet height="70vh">
                            <v-calendar
                                ref="calendar"
                                :type="type"
                                v-model="start"
                                :end="end"
                                color="primary"
                                locale="pt-br"
                            >

                            <template v-slot:day="{ date }">
                                    <template v-for="event in eventsMap[date]">
                                        <v-menu
                                            :key="event.id"
                                            v-model="event.open"
                                            full-width
                                            :close-on-content-click="false"
                                        >
                                            <div
                                                slot="activator"
                                                v-ripple
                                                :class="event.type ? 'my-notification': 'my-event'"
                                            >
                                                <v-icon left dark small>
                                                    {{event.type ? "notifications": "event" }}
                                                </v-icon>

                                                {{ event.title }}
                                            </div>
                                            <v-card
                                                color="grey lighten-4"
                                                min-width="350px"
                                                flat
                                            >
                                                <v-toolbar :color="event.type ? 'green' : 'primary' " dark>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn
                                                                @click="edit(event.project_id)"
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-icon>content_paste</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Editar Projeto</span>
                                                    </v-tooltip>
                                                    <v-toolbar-title
                                                        v-html="event.title"
                                                    ></v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-btn icon>
														<v-icon>more_vert</v-icon>
													</v-btn>-->
                                                </v-toolbar>
                                                <v-card-title
                                                    v-if="event.details"
                                                    primary-title
                                                >
                                                    <span
                                                        v-html="event.details"
                                                    ></span>
                                                </v-card-title>
                                                <v-card-actions>
                                                    <v-chip v-if="event.type">
                                                        Faltam apenas
                                                        {{ event.days }} dias
                                                        para o evento
                                                        <strong
                                                            style="
                                                                margin-left: 4px;
                                                                color: blue;
                                                            "
                                                            >{{
                                                                events.find(
                                                                    (ev) => {
                                                                        return ev.id ==
                                                                            event.project_date_id
                                                                            ? ev
                                                                            : null;
                                                                    }
                                                                ).title
                                                            }}</strong
                                                        >
                                                    </v-chip>
                                                    <v-btn
                                                        v-else
                                                        @click="
                                                            event.open = false
                                                        "
                                                        flat
                                                        color="secondary"
                                                        >OK</v-btn
                                                    >
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </template>

                                <template v-slot:dayHeader="{ date }">
                                    <template v-for="event in eventsMap[date]">
                                        <v-menu
                                            :key="event.id"
                                            v-model="event.open"
                                            full-width
                                            :close-on-content-click="false"
                                        >
                                            <div
                                                slot="activator"
                                                v-ripple
                                                :class="event.type ? 'my-notification': 'my-event'"
                                            >
                                                <v-icon left dark small>
                                                    {{event.type ? "notifications": "event" }}
                                                </v-icon>

                                                {{ event.title }}
                                            </div>
                                            <v-card
                                                color="grey lighten-4"
                                                min-width="350px"
                                                flat
                                            >
                                                <v-toolbar :color="event.type ? 'green' : 'primary' " dark>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn
                                                                @click="edit(event.project_id)"
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-icon>content_paste</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Editar Projeto</span>
                                                    </v-tooltip>
                                                    <v-toolbar-title
                                                        v-html="event.title"
                                                    ></v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-btn icon>
														<v-icon>more_vert</v-icon>
													</v-btn>-->
                                                </v-toolbar>
                                                <v-card-title
                                                    v-if="event.details"
                                                    primary-title
                                                >
                                                    <span
                                                        v-html="event.details"
                                                    ></span>
                                                </v-card-title>
                                                <v-card-actions>
                                                    <v-chip v-if="event.type">
                                                        Faltam apenas
                                                        {{ event.days }} dias
                                                        para o evento
                                                        <strong
                                                            style="
                                                                margin-left: 4px;
                                                                color: blue;
                                                            "
                                                            >{{
                                                                events.find(
                                                                    (ev) => {
                                                                        return ev.id ==
                                                                            event.project_date_id
                                                                            ? ev
                                                                            : null;
                                                                    }
                                                                ).title
                                                            }}</strong
                                                        >
                                                    </v-chip>
                                                    <v-btn
                                                        v-else
                                                        @click="
                                                            event.open = false
                                                        "
                                                        flat
                                                        color="secondary"
                                                        >OK</v-btn
                                                    >
                                                </v-card-actions>
                                            </v-card>
                                        </v-menu>
                                    </template>
                                </template>
                            </v-calendar>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import jwt from "vue-jwt-decode";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
	name: "Calendar",
	components: {},
	data: () => ({
		userData: null,
		type: "month",
		start: new Date().toISOString(),
		end: new Date().toISOString(),
		typeOptions: [
			{
				text: "Díario",
				value: "day",
				format: "day/month/year"
			},
			{
				text: "Semanal",
				value: "week",
				format: "month/year"
			},
			{
				text: "Mensal",
				value: "month",
				format: "month/year"
			}
		],
		events: []
	}),
	computed: {
		currentDate () {
			let format = this.typeOptions.filter((typeOption) => {
				return typeOption.value == this.type ? typeOption : null;
			})[0].format;

			return this.formatDate(this.start, format);
		},
		// convert the list of events into a map of lists keyed by date
		eventsMap () {
			const map = {};
			this.events.forEach((e) =>
				(map[e.date] = map[e.date] || []).push(e)
			);
			return map;
		}
	},
	async mounted () {
		this.userData = (await sessionStorage.token)
			? jwt.decode(sessionStorage.token).userData
			: null;

		await this.getData();
	},
	methods: {
		async getData () {
			const params = {
				notificationType: 3,
				userId: this.userData.id
			};

			try {
				const datas = await this.minamelServices
					.ProjectNotificationService()
					.getAll(params);

				if (
					!datas.error &&
                    datas.data.length &&
                    datas.data.length > 0
				) {
					datas.data.map((data) => {
						this.events.push({
							title: data.name,
							details: data.name,
							date: data.date,
							time: "12:00",
							open: false,
							id: data.id,
							project_id: data.project_id
						});
						if (
							data.projectDateNotifies &&
                            data.projectDateNotifies.length
						) {
							data.projectDateNotifies.map((notify) => {
								let dt = new Date(data.date);
								dt.setDate(dt.getDate() - notify.days);
								dt = `${dt.getFullYear()}-${(
									"0" +
                                    (dt.getMonth() + 1)
								).slice(-2)}-${dt.getDate()}`;

								this.events.push({
									title: notify.name,
									date: dt,
									time: "09:00",
									open: false,
									id: notify.id,
									type: "notify",
									days: notify.days,
									project_date_id: data.id,
									project_id: data.project_id
								});
								return notify;
							});
						}
						return data;
					});
				}
			} catch (error) {
				console.error(error);
			}
		},
		edit (itemId) {
			this.$router.push({
				name: "EditProject",
				params: { id: itemId }
			});
		},
		open (event) {
			alert(event.title);
		},
		formatDate (date, format) {
			if (!date) return;
			if (format) {
				let new_date = format;
				if (format.includes("day")) {
					new_date = new_date.replace("day", `${date.split("-")[2]}`);
				}
				if (format.includes("month")) {
					new_date = new_date.replace(
						"month",
						this.getMonthName(date.split("-")[1])
					);
				}
				if (format.includes("year")) {
					new_date = new_date.replace(
						"year",
						`${date.split("-")[0]}`
					);
				}

				return new_date;
			}

			return `${date.split("-")[2]}/${date.split("-")[1]}/${
				date.split("-")[0]
			}`;
		},

		getMonthName(value) {
			switch (value) {
			case "01": return "Janeiro";
			case "02": return "Fevereiro";
			case "03": return "Março";
			case "04": return "Abril";
			case "05": return "Maio";
			case "06": return "Junho";
			case "07": return "Julho";
			case "08": return "Agosto";
			case "09": return "Setembro";
			case "10": return "Outubro";
			case "11": return "Novembro";
			case "12": return "Dezembro";
			}
		}
	}
};
</script>

<style scoped>
.my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
}
.with-time {
    position: absolute;
    right: 4px;
    margin-right: 0px;
}

.my-notification {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: green;
    color: #ffffff;
    border: 1px solid green;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
}
</style>

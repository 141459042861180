<template>
  <div class="w-100">
    <div class="text-center">
      <div id="original" class="overline mx-auto">
        <div class="image-container w-100 elevation-0">
          <img ref="source" class="image-preview" :src="objectUrl" />
        </div>

        <div class="d-flex justify-center mx-auto">
          <v-btn class="flex-item w-100" depressed text @click="doneCrop">
            <v-icon> mdi-check </v-icon>

            Salvar
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import ComponentHelpers from "@/shared/helpers/ComponentHelpers";
import ModelManagementMixin from "@/shared/mixins/ModelManagementMixin";

export default {
  name: "ImageCropper",
  components: {},
  mixins: [ModelManagementMixin],
  props: {
    image: [File, Object, String],
    aspectRatio: Number,
    smH: String,
    smW: String,
    mdH: String,
    mdW: String,
    cropType: Number,
  },
  data() {
    return {
      cropper: null,
      objectUrl: null,
      previewCropped: null,
      debounceUpdatePreview: ComponentHelpers.registerDebounce(
        this.updatePreview,
        257
      ),
    };
  },
  mounted() {
    this.setupCropper();
  },
  methods: {
    setupCropper() {
      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }

      if (!this.image) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;

        return;
      }

      this.objectUrl = window.URL.createObjectURL(this.image);
      this.$nextTick(this.setupCropperInstance);
    },
    setupCropperInstance() {
      const cropType =
        this.cropType != "undefined"
          ? this.cropType
          : this.$cropTypes.fitContainer;

      this.cropper = new Cropper(this.$refs.source, {
        aspectRatio: this.aspectRatio,
        crop: this.debounceUpdatePreview,
        guides: false,
        center: true,
        highlight: true,
        minContainerHeight: `${this.getHeight()}px`,
        minContainerWidth: `${this.getWidth()}px`,
        dragMode: "move",
        autoCrop: true,
        responsive: true,
        viewMode: cropType,
      });
    },
    getHeight() {
      const isSmOrLess = this.$vuetify.breakpoint.smAndDown;

      if (isSmOrLess) {
        return this.smH;
      }

      return this.mdH;
    },
    getWidth() {
      const isSmOrLess = this.$vuetify.breakpoint.smAndDown;

      if (isSmOrLess) {
        return this.smW;
      }

      return this.mdW;
    },
    updatePreview() {
      const canvas = this.cropper.getCroppedCanvas();

      this.previewCropped = canvas.toDataURL("image/png");
    },
    doneCrop() {
      this.$emit("input", {
        content: this.previewCropped,
        name: this.image.name,
        preview: this.previewCropped,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.image-container
    display: inline-block
    max-height: 200px
    max-width: 800px

.image-preview
    display: block
    max-width: 100%
</style>

<template>
  <v-card class="elevation-0" style="margin-bottom: 70px !important">
    <v-form>
      <v-row row wrap>
        <v-col md="3" xs="12" sm="6">
          <v-card color="grey lighten-5" class="elevation-1">
            <v-card-text>
              <span class="d-block pb-3">Projeto</span>
              <h3 class="subtitle primary--text">
                {{ data.project ? data.project.name : "" }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col xs="12" md="4" sm="6">
          <v-card color="grey lighten-5" class="elevation-1">
            <v-card-text>
              <span class="d-block pb-3">Produtor/agregado</span>
              <h3 class="subtitle primary--text">
                {{ data.supplier ? data.supplier.name : "" }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col xs="12" md="3" sm="6">
          <v-card color="grey lighten-5" class="elevation-1">
            <v-card-text>
              <span class="d-block pb-3"> Data de preenchimento </span>
              <h3 class="subtitle primary--text">
                {{
                  data.answered_at
                    ? $functions.formatDate(data.answered_at)
                    : "Pendente"
                }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col xs="12" md="2" sm="6">
          <v-card color="grey lighten-5" class="elevation-1">
            <v-card-text>
              <span class="d-block pb-3">Status</span>
              <h3 class="subtitle primary--text">
                {{ data.id ? "Preenchido" : "Pendente" }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2 class="headline">{{ data.form_name }}</h2>
          <span
            class="float-right font-weight-bold"
            :class="onlyView ? 'blue--text' : 'red--text'"
          >
            ({{ onlyView ? "Modo de visualização" : "Modo de edição" }})
          </span>

          <span class="d-block required grey--text mt-4">
            * Preenchimento obrigatório.
          </span>
        </v-col>
      </v-row>

      <ItemQuestion
        v-for="(item, i) in data.formFields"
        :item="item"
        :key="i"
        :onlyView="onlyView"
      />

      <v-divider></v-divider>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!onlyView"
          style="color: white"
          color="green"
          @click="save"
        >
          Salvar
        </v-btn>

        <v-btn color="error" text :to="`${routeName}/responses`">
          {{ onlyView ? "Voltar" : "Cancelar" }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import jwt from "vue-jwt-decode";
import ItemQuestion from "./ItemQuestion";

export default {
  name: "ResponseRegister",

  props: {
    routeName: {
      type: String,
      default: "",
    },
    onlyView: { type: Boolean, default: false },
  },

  components: {
    ItemQuestion,
  },

  data() {
    return {
      userData: null,
      formId: null,
      supplierId: null,
      data: {},
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },

  async mounted() {
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.projectFormResponseId = this.$route.params.projectFormResponseId;
    this.formResponseId = this.$route.params.formResponseId;

    await this.getData();
  },

  methods: {
    async getData() {
      try {
        const response = await this.minamelServices
          .ProjectFormResponseService()
          .show(this.projectFormResponseId, this.formResponseId);

        this.data = response.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.$forceUpdate();
      }
    },

    async save() {
      const alertConfirm = await this.alertConfirm("Atenção", "warning");

      if (!alertConfirm) {
        return;
      }

      this.data.formFields.forEach((item) => {
        if (item.type === "TEXTBLOCK") {
          item.response = item.help_text;
        }
      });

      if (this.data.id || this.data.import_key) {
        this.update();
      } else {
        this.create();
      }
    },

    alertConfirm(ask, type) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: type,
          text: "O preenchimento deste formulário é de responsabilidade do produtor/agregado. Deseja realmente alterá-lo?",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },

    async create() {
      try {
        const data = {
          form_id: this.data.form_id,
          project_id: this.data.project_id,
          supplier_id: this.data.supplier_id,
          answered_by_user_import_key: this.userData.import_key,
          form_fields: this.data.formFields,
          id: this.data.id,
          import_key: this.data.import_key,
        };

        const response = await this.minamelServices
          .FormResponseService()
          .store(data);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Formulário preenchido com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(this.routeName + "/responses");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const data = {
          form_id: this.data.form_id,
          project_id: this.data.project_id,
          supplier_id: this.data.supplier_id,
          answered_by_user_import_key: this.userData.import_key,
          form_fields: this.data.formFields,
          id: this.data.id,
          import_key: this.data.import_key,
        };

        const response = await this.minamelServices
          .FormResponseService()
          .update(this.data.id, data);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Formulário alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(this.routeName + "/responses");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao alterar os dados.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {UploadService}
 */
export default class UploadService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'upload/';
    
    getUpload() {
        this.path = "upload/get";
        return this;
    }

    sendUpload() {
        this.path = "upload/send";
        return this;
    }
}
import Vue from 'vue';
import pt from 'vuetify/es5/locale/pt';
import colors from 'vuetify/lib/util/colors'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
	lang: {
		locales: { pt },
		current: 'pt',
	},
	icons: {
		iconfont: 'md',
	},
	theme: {
		themes: {
			light: {
				primary: '#b9041c',
				secondary: colors.red,
				accent: colors.shades.black,
				error: colors.red.accent3
			}
		}
	},
});

export default vuetify;
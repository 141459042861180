import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {InventoryMovementService}
 */
export default class InventoryMovementService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'inventory-movements/';
    
    getInventoryMovement() {
        this.path = "inventory-movements/get";
        return this;
    }

    getInventoryMovementBalanceByPeriod() {
        this.path = "inventory-movements/get-balance-by-period";
        return this;
    }

    createInventoryMovement(id = null) {
        if(id){
            this.path = "inventory-movements/update";
        }else{
            this.path = "inventory-movements/create";
        }
        return this;
    }
    updateInventoryMovement() {
        this.path = "inventory-movements/update";
        return this;
    }
    deleteInventoryMovement() {
        this.path = "inventory-movements/delete";
        return this;
    }

}
import Rest from './base/Rest';

/**
 * @typedef {DocumentTemplateService}
 */
export default class DocumentTemplateService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'document-templates/';

    get(params) {
        return super.get(`/?${this.queryString(params)}`);
    }

    show(id) {
        return super.get(`/${id}`);
    }

    store(data) {
        return super.post('/', data);
    }

    update(id, data) {
        return super.put(`/${id}`, data);
    }

    destroy(id) {
        return super.delete(`/${id}`);
    }

    print(id) {
        return super.getFile(`/${id}/print`);
    }

    printFilled(id) {
        return super.getFile(`/print-filled/${id}`);
    }
}

import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados à apiários dos projetos de apiários;
 * @typedef {ProjectApiaryService}
 */
export default class ProjectApiaryService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'project-apiary/';
    
    getProjectApiary() {
        this.path = "project-apiary/get";
        return this;
    }
    createProjectApiary(id = null) {
        if(id){
            this.path = "project-apiary/update";
        }else{
            this.path = "project-apiary/create";
        }
        return this;
    }
    updateProjectApiary() {
        this.path = "project-apiary/update";
        return this;
    }
    deleteProjectApiary() {
        this.path = "project-apiary/delete";
        return this;
    }

    updateProjectApiaryFlowery() {
        this.path = "project-apiary/update-project-apiary-flowery";
        return this;
    }
}
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {LoginService}
 */
export default class LoginService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = 'login/';

	loginUser() {
		this.path = "login/";
		return this;
	}

	loginChecktoken() {
		this.path = "login/checktoken";
		return this;
	}
}

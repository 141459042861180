<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col>
        <v-row row>
          <v-col xs="12">
            <v-subheader>
              <h2>Operações</h2>
            </v-subheader>

            <v-row row>
              <v-col xs="7">
                <v-text-field
                  v-model="search.term"
                  ref="search"
                  label="Faça a sua busca"
                  clear-icon="close"
                  clearable
                  append-icon="search"
                  @click:append="getData"
                  @keyup="searchData"
                  @keyup.enter="getData"
                />
              </v-col>
              <v-col xs="2" class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      large
                      color="normal"
                      v-on="on"
                      @click="filters = !filters"
                      v-shortkey="['ctrl', 'f']"
                      @shortkey="searchFocus"
                    >
                      <v-icon>filter_list</v-icon>
                    </v-btn>
                  </template>
                  <span>Clique para selecionar os filtros</span>
                </v-tooltip>
              </v-col>

              <v-col />
              <v-col class="text-xs-right d-flex">
                <v-btn
                  class="flex-one ml-auto"
                  color="primary"
                  large
                  @click="register"
                  >+ Incluir</v-btn
                >
              </v-col>
            </v-row>

            <v-expand-transition>
              <v-container fluid class="pa-0">
                <v-row v-show="filters">
                  <v-col md="12">
                    <v-card class="elevation-1 pa-3 mb-4">
                      <v-row row>
                        <v-col xs="12">
                          <v-row row>
                            <v-col xs="6" class="caption"
                              >Selecione os campos para fazer a busca</v-col
                            >
                            <v-col xs="6" class="text-xs-right">
                              <v-btn
                                @click="clearFilters"
                                text
                                small
                                class="ml-auto"
                                color="primary"
                              >
                                <v-icon>clear</v-icon>Limpar todos os filtros
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row row>
                        <v-col
                          v-for="field in search.fieldToSearch"
                          :key="field.id"
                          xs4
                        >
                          <v-select
                            clearable
                            style="padding: 15px; margin-top: 15px"
                            color="primary"
                            :items="field.options"
                            v-model="field.value"
                            :multiple="field.multiple"
                            item-value="id"
                            item-text="text"
                            :label="field.description"
                            @change="filter"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>

            <v-data-table
              :headers="types.headers"
              :items="table.items"
              :loading="table.loading"
              :pagination.sync="table.pagination"
              :total-items="table.totalItems"
              :no-results-text="table.noResultText"
              :no-data-text="table.noDataText"
              item-key="B1_COD"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  label
                  v-if="item.status == '2'"
                  color="green"
                  text-color="white"
                  >Confirmado</v-chip
                >
                <v-chip
                  label
                  v-if="item.status == '1'"
                  color="#7d7d7d"
                  text-color="white"
                  >Pendente</v-chip
                >
              </template>

              <template v-slot:[`item.movement_date`]="{ item }">
                {{ item.movement_date | formatDate }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-x left bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon text v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="d-flex flex-column">
                    <v-list-item @click="edit(item)" v-if="item.status == '1'">
                      <v-list-item-title class="cursorPointer"
                        >Editar</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="view(item)">
                      <v-list-item-title class="cursorPointer"
                        >Visualizar</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="confirm(item)"
                      v-if="item.status == '1'"
                    >
                      <v-list-item-title class="cursorPointer"
                        >Confirmar</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="deleteOperation(item)"
                      v-if="item.status == '1'"
                    >
                      <v-list-item-title class="cursorPointer"
                        >Excluir</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="pdf(item)"
                      v-if="
                        item.movement_type_is_adjustment == null &&
                        item.movement_type_emit_receipt
                      "
                    >
                      <v-list-item-title class="cursorPointer"
                        >Gerar recibo</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template slot="no-data">
                <div class="text-xs-center">{{ table.noDataText }}</div>
              </template>
            </v-data-table>

            <div>
              <v-card class="pa-4">
                <v-row row>
                  <v-col md="3"> </v-col>
                  <v-col md="6" class="text-xs-center">
                    <v-pagination
                      v-model="table.currentPage"
                      :length="table.totalPages"
                      v-bind:disabled="table.disabled"
                      @input="pageChange"
                      :total-visible="table.totalPagesVisible"
                      prev-icon="arrow_left"
                      next-icon="arrow_right"
                      primary
                    />
                  </v-col>
                  <v-col md="3">&nbsp;</v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Operations",
  components: {},
  filters: {
    formatDate: function (value) {
      if (!value) return null;
      const [year, month, day] = value.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.token = this.minamelServices.getToken();
    this.$http
      .get(this.minamelServices.getUrl() + "movement-types/get", {
        params: {},
        headers: { token: this.token },
      })
      .then((response) => {
        if (response.body.error) {
          this.snackbar.color = "error";
          this.snackbar.message = response.body.message;
          this.$root.$SnackBar.show(this.snackbar);

          return;
        }

        this.search.fieldToSearch[1].options = response.body.data.map(
          (movement_type) => {
            return {
              id: movement_type.id,
              text: movement_type.name,
            };
          }
        );
      })
      .catch(() => {
        this.snackbar.color = "error";
        this.snackbar.message = this.CONSTANTS.texts.errors.connection;
        this.$root.$SnackBar.show(this.snackbar);
      })
      .finally(() => {
        this.getData();
      });
  },
  methods: {
    pageChange(page) {
      this.table.currentPage = page;
    },
    pdf(item) {
      this.$http
        .post(
          this.minamelServices.getUrl() + "pdf/receipt",
          {
            ...item,
            ...{ confirm: item.status == 2 },
          },
          {
            headers: { token: this.token },
          }
        )
        .then((response) => {
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);
            return;
          }

          var date = new Date();
          var ms = date.getMilliseconds();
          var stringDate =
            date.getFullYear() + "_" + date.getMonth() + "_" + date.getDate();

          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + response.body.response;
          a.download =
            "RECIBO_" + stringDate + "_" + item.id + "_" + ms + ".pdf";
          a.click();
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        })
        .finally(() => {});
    },
    deleteOperation(operation) {
      this.$swal
        .fire({
          title: "Atenção",
          text: "Deseja excluir esta operação?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Sim, excluir",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.value) {
            this.$http
              .delete(this.minamelServices.getUrl() + "operations/delete", {
                params: {
                  operation_id: operation.id,
                },
                headers: { token: this.token },
              })
              .then((response) => {
                if (response.body.error) {
                  this.snackbar.color = "error";
                  this.snackbar.message = response.body.message;
                  this.$root.$SnackBar.show(this.snackbar);
                  return;
                }

                setTimeout(() => {
                  this.snackbar.message = response.body.message;
                  this.$root.$SnackBar.show(this.snackbar);

                  this.getData();
                }, 1500);
              });
          }
        });
    },
    confirm(operation) {
      this.$swal
        .fire({
          title: "Atenção",
          text: "Deseja confirmar esta operação?",
          type: "info",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return;
          }

          this.$root.$LoadingBar.show("Carregando");

          this.$http
            .post(
              this.minamelServices.getUrl() + "operations/confirm",
              {
                id: operation.id,
              },
              {
                headers: { token: this.token },
              }
            )
            .then((response) => {
              if (response.body.error) {
                this.snackbar.color = "error";
                this.snackbar.message = response.body.message;
                this.$root.$SnackBar.show(this.snackbar);

                return;
              }

              setTimeout(() => {
                this.snackbar.message = response.body.message;
                this.$root.$SnackBar.show(this.snackbar);

                this.$router
                  .push({
                    name: "Operations",
                  })
                  .catch(() => {});
              }, 1500);
            })
            .catch(() => {
              this.snackbar.color = "error";
              this.snackbar.message = this.CONSTANTS.texts.errors.connection;
              this.$root.$SnackBar.show(this.snackbar);
            })
            .finally(() => {
              this.$root.$LoadingBar.hide();

              setTimeout(() => {
                this.getData();
              }, 500);
            });
        });
    },
    filter() {
      this.table.currentPage = 1;
      this.table.totalPages = 1;
      this.getData();
    },
    clearFilters() {
      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        this.search.fieldToSearch[i].value = "";
      }
      this.getData();
    },
    edit(item) {
      this.$router.push({
        name: "EditOperation",
        params: {
          id: item.id,
        },
      });
    },
    view(item) {
      this.$router.push({
        name: "ViewOperation",
        params: {
          id: item.id,
        },
      });
    },
    register() {
      this.$router.push({ name: "RegisterOperation" });
    },
    loadTable(status) {
      this.table.loading = status;

      if (this.search.onSearch) {
        this.table.currentPage = 1;
        this.table.totalPages = 1;
      }

      if (status) {
        this.table.items = [];
        this.table.totalItems = 0;
      }
    },
    searchData() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.search.onSearch = true;
        this.getData();
      }, 800);
    },
    getData() {
      if (this.table.loading) {
        return;
      }

      const { sortBy, descending } = this.table.pagination;

      this.loadTable(true);
      this.table.noDataText = this.CONSTANTS.texts.table.loadingText;
      this.table.noResultText = this.CONSTANTS.texts.table.loadingText;
      this.table.disabled = false;

      let itensToSearch = [];

      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        if (this.search.fieldToSearch[i].value) {
          itensToSearch.push({
            field: this.search.fieldToSearch[i].field,
            value: this.search.fieldToSearch[i].value,
          });
        }
      }

      const params = {
        sort: sortBy,
        orientation: descending == null ? "" : !descending ? "ASC" : "DESC",
        page: this.table.currentPage,
        rowsPerPage: this.table.rowsPerPage,
        term: this.search.term,
        fieldsToSearch: itensToSearch.length <= 0 ? "" : itensToSearch,
      };

      this.$http
        .get(this.minamelServices.getUrl() + "operations/get", {
          params: params,
          headers: { token: this.token },
        })
        .then((response) => {
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);

            return;
          }

          this.table.items = response.body.data;
          this.table.totalItems = response.body.total_records;

          if (this.search.onSearch) {
            this.search.onSearch = !this.search.onSearch;
          }

          this.table.totalPages = Math.ceil(
            this.table.totalItems / this.table.rowsPerPage
          );
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        })
        .finally(() => {
          this.loadTable(false);
          this.table.noDataText = this.CONSTANTS.texts.table.noDataText;
          this.table.noResultText = this.CONSTANTS.texts.table.noResultText;
        });
    },
    back() {},
    searchFocus() {
      this.$refs.search.focus();
    },
  },
  watch: {
    "table.currentPage": {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "table.pagination.sortBy": {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "table.rowsPerPage": {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      token: null,
      movement_types: [],
      types: {
        headers: [
          {
            text: "Nº Documento",
            align: "left",
            value: "id",
          },
          {
            text: "Tipo de Mov.",
            align: "left",
            value: "movement_type_id",
          },
          {
            text: "Fornecedor",
            align: "left",
            value: "supplier_name",
          },
          {
            text: "Quantidade de barril tipo A",
            align: "left",
            value: "a_type_barrel_quantity",
          },
          {
            text: "Quantidade de barril tipo B",
            align: "left",
            value: "b_type_barrel_quantity",
          },
          {
            text: "Status",
            align: "left",
            value: "status",
          },
          {
            text: "Data",
            align: "left",
            value: "movement_date",
          },
          {
            text: "Ações",
            align: "left",
            value: "actions",
          },
        ],
        items: [],
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
      filters: false,
      search: {
        checkAll: true,
        onSearch: false,
        term: "",
        fieldToSearch: [
          {
            description: "Status",
            field: "operations.status",
            value: ["1"],
            multiple: true,
            options: [
              { id: "1", text: "Pendente" },
              { id: "2", text: "Confirmado" },
            ],
          },
          {
            description: "Tipo de Movimentação",
            field: "movement_types.id",
            multiple: true,
            value: "",
            options: [],
          },
        ],
      },
      table: {
        disabled: false,
        loading: false,
        totalPagesVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,
        currentPage: 1,
        totalPages: 1,
        rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
        rowPerPageItems: 50,
        pagination: {
          rowsPerPage: 0,
          sortBy: "",
          descending: false,
          page: 1,
          totalItems: 0,
        },
        totalItems: 0,
      },
    };
  },
};
</script>

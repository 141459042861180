<template>
    <v-row
        :style="{ backgroundColor: system.color }"
        v-if="system"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
        "
    >
        <v-card color="transparent" flat>
            <v-img
                width="300"
                :style="{ backgroundColor: system.color }"
                class="white--text align-end"
                :src="system.base64"
            >
            </v-img>

            <!-- <h1 style="font-weight: 600" class="pb-0">{{system.name}}</h1>
			<div>{{system.desc}}</div> -->

            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
        </v-card>
    </v-row>
</template>

<script>
import jwt from 'vue-jwt-decode';
import logoIntranet from '../../assets/logo.png';

export default {
	name: "TransitionSystem",
	async mounted () {
		this.userData = await sessionStorage.token ? jwt.decode(sessionStorage.token).userData : null;
		this.systemData = await sessionStorage.token ? jwt.decode(sessionStorage.token).systemData : null;
		this.listSystems();
	},
	data () {
		return ({
			userData: {},
			systemData: {},
			systems: [],
			system: null
		});
	},
	methods: {
		async listSystems () {
			// delete this.systemData[this.userData.intranetId];

			const systemDataArray = Object.keys(this.systemData).map((key) => this.systemData[key]);

			for (let index = 0; index < systemDataArray.length; index++) {
				let system = systemDataArray[index];

				try {
					if (this.userData.intranetId == system.id) {
						system.base64 = logoIntranet;
					} else {
						let response = await this.minamelServices.UploadService().getUpload().search({ system_id: system.id });
						if (response && response.data.length > 0) {
							system.base64 = response.data;
						}
					}
				} catch (error) {
					console.error(error);
				} finally {
					this.systems.push(system);
				}
			}
		},
		setSystem (system_id) {
			this.system = this.systems.find(system => { return system.id === system_id; });
		}
	}
};
</script>

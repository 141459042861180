<template>
  <v-card color="grey lighten-5" class="elevation-1">
    <v-card-text>
      <span class="d-block pb-3">{{ label }}</span>
      <h3 class="subtitle primary--text">
        {{ info }}
      </h3>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FormResponseSummaryItem",
  props: {
    label: String,
    info: String,
  },
};
</script>

<style></style>

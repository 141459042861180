<template>
	<v-container fluid class="my-4">
		<v-card class="cardBody">
			<v-row>
				<v-col>
					<div class="groupButtonRow">
						<button class="groupButton" v-for="item in apiaryGroups" :key="item.id" @click="toggleGroupFilter(item.id)">
							{{ item.name }} ({{ item.apiary?.length }})
						</button>
					</div>
					<div class="groupButtonRow">
						<button class="groupButton" @click="toggleGroupFilter('all')">
							Listar Todos ({{ withGroup }})
						</button>
						<button class="groupButton" @click="toggleGroupFilter('null')">
							Listar Todos Sem Grupo ({{ withoutGroup }})
						</button>
					</div>
				</v-col>
				<div style="width: 20%; padding-right: 10px; align-content: end;">
					<v-text-field v-model="filter.term" label="Faça a sua busca" clear-icon="close" clearable
						append-icon="search" @click:append="getData" @keyup="getData" @keyup.enter="getData" />
				</div>
			</v-row>
			<div>
				<GoogleMapLoader :apiaries="apiaries" />
				<v-row style="padding-top: 20px;">
					<v-col>
						<Totalizer title="Produtores" :count="countSuppliers" route="/suppliers-select" />
					</v-col>
					<v-col>
						<Totalizer title="Apiários" :count="countApiaries" route="/apiaries-select" />
					</v-col>
					<v-col>
						<Totalizer title="Análises" :count="0" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<Totalizer title="Planos de Manejo" :count="0" />
					</v-col>
					<v-col>
						<Totalizer title="Auditorias" :count="0" />
					</v-col>
					<v-col>
						<Totalizer title="Avaliações" :count="0" />
					</v-col>
				</v-row>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import GoogleMapLoader from "@/shared/components/map/GoogleMapLoader.vue";
import Totalizer from "./components/Totalizer.vue"

export default {
	name: "DashboardConectar",
	components: {
		GoogleMapLoader,
		Totalizer,
	},
	data() {
		return {
			showFilters: false,
			filter: {
				apiaryGroup: 'all',
			},
			apiaryGroups: [],
			apiaries: [],
			withGroup: 0,
			withoutGroup: 0,
			countSuppliers: 0,
			countApiaries: 0,
			apiaryLocations: [],
			blockMap: false,
			observations: null,
		}
	},
	async mounted() {
		await this.getApiaryGroups();
		await this.getData();
	},
	methods: {
		async getApiaryGroups() {
			const response = await this.minamelServices
				.ApiaryGroupServiceV2()
				.getAll({ active: 1 });

			this.apiaryGroups = response.data;
		},

		toggleGroupFilter(group) {
			if (group !== this.filter.apiaryGroup) {
				this.filter.apiaryGroup = group;
				this.getData();
			}
		},

		async getData() {
			try {
				const params = {
					term: this.filter.term ?? "",
					apiaryGroup: this.filter.apiaryGroup ?? null,
				};

				const response = await this.minamelServices
					.ApiaryServiceV2()
					.getAll(params);

				if (response) {
					this.withoutGroup = response.without_group;
					this.withGroup = response.with_group;
					this.countSuppliers = response.suppliers;
					this.countSuppliers = response.suppliers;
					this.countApiaries = response.hive_total;
					this.apiaries = response.data;
	
					this.apiaryLocations = this.apiaries.map((apiary) => ({
						lat: Number(apiary.latitude),
						lng: Number(apiary.longitude),
					}));
				}

			} catch (err) {
				console.log(err);
			}
		},
	}
};
</script>

<style>
.cardBody {
	padding: 20px 20px 20px 20px;
}

.groupButtonRow {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 10px;
	gap: 10px;
}

.groupButton {
	background-color: rgb(37, 102, 223);
	color: white;
	padding: 8px 13px;
	border-radius: 5px;
	font-size: small;
}

.groupButton:hover {
	background-color: rgb(26, 75, 165);
}
</style>
<template>
  <div class="w-100">
    <image-upload-button
      v-if="doesntHaveFileAndPreview"
      ref="img_upload_button"
      v-model="selectedFile"
      @click="selectFile"
    />

    <image-cropper
      v-else-if="haveFileButNotMakePreviewYet && !skipCrop"
      v-model="model"
      :image="selectedFile"
      :aspect-ratio="responsivity.aspectRatio"
      :responsivity="{
        mdWidth: responsivity.mdWidth,
        mdHeight: responsivity.mdHeight,
        smWidth: responsivity.smWidth,
        smHeight: responsivity.smHeight,
      }"
    />

    <image-preview
      v-else
      v-model="model"
      :folder="folder"
      :responsivity="{
        maxWidth: responsivity.maxWidth,
        maxHeight: responsivity.maxHeight,
      }"
      @change="selectFile"
    />

    <image-upload-input
      ref="img_upload_input"
      v-model="selectedFile"
      :required="required"
    />
  </div>
</template>

<script>
import ImagePreview from "./components/ImagePreview.vue";
import ImageUploadButton from "./components/ImageUploadButton.vue";
import ImageUploadInput from "./components/ImageUploadInput.vue";
import ImageCropper from "./components/ImageCropper.vue";

import Responsivity from "./domain/Responsivity";
import ModelManagementMixin from "@/shared/mixins/ModelManagementMixin";

export default {
  name: "ImageDialog",
  components: {
    ImagePreview,
    ImageUploadInput,
    ImageCropper,
    ImageUploadButton,
  },
  mixins: [ModelManagementMixin],
  props: {
    ...Responsivity.props(),
    // eslint-disable-next-line vue/require-default-prop
    skipCrop: Boolean,
    filename: String,
    folder: String,
    required: Boolean,
  },
  data() {
    return {
      selectedFile: null,
    };
  },
  computed: {
    haveFileButNotMakePreviewYet() {
      return (!this.model || !this.model.preview) && this.selectedFile;
    },
    doesntHaveFileAndPreview() {
      return !this.model && !this.selectedFile;
    },
  },
  watch: {
    selectedFile() {
      if (!this.image) {
        if (this.skipCrop) {
          this.preparePreviewWithSkippingCrop(this.selectedFile);
        } else {
          this.$emit("input", this.image);
        }
      }
    },
    model() {
      this.$emit("input", this.model);
    },
  },
  methods: {
    preparePreviewWithSkippingCrop(selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        selectedFile.preview = event.target.result;

        this.$emit("input", selectedFile);
      };

      reader.readAsDataURL(selectedFile);
    },
    clear() {
      this.selectedFile = null;
      this.model = null;
      this.$refs.img_upload_input.clear();
    },
    loadFile(file) {
      this.selectedFile = file;
    },
    selectFile() {
      this.$refs.img_upload_input.selectFile();
    },
  },
};
</script>

<style></style>

export default class EFormResponseStatus {
    static ANSWERED = "ANSWERED";
    static IN_PROGRESS = "IN_PROGRESS";
    static APPROVED = "APPROVED";
    static NO_ANSWERED = "NO_ANSWERED";
    static DISAPPROVED = "DISAPPROVED";

    /**
     * 
     * @param {EFormResponseStatus} status - toSerializeStatus.
     * @returns {String} the used name in the system. 
     * 
     */
    static serializedStatus(status) {
        switch (status) {
            case EFormResponseStatus.ANSWERED:
                return "Preenchido";
            case EFormResponseStatus.NO_ANSWERED:
                return "Não Respondido";
            case EFormResponseStatus.IN_PROGRESS:
                return "Em Preenchimento";
            case EFormResponseStatus.APPROVED:
                return "Aprovado";
            case EFormResponseStatus.DISAPPROVED:
                return "Reprovado";
            default:
                return "Não Definido";
        }
    }
}

<template>
  <v-container fluid class="my-4">
    <v-row class="mb-n3">
      <v-col>
        <v-subheader>
          <slot name="title" />
        </v-subheader>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">
        <slot name="actions" />
      </v-col>
    </v-row>

    <v-card>
      <v-container fluid>
        <v-row>
          <v-col>
            <slot name="content" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <slot name="pagination" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "DefaultListLayout",
};
</script>

<style></style>

import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {AggregateService}
 */
export default class AggregateService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'aggregates/';

    getAggregate () {
		this.path = "aggregates/get";
		return this;
    }

    getAggregateSupplier () {
		this.path = "aggregates/get-suppliers";
		return this;
    }

    isValidAggregate () {
		this.path = "aggregates/is-valid";
		return this;
    }

    updateAggregateSystems () {
		this.path = "aggregates/update-aggregate-systems";
		return this;
    }

    syncAggregateSuppliers () {
		this.path = "aggregates/sync-suppliers";
		return this;
    }

    createAggregate (id = null) {
		if (id) {
			this.path = "aggregates/update";
		} else {
			this.path = "aggregates/create";
		}
		return this;
    }
    updateAggregate () {
		this.path = "aggregates/update";
		return this;
    }
    deleteAggregate () {
		this.path = "aggregates/delete";
		return this;
    }
}

/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * @typedef {FormResponseService}
 */
export default class FormResponseService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'form-responses/';

    // getAll (id, params) {
    // 	return super.get(`/${id}/?${this.queryString(params)}`);
    // }

    // show (projectId, formId) {
    // 	return super.get(`/${projectId}/edit/${formId}`);
    // }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    // destroy (id) {
    // 	return super.delete(`/${id}`);
    // }
}

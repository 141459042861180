<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col md="12">
        <v-card class="elevation-1 pa-3 mb-4">
          <v-form ref="form">
            <v-row row wrap>
              <v-col xs="12">
                <v-subheader>
                  <h2>{{ headerText }} círculo de mapa</h2>
                </v-subheader>
              </v-col>

              <v-col xs="12" md="12" pa-3>
                <span class="required grey--text"
                  >* Preenchimento obrigatório.</span
                >
              </v-col>

              <v-col xs="12" md="4" class="pa-3">
                <v-text-field
                  :rules="formRules"
                  v-model="register.name"
                  label="Nome"
                  class="required"
                  ref="name"
                />
              </v-col>

              <v-col xs="12" md="2" class="pa-3">
                <v-text-field
                  :rules="formRules"
                  type="number"
                  v-model="register.radius"
                  label="Raio(m)"
                  class="required"
                  ref="radius"
                />
              </v-col>

              <v-col xs="12" md="2" class="pa-3">
                <v-label>Cor</v-label>
                <input
                  class="box-color-picker"
                  type="color"
                  v-model="register.color"
                />
              </v-col>

              <v-col xs="12" md="2" class="pa-3">
                <v-switch
                  v-model="register.active"
                  color="primary"
                  :label="register.active ? 'Ativo' : 'Inativo'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn style="color: white" color="green" @click="save"
              >Salvar</v-btn
            >
            <v-btn color="error" text to="/circle-maps">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegisterCircleMap",
  data() {
    return {
      id: null,
      headerText: "Novo",
      formRules: [(v) => !!v || "Campo obrigatório!"],
      register: {
        id: "",
        radius: "",
        color: "#cccccc",
        active: 1,
        name: "",
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  mounted() {
    this.id = this.$route.params.id;

    if (this.id) {
      this.headerText = "Alterar ";
      this.getData();
    }
  },
  methods: {
    async getData() {
      const response = await this.minamelServices
        .CircleMapServiceV2()
        .show(this.id);

      this.register = response.data;
    },

    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.id) {
        this.update();
        return;
      }

      this.create();
    },

    async create() {
      try {
        const response = await this.minamelServices
          .CircleMapServiceV2()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Círculo de mapa incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/circle-maps");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .CircleMapServiceV2()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Círculo de mapa alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/circle-maps");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>
<style scoped>
.box-color-picker {
  width: 100% !important;
  height: 30px !important;
}
</style>

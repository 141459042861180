import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {ApiaryRemarkService}
 */
export default class ApiaryRemarkService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'apiary-remark/';
    
    getApiaryRemark() {
        this.path = "apiary-remark/get";
        return this;
    }

    createApiaryRemark(id = null) {
        if(id){
            this.path = "apiary-remark/update";
        }else{
            this.path = "apiary-remark/create";
        }
        return this;
    }
    updateApiaryRemark() {
        this.path = "apiary-remark/update";
        return this;
    }
    deleteApiaryRemark() {
        this.path = "apiary-remark/delete";
        return this;
    }
}
<template>
	<div>
		<v-snackbar
			v-model="visible"
			:color="snackbar.color"
			:timeout="snackbar.timeout"
			:multi-line="snackbar.multiLine"
			:vertical="snackbar.false"
			:bottom="snackbar.bottom"
			:left="snackbar.left"
			:right="snackbar.right"
			:top="snackbar.top"
		>
			{{ snackbar.message }}
			<v-btn dark text @click="visible = false"><v-icon>close</v-icon></v-btn>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	name: 'SnackBar',
	data () {
		return {
			visible: false,
			snackbar: {
				message: '',
				color: 'success',
				timeout: 60000,
				bottom: false,
				left: false,
				right: true,
				top: true,
				multiLine: true
			}
		};
	},
	methods: {
		show (obj) {
			this.visible = true;
			this.snackbar = obj;
		},
		hide () {
			this.visible = false;
		}
	}
};
</script>

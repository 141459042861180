import mammoth from 'mammoth';

export async function extractHtmlFromDocx(file) {
    try {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        return result.value;
    } catch (error) {
        console.error('Erro ao ler o arquivo .docx:', error);
        return null;
    }
}

export async function extractQuestions(html, keyword) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const tables = doc.querySelectorAll('table');
    const questions = [];
    let foundKeyword = false;
    let questionNumber = 1;

    tables.forEach(table => {
        const rows = table.querySelectorAll('tr');
        rows.forEach(row => {
            const cells = row.querySelectorAll('td');

            if (cells.length > 0) {
                cells.forEach(cell => {
                    if (cell.textContent.includes(keyword)) foundKeyword = true
                });

                if (foundKeyword) {
                    if (cells.length >= 2) {
                        const question = cells[0].textContent.trim();
                        const resposta = cells[1].textContent.trim();

                        if (question.length > 0) {
                            let questionType = 'TEXTAREA';
                            let label = question;
                            let options = [];

                            if (question.includes(':')) {
                                const parts = question.split(':');

                                if (parts.length > 1) {
                                    label = parts[0] + ":";

                                    const rawOptions = parts[1].split(';');

                                    options = rawOptions
                                        .filter(option => option.trim().length > 0)
                                        .map((option, index) => ({
                                            id: index + 1,
                                            text: option.trim(),
                                        }));

                                    const radioPattern = /^\d+-/;
                                    const allOptionsAreRadio = options.every(opt => radioPattern.test(opt.text));

                                    questionType = allOptionsAreRadio ? 'RADIO' : 'CHECKBOX';
                                }
                            }

                            label = `${questionNumber}) ${label.trim()}`;
                            questionNumber++;

                            questions.push({
                                label: label,
                                options: options.length > 0 ? options : null,
                                type: questionType,
                                resposta: resposta.length > 0 ? resposta : null
                            });
                        }
                    }
                }
            }
        });

        if (foundKeyword) return false;
    });

    return questions;
}
<!-- eslint-disable vue/no-mutating-props -->
<template>
	<div>
	</div>
</template>

<script>
import theme from '@/theme';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Logoff',
	mounted () {
		this.logoff();
	},
	methods: {
		logoff () {
			this.$vuetify.theme = theme;
			sessionStorage.clear();
			this.$router.push({path: '/login' });
		}
	}
};
</script>

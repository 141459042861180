<template>
  <default-list-layout>
    <template v-slot:title>
      <h2>Usuários</h2>
    </template>

    <template v-slot:actions>
      <v-container>
        <v-row row justify-space-between>
          <v-col xs="7" md="4">
            <v-text-field
              v-model="filter.term"
              ref="search"
              label="Faça a sua busca"
              clear-icon="close"
              clearable
              append-icon="search"
              @click:append="getData"
              @keyup="getData"
              @keyup.enter="getData"
            />
          </v-col>

          <v-col xs="3" md="2" class="text-xs-left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn large color="normal" v-on="on" @click="toggleFilters">
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </template>
              <span>Clique para selecionar os filtros</span>
            </v-tooltip>
          </v-col>

          <v-col class="text-xs-right">
            <v-btn color="primary" large to="users/register">+ Incluir</v-btn>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row row wrap v-show="expandFilters">
            <v-col md="12">
              <v-card class="elevation-1 pa-3 mb-4">
                <v-row row>
                  <v-col xs="12">
                    <v-label>Selecione os campos para fazer a busca</v-label>
                  </v-col>
                </v-row>

                <v-row row wrap>
                  <v-col xs="12" md="3" pa-3>
                    <v-select
                      chips
                      deletable-chips
                      clearable
                      v-model="filter.active"
                      :items="[
                        { value: '1', text: 'Sim' },
                        { value: '0', text: 'Não' },
                      ]"
                      label="Ativo"
                      @change="getData"
                    />
                  </v-col>
                  <v-col xs="12" md="4" pa-3>
                    <v-select
                      chips
                      deletable-chips
                      v-model="filter.userGroupId"
                      :items="userGroups"
                      item-value="id"
                      item-text="name"
                      label="Grupo de usuário"
                      clearable
                      @change="getData"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </template>

    <template v-slot:content>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :loading="table.loading"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:[`item.app_login`]="{ item }">
          {{ $functions.maskText(item.app_login, "###.###.###-##") }}
        </template>
        <template v-slot:[`item.group`]="{ item }">
          {{ item.group ? item.group.name : "" }}
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <v-chip v-if="item.active == 1" color="green" text-color="white">
            Ativo</v-chip
          >
          <v-chip v-if="item.active == 0" color="#5e636b" text-color="white"
            >Inativo</v-chip
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn :to="`/users/edit/${item.id}`" icon v-on="on">
                <v-icon color="#707070">edit</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="remove(item.id)" icon v-on="on">
                <v-icon color="#707070">clear</v-icon>
              </v-btn>
            </template>
            <span>Excluir</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>

    <template v-slot:pagination>
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </template>
  </default-list-layout>
</template>

<script>
import DefaultListLayout from "@/shared/components/layout/DefaultListLayout.vue";
export default {
  components: { DefaultListLayout },
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Users",
  data() {
    return {
      expandFilters: false,
      filter: {
        term: "",
        active: "",
        userGroupId: null,
      },
      userGroups: [],
      table: {
        headers: [
          { text: "Status", value: "active", align: "left" },
          { text: "Nome", value: "name" },
          { text: "CPF", value: "app_login" },
          { text: "Login", value: "login" },
          { text: "E-mail", value: "email" },
          { text: "Grupo de usuário", value: "group" },
          {
            text: "",
            value: "actions",
            align: "right",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  async mounted() {
    await this.getUserGroups();
    await this.getData();
  },

  methods: {
    async getUserGroups() {
      const res = await this.minamelServices
        .UserGroupService()
        .getUserGroup()
        .search({ active: 1 });

      this.userGroups = res.data;
    },
    async getData() {
      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          active: this.filter.active ? this.filter.active : "",
          term: this.filter.term ? this.filter.term : "",
          userGroupId: this.filter.userGroupId ? this.filter.userGroupId : "",
        };

        const res = await this.minamelServices.UserServiceV2().getAll(params);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.UserServiceV2().delete(id);

      if (res) {
        await this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>

import numeral from 'numeral'
import 'numeral/locales'

numeral.locale('pt-br')

export default {
	install (Vue) {
		Vue.prototype.formatNumber = (value, mask) => numeral(value).format(mask)
		Vue.prototype.unformatNumber = (value) => numeral(value).value()
	}
}

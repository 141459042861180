import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {RoleService}
 */
export default class RoleService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'roles/';
    
    getRole() {
        this.path = "roles/get";
        return this;
    }
    getPermissions() {
        this.path = "roles/get-permissions";
        return this;
    }
    getUserGroupRoles() {
        this.path = "roles/get-roles";
        return this;
    }

    createRole(id = null) {
        if(id){
            this.path = "roles/update";
        }else{
            this.path = "roles/create";
        }
        return this;
    }
    updateRole() {
        this.path = "roles/update";
        return this;
    }
    deleteRole() {
        this.path = "roles/delete";
        return this;
    }


    // access() {
    //     this.path = "systems/access";
    //     return this;
    // }

    // group() {
    //     this.path = "systems/group";
    //     return this;
    // }

    // permission() {
    //     this.path = "systems/permission";
    //     return this;
    // }
}
/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados aos projetos de apiários
 * @typedef {ProjectService}
 */
export default class ProjectService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'projects/';

    getProject () {
    	this.path = "project/get";
    	return this;
    }

    createProject (id = null) {
    	if (id) {
    		this.path = "project/update";
    	} else {
    		this.path = "project/create";
    	}
    	return this;
    }
    updateProject () {
    	this.path = "project/update";
    	return this;
    }
    deleteProject () {
    	this.path = "project/delete";
    	return this;
    }
    updateProjectUsers () {
    	this.path = "project/update-project-users";
    	return this;
    }
    getProjectUsers () {
    	this.path = "project/get-project-users";
    	return this;
    }

    getAll (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    getProjectFiles (id, files, params) {
    	return super.getFile(`/${id}/files/${files}`, params);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    getDraft () {
    	return super.get('/actions/draft');
    }
}

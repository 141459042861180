import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {PdfService}
 */
export default class PdfService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'pdf/';
    
    extractPdf() {
        this.path = "pdf/extract";
        return this;
    }

    receiptPdf() {
        this.path = "pdf/receipt";
        return this;
    }

}
<template>
	<div>
		<v-app id="inspire">
		<v-container fluid class="login-view pa-0">
			<v-row row>
				<v-col class="text-xs-center" xs="12" md="8">
					<div class="background-minamel"/>
				</v-col>
				<v-col class="text-xs-center pa-4 form-login" xs="12" md="4">
					<v-form ref="form" class="pa-1 d-flex flex-column" style="margin-top: 20vh" >
						<img width="450" class="mx-auto" src="../assets/login_logo.png" style="margin-bottom: 8vh" />

						<v-text-field
							ref="userName"
							label="Usuário"
							v-model="user.login"
							:rules="userRules"
							required
							@keydown.enter="submit"
						></v-text-field>

						<v-text-field
							ref="userPassword"
							label="Senha"
							v-model="user.password"
							:append-icon="!e1 ? 'visibility' : 'visibility_off'"
							@click:append="() => (e1 = !e1)"
							:type="e1 ? 'password' : 'text'"
							:rules="passwordRules"
							counter
							required
							@keydown.enter="submit"
						></v-text-field>

						<v-spacer></v-spacer>

						<v-btn
							ref="btnLogin"
							class="mx-auto"
							:loading="loading"
							:disabled="loading"
							@click="submit"
							color="primary"
							:class="{ 'darken-4 white--text' : valid, disabled: !valid }"
						>
							Entrar
						</v-btn>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
		</v-app>

		<SnackBar ref="SnackBar"></SnackBar>
	</div>
</template>

<script>
import jwt from 'vue-jwt-decode';
import colors from 'vuetify/es5/util/colors';
// import Login from '@/components/view/Login'
import SnackBar from './misc/SnackBar';

export default {
	name: 'LoginView',
	data () {
		return {
			valid: false,
			e1: true,
			user: {
				login: "",
				password: ""
			},
			loading: false,
			passwordRules: [v => !!v || "Obrigatório informar a senha"],
			userRules: [v => !!v || "Obrigatório informar o usuário"],
			snackbar: {
				message: "",
				visible: false,
				color: "success",
				timeout: 6000,
				x: null,
				y: "bottom",
				multiLine: false
			}
		};
	},
	components: {
		'SnackBar': SnackBar
	},
	mounted () {
		this.$root.$SnackBar = this.$refs.SnackBar;
		this.loginFocus();
	},
	methods: {
		getColorName (colorHex) {
			for (const color in colors) {
				// eslint-disable-next-line no-prototype-builtins
				if (colors.hasOwnProperty(color)) {
					const element = colors[color];
					if (element != '' && element.base == colorHex) {
						return color;
					}
				}
			}
		},
		async setsessionStorage (label, value) {
			if (value) {
				await sessionStorage.setItem(label, value);
				return sessionStorage.getItem('systemId');
			}
		},
		async submit () {
			let response;

			if (!this.$refs.form.validate()) return;

			this.loading = true;

			try {
				response = await this.minamelServices.LoginService().loginUser().send(this.user);
			} catch (error) {
				console.error(error);
				this.loading = false;
				this.snackbar.color = "error";
				this.snackbar.message = 'Erro ao efetuar o login!';
				this.$root.$SnackBar.show(this.snackbar);
				return;
			}

			if (response.error) {
				this.loading = false;
				this.snackbar.color = "error";
				this.snackbar.message = response.message;
				this.$root.$SnackBar.show(this.snackbar);
				return;
			}

			let token = jwt.decode(response.data);
			await this.setsessionStorage('token', response.data);
			let user = token.userData;

			if (!token.systemData) {
				this.loading = false;
				this.snackbar.color = "error";
				this.snackbar.message = 'Usuário sem acesso.';
				this.$root.$SnackBar.show(this.snackbar);
				return;
			}

			const systemDataArray = Object.keys(token.systemData).map((key) => token.systemData[key]);

			let systemId;
			if (systemDataArray.length == 1) {
				systemId = await this.setsessionStorage('systemId', systemDataArray[0].id);
			} else {
				systemId = await this.setsessionStorage('systemId', user.intranetId);
			}

			if (token.systemData[systemId].color) {
				this.$vuetify.theme.primary = colors[this.getColorName(token.systemData[systemId].color)].darken4;
				this.$vuetify.theme.secondary = colors[this.getColorName(token.systemData[systemId].color)];
			}

			this.loading = false;

			this.$router.push({ path: token.systemData[systemId].MENUS[0].route.replace('/', '') });
		},
		loginFocus () {
			setTimeout(() => {
				this.$refs.userName.focus();
			}, 2000);
		}
	}
};
</script>

<style>

html {
	overflow: auto;
}

.background-minamel {
	background-image: url(../assets/photo-1577095870693-360d002ad341.jpg);
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100%;
	opacity: 1;
	overflow: hidden !important;
	height: 100vh;
}

.login-view {
	overflow: hidden !important;
}

label.v-label.theme--light {
	color: #b9041c !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	transition-delay: 3600s;
}
</style>

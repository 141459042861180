<template>
  <v-container grid-list-md fluid>
    <v-row row wrap>
      <v-col
        v-for="system in this.systems"
        :key="system.id"
        style="padding: 2px 10px 10px 0px"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <v-card
          @click="redirectSystem(system.url, system.id, system.MENUS)"
          class="mx-auto cursorPointer"
          width="240"
        >
          <v-img
            :style="{ backgroundColor: system.color }"
            class="white--text align-end"
            :src="system.base64"
          >
          </v-img>

          <v-card-title style="font-weight: 600" class="pb-0">{{
            $functions.decode_utf8(system.name)
          }}</v-card-title>

          <v-card-text class="text--primary">
            <div>{{ $functions.decode_utf8(system.desc) }}</div>
          </v-card-text>

          <!-- <v-card-actions>
						<v-btn @click="redirectSystem(system.url, system.id, system.MENUS)" :color="system.color" text>
							Acessar
						</v-btn>
					</v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row row wrap></v-row>

    <v-row row wrap>
      <v-col xs8></v-col>
      <v-col xs4></v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwt from "vue-jwt-decode";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
  async mounted() {
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.systemData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).systemData
      : null;
    this.systemId = (await sessionStorage.systemId)
      ? sessionStorage.systemId
      : null;
    this.listSystems();
  },
  data() {
    return {
      userData: {},
      systemId: {},
      systemData: {},
      systems: [],
    };
  },
  methods: {
    async redirectSystem(url, id, menus) {
      if (!id) return;

      sessionStorage.setItem("systemId", id);
      sessionStorage.setItem("token", sessionStorage.getItem("token"));

      this.$router.push({ path: menus[0].route });

      this.$root.$TransitionSystem.setSystem(id);

      setTimeout(() => {
        window.location.reload();
      }, 350);
    },
    async listSystems() {
      delete this.systemData[this.userData.intranetId];

      const systemDataArray = Object.keys(this.systemData).map(
        (key) => this.systemData[key]
      );

      for (let index = 0; index < systemDataArray.length; index++) {
        let system = systemDataArray[index];

        let response = await this.minamelServices
          .UploadService()
          .getUpload()
          .search({ system_id: system.id });

        try {
          if (response && response.data.length > 0) {
            system.base64 = response.data;
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.systems.push(system);
        }
      }
    },
  },
};
</script>

<template>
  <v-col lg="1" xl="1" md="1" sm="2" xs="2" class="mt-1" v-if="activeCard">
    <v-card class="elevation-1 grey lighten-4" tile style="width: 50px">
      <v-list-item class="pl-2 py-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="moveItemUp"
              :disabled="isInTheFirstIndex()"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </template>

          <span> Mover Item para cima </span>
        </v-tooltip>
      </v-list-item>

      <v-list-item class="pl-2 py-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="addNewItemAboveThis" icon>
              <v-icon>add_circle_outline</v-icon>
            </v-btn>
          </template>

          <span>Adicionar</span>
        </v-tooltip>
      </v-list-item>

      <v-list-item class="pl-2 py-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="saveForm" icon>
              <v-icon>save</v-icon>
            </v-btn>
          </template>

          <span>Salvar</span>
        </v-tooltip>
      </v-list-item>

      <v-list-item class="pl-2 py-1" v-if="item.type === 'TEXTBLOCK'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="toggleItemVisibilityOnMobile">
              <v-icon>{{
                item.not_show_on_mobile ? "visibility" : "visibility_off"
              }}</v-icon>
            </v-btn>
          </template>

          <span>{{
            item.not_show_on_mobile
              ? "Aparecer do aplicativo"
              : "Remover do aplicativo"
          }}</span>
        </v-tooltip>
      </v-list-item>

      <v-list-item class="pl-2 py-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="moveItemDown"
              :disabled="isInTheFinalIndex()"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <span> Mover Item para baixo </span>
        </v-tooltip>
      </v-list-item>
    </v-card>
  </v-col>
</template>

<script>
import Helpers from "@/helpers/helpers";
import FormStore from "../../store/FormStore";
export default {
  name: "ItemRegisterActions",
  props: {
    activeCard: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      store: new FormStore(),
    };
  },
  methods: {
    addNewItemAboveThis() {
      this.$emit("addNewItemAboveThis", this.index);
    },
    saveForm() {
      this.$emit("saveForm");
    },
    toggleItemVisibilityOnMobile() {
      this.$emit("toggleItemVisibilityOnMobile");
    },
    async moveItemDown() {
      await Helpers.sleep(150);

      this.store.reorderItem(this.item, this.index, this.index + 1);
    },
    async moveItemUp() {
      await Helpers.sleep(150);

      this.store.reorderItem(this.item, this.index, this.index - 1);
    },
    isInTheFinalIndex() {
      return this.store.state.items.length - 1 == this.index;
    },
    isInTheFirstIndex() {
      return this.index == 0;
    },
  },
};
</script>

<style></style>

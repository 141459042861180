module.exports = {
	texts: {
		errors: {
			connection: 'Ocorreu um erro ao conectar com a API, favor verificar com o TI!'
		},
		table: {
			noResultText: 'Nenhum item foi encontrado',
			rowPerPageText: 'Itens por página',
			noDataText: 'Nenhum registro',
			loadingText: 'Aguarde, buscado dados...'
		},
		dialog: {
			loading: 'Aguarde, os dados estão sendo carregados...',
			deleteSales: 'Aguarde, os dados estão sendo excluidos...',
			saveSales: 'Aguarde, os dados estão sendo salvos!'
		},
		register: {
			required: 'Campos com preenchimento obrigatório'
		}
	},
	images: {
	},
	arrays: {
		table: {
			itensToView: [10, 20, 50]
		}
	},
	integer: {
		table: {
			numberOfRowPerPage: 10,
			totalPagesVisible: 10
		},
		search: {
			numberOfCharacterToSearch: 3
		}
	},
	colors: {
		error: '#DD6B55',
		success: '#4caf50',
		warning: '',
		cancel: '#cccccc'
	},
	months: [
		{ name: "Janeiro", value: "01" },
		{ name: "Fevereiro", value: "02" },
		{ name: "Março", value: "03" },
		{ name: "Abril", value: "04" },
		{ name: "Maio", value: "05" },
		{ name: "Junho", value: "06" },
		{ name: "Julho", value: "07" },
		{ name: "Agosto", value: "08" },
		{ name: "Setembro", value: "09" },
		{ name: "Outubro", value: "10" },
		{ name: "Novembro", value: "11" },
		{ name: "Dezembro", value: "12" }
	],
	standardTypesHoneyRecord: [
		{
			value: "FEED_RECORD_REASON",
			name: "Motivo de alimentação artificial"
		},
		{
			value: "FEED_RECORD_FOOD",
			name: "Tipo de comida"
		},
		{
			value: "HONEY_APPOINTMENT_DESTINATION",
			name: "Destino do mel"
		},
		{
			value: "CLEANING_RECORD_PRODUCT",
			name: "Produto de limpeza"
		},
		{
			value: "BEE_SWARM_CAPTURE_TYPE",
			name: "Tipo de captura de abelha"
		},
		{
			value: "BEESWAX_APPOINTMENT_EXCHANGE_CENTER",
			name: "Centro de troca de cera"
		}
	],
	"polygonTransparentColor": false,
	defaultPosition: {
		lat: -28.6964608,
		lng: -49.3099899
	},
	formVariables: [
		{
			value: "NOME_INSPETOR",
			name: "Inspetor de campo"
		},
		{
			value: "CODIGO_APICULTOR",
			name: "Código do produtor"
		},
		{
			value: "NOME_APICULTOR",
			name: "Nome do produtor"
		},
		{
			value: "CNPJ_APICULTOR",
			name: "CPF/CNPJ do produtor"
		},
		{
			value: "RG_APICULTOR",
			name: "RG do produtor"
		},
		{
			value: "CEP_APICULTOR",
			name: "Cep do produtor"
		},
		{
			value: "ESTADO_APICULTOR",
			name: "Estado do produtor"
		},
		{
			value: "BAIRRO_APICULTOR",
			name: "Bairro do produtor"
		},
		{
			value: "CIDADE_APICULTOR",
			name: "Cidade do produtor"
		},
		{
			value: "ENDERECO_APICULTOR",
			name: "Endereço do produtor"
		},
		{
			value: "NUMERO_ENDERECO_APICULTOR",
			name: "Nº do endereço do produtor"
		},
		{
			value: "COMPLEMENTO_ENDERECO_APICULTOR",
			name: "Complemento do endereço do produtor"
		},
		{
			value: "TELEFONE_APICULTOR",
			name: "Telefone do produtor"
		},
		{
			value: "EMAIL_APICULTOR",
			name: "E-mail do produtor"
		},
		{
			value: "REVISAO_PROJETO",
			name: "Nº da revisão do projeto"
		},
		{
			value: "OESA",
			name: "Número OESA"
		},
	]
};

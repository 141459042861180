var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"xs":"12","lg12":"","md":"12"}},[_c('v-subheader',[_c('h2',[_vm._v("Círculos de mapa")])])],1)],1),_c('v-row',{attrs:{"row":"","justify-space-between":""}},[_c('v-col',{attrs:{"xs":"7","md":"5"}},[_c('v-text-field',{ref:"search",attrs:{"label":"Faça a sua busca","clear-icon":"close","clearable":"","append-icon":"search"},on:{"click:append":_vm.getData,"keyup":[_vm.getData,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getData.apply(null, arguments)}]},model:{value:(_vm.filter.term),callback:function ($$v) {_vm.$set(_vm.filter, "term", $$v)},expression:"filter.term"}})],1),_c('v-col',{staticClass:"text-xs-left",attrs:{"xs":"3","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"large":"","color":"normal"},on:{"click":_vm.toggleFilters}},on),[_c('v-icon',[_vm._v("filter_list")])],1)]}}])},[_c('span',[_vm._v("Clique para selecionar os filtros")])])],1),_c('v-col'),_c('v-col',{staticClass:"text-xs-right d-flex"},[_c('v-btn',{staticClass:"flex-one ml-auto",attrs:{"color":"primary","large":"","to":"circle-maps/register"}},[_vm._v(" + Incluir ")])],1)],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandFilters),expression:"expandFilters"}],attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"elevation-1 pa-3 mb-4"},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"xs":"12"}},[_c('v-label',[_vm._v("Selecione os campos para fazer a busca")])],1)],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"xs":"12","md":"3","pa-3":""}},[_c('v-select',{attrs:{"chips":"","deletable-chips":"","clearable":"","items":[
								{ value: '1', text: 'Sim' },
								{ value: '0', text: 'Não' },
							],"label":"Ativo"},on:{"change":_vm.getData},model:{value:(_vm.filter.active),callback:function ($$v) {_vm.$set(_vm.filter, "active", $$v)},expression:"filter.active"}})],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"loading":_vm.table.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [(item.active == 1)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v("Ativo")]):_c('v-chip',{attrs:{"color":"#5e636b","text-color":"white"}},[_vm._v(" Inativo ")])]}},{key:`item.color`,fn:function({ item }){return [_c('div',{style:({
				backgroundColor: item.color,
				width: '40px',
				height: '40px',
				borderRadius: '10px',
			})})]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"to":`/circle-maps/edit/${item.id}`,"icon":""}},on),[_c('v-icon',{attrs:{"color":"#707070"}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},on),[_c('v-icon',{attrs:{"color":"#707070"}},[_vm._v("clear")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)}),_c('div',[_c('v-card',{staticClass:"pa-3"},[_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{staticClass:"text-xs-center",attrs:{"xs":"12","md":"12"}},[_c('v-pagination',{attrs:{"length":_vm.table.pagination.pages,"disabled":_vm.table.disabled,"total-visible":_vm.table.pagination.totalVisible,"prev-icon":"arrow_left","next-icon":"arrow_right","primary":""},model:{value:(_vm.table.pagination.page),callback:function ($$v) {_vm.$set(_vm.table.pagination, "page", $$v)},expression:"table.pagination.page"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
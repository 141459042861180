<template>
	<div>
		<v-dialog v-model="visible" persistent :width="width">
			<v-card color="primary" dark>
				<v-card-text>
					{{ message }}
					<v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'LoadingBar',
	data () {
		return {
			visible: false,
			message: '',
			width: 450
		}
	},
	methods: {
		show (message) {
			this.visible = true
			this.message = message
		},
		hide () {
			this.visible = false
		}
	}
}
</script>

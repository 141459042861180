export default class Helpers {
	/**
	 * registerDebounce
	 * funcao de debounce do javascript vanilla
	 *
	 * @param {closure} func
	 * @param {Number} wait
	 * @return {function}
	 */
	static registerDebounce(func, wait = 100) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);

			timeout = setTimeout(() => {
				func.apply(this, args);
			}, wait);
		};
	}

	static sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}

<template>
  <v-row>
    <image-dialog
      class="final_signature_item_image_dialog mb-6 mx-3"
      ref="image_dialog"
      v-model="model"
      folder="final_signature"
      :responsivity="responsivity"
      skip-crop
    />
  </v-row>
</template>

<script>
import ModelManagementMixin from "@/helpers/ModelManagementMixin";
import ImageDialog from "@/shared/components/ImageComponent/ImageDialog.vue";

export default {
  name: "FinalSignatureItem",
  components: {
    ImageDialog,
  },
  mixins: [ModelManagementMixin],
  data() {
    return {
      responsivity: {
        maxWidth: "360px",
        maxHeight: "360px",
        aspectRatio: 4 / 3,
      },
    };
  },
  watch: {},
};
</script>

<style>
.final_signature_item_image_dialog {
  max-width: 400px;
}
</style>

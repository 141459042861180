/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {SystemService}
 */
export default class SystemService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'systems/';

    getSystem () {
    	this.path = "systems/get";
    	return this;
    }

    createSystem (id = null) {
    	if (id) {
    		this.path = "systems/update";
    	} else {
    		this.path = "systems/create";
    	}
    	return this;
    }
    updateSystem () {
    	this.path = "systems/update";
    	return this;
    }
    deleteSystem () {
    	this.path = "systems/delete";
    	return this;
    }

    updateSystemsRoles () {
    	this.path = "systems/update-systems-roles";
    	return this;
    }

    getAll (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    // access() {
    //     this.path = "systems/access";
    //     return this;
    // }

    // group() {
    //     this.path = "systems/group";
    //     return this;
    // }

    // permission() {
    //     this.path = "systems/permission";
    //     return this;
    // }
}

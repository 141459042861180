<!-- eslint-disable vue/no-mutating-props -->
<template>
	<v-container fluid>
		<v-dialog v-model="visible" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
			<v-card>
				<v-toolbar dark color="primary">
					<v-btn icon dark @click="closeDialog(true)" v-shortkey="['esc']" @shortkey="closeDialog(true)">
						<v-icon>close</v-icon>
					</v-btn>
					<v-toolbar-title>Atalhos</v-toolbar-title>
				</v-toolbar>
				<v-container fluid>
					<v-row row>
						<v-col md="4">
							<v-card class="text--xs-center ma-2">
								<v-card-title class="white--text info">
									<v-icon>scatter_plot</v-icon>
									<span class="title">Tela de cadastro de PV</span>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row row v-for="obj in shortcuts.salesKeys" :key="`sales-${obj.id}`">
										<v-col  md="2" text-xs-left class="font-weight-bold">{{ obj.key }}</v-col>
										<v-col md10 text-xs-right class="caption">{{ obj.text }}</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>

						<v-col md="4">
							<v-card class="text--xs-center ma-2">
								<v-card-title class="white--text info">
									<v-icon>scatter_plot</v-icon>
									<span class="title">Tela de produtos</span>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row row v-for="obj in shortcuts.productsKeys" :key="`product-${obj.id}`">
										<v-col  md="2" text-xs-left class="font-weight-bold">{{ obj.key }}</v-col>
										<v-col md10 text-xs-right class="caption">{{ obj.text }}</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>

						<v-col md="4">
							<v-card class="text--xs-center ma-2">
								<v-card-title class="white--text info">
									<v-icon>scatter_plot</v-icon>
									<span class="title">Tela de adição do produto</span>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row row v-for="obj in shortcuts.addProductKeys" :key="`addprd-${obj.id}`">
										<v-col  md="2" text-xs-left class="font-weight-bold">{{ obj.key }}</v-col>
										<v-col md10 text-xs-right class="caption">{{ obj.text }}</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
export default {
	name: 'ShortcutsHelp',
	props: {
		visible: { type: Boolean, default: false }
	},
	data () {
		return {
			mutableVisible: false,
			shortcuts: {
				salesKeys: [
					{id: 1, key: 'ESC', text: 'Sair da tela'},
					{id: 2, key: 'CTRL + F', text: 'Busca os dados'},
					{id: 3, key: 'F2', text: 'Salvar o pedido como rascunho'},
					{id: 4, key: 'F3', text: 'Salvar o pedido e finalizar a edição'},
					{id: 5, key: 'F4', text: 'Adicionar um novo produto'}
				],
				productsKeys: [
					{id: 1, key: 'ESC', text: 'Sair da tela'},
					{id: 2, key: 'CTRL + F', text: 'Busca os dados'}
				],
				addProductKeys: [
					{id: 1, key: 'ESC', text: 'Sair da tela'},
					{id: 2, key: 'F2', text: 'Adicionar o produto e continuar adicionando'},
					{id: 3, key: 'F3', text: 'Adicionar o produto e voltar ao pedido'}
				]
			}
		};
	},
	methods: {
		closeDialog (value) {
			this.mutableVisible = !value;
			this.$emit('setDialogVisible', this.mutableVisible);
		}
	}
};
</script>

/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {ApiaryService}
 */
export default class ApiaryService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'apiaries/';

    getApiary () {
    	this.path = "apiary/get";
    	return this;
    }

    createApiary (id = null) {
    	if (id) {
    		this.path = "apiary/update";
    	} else {
    		this.path = "apiary/create";
    	}
    	return this;
    }
    updateApiary () {
    	this.path = "apiary/update";
    	return this;
    }
    deleteApiary () {
    	this.path = "apiary/delete";
    	return this;
    }

    updateApiaryFlowery () {
    	this.path = "apiary/update-apiary-flowery";
    	return this;
    }

    getAll (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    getListByProducer(params) {
    	return super.get(`producer?${this.queryString(params)}`);
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    importToProject (id, data) {
    	return super.post(`/${id}/import-project`, data);
    }

    getHiveCount (params) {
    	return super.get(`hives/total/?${this.queryString(params)}`);
    }
}

<template>
	<v-card elevation="0" class="ma-4">
		<v-row row justify-space-between>
			<v-col xs="7" md="4">
				<v-text-field v-model="filter.term" ref="search" label="Faça a sua busca" clear-icon="close" clearable
					append-icon="search" @click:append="getData" @keyup="getData" @keyup.enter="getData" />
			</v-col>

			<v-col class="text-xs-right">
				<v-btn color="primary" large to="notifications/register">+ Incluir</v-btn>
			</v-col>
		</v-row>

		<v-data-table :headers="table.headers" :items="table.items" :loading="table.loading" class="elevation-1"
			hide-default-footer>
			<template v-slot:item="{ item }">
				<tr>
					<td class="text-xs-left">{{ item.name }}</td>
					<td class="text-xs-left">{{ $functions.formatDate(item.date) }}</td>
					<td class="text-xs-right" style="min-width: 185px">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn :to="`notifications/edit/${item.id}`" icon v-on="on">
									<v-icon color="#707070">edit</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn @click="remove(item.id)" icon v-on="on">
									<v-icon color="#707070">clear</v-icon>
								</v-btn>
							</template>
							<span>Excluir</span>
						</v-tooltip>
					</td>
				</tr>
			</template>
		</v-data-table>
		<div class="text-xs-center pa-4">
			<v-pagination v-model="table.pagination.page" :length="table.pagination.pages"
				v-bind:disabled="table.disabled" :total-visible="table.pagination.totalVisible" prev-icon="arrow_left"
				next-icon="arrow_right" primary />
		</div>
	</v-card>
</template>
<script>
export default {
	name: "ProjectNotifications",
	props: {
		routeName: { type: String, default: "/field-notes" }

	},
	data() {
		return {
			projectId: null,
			filter: {
				term: ""
			},
			table: {
				headers: [
					{ text: "Descrição", value: "name" },
					{ text: "Data", value: "date" },
					{
						text: "",
						value: "actions",
						align: "right",
						sortable: false
					}
				],
				items: [],
				loading: false,
				pagination: {
					page: 1,
					perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
					totalVisible: this.CONSTANTS.integer.table
						.totalPagesVisible
				}
			},
			snackbar: {
				message: "",
				visible: false,
				color: "success",
				timeout: 6000,
				x: "right",
				y: "top",
				multiLine: false
			}
		};
	},
	async mounted() {
		this.projectId = this.$route.params.id;

		await this.getData();
	},

	methods: {
		async getData() {
			try {
				this.table.loading = true;

				const params = {
					page: this.table.pagination.page,
					perPage: this.table.pagination.perPage,
					term: this.filter.term ? this.filter.term : "",
					projectId: this.projectId
				};

				const res = await this.minamelServices
					.ProjectNotificationService()
					.getAll(params);

				this.table.items = res.data;
				this.table.pagination.pages = res.meta.last_page;
				this.table.loading = false;
			} catch (err) {
				this.table.loading = false;
			}
		},

		async remove(id) {
			const question = "Deseja realmente excluir este item?";
			const type = "question";

			const confirm = await this.alertConfirm(question, type);

			if (!confirm) {
				return;
			}

			const res = await this.minamelServices
				.ProjectNotificationService()
				.delete(id);

			if (res) {
				this.getData();
			}
		},

		async alertConfirm(ask, type) {
			let response = await this.$swal.fire({
				title: `${ask}`,
				type: type,
				showCancelButton: true,
				confirmButtonColor: "#4caf50",
				cancelButtonColor: "#ff1744",
				confirmButtonText: "Confirmar",
				cancelButtonText: "Cancelar",
				allowOutsideClick: false
			});

			if (response.value) {
				return true;
			}

			return false;
		}

	},
	watch: {
		"table.pagination.page": function () {
			this.getData();
		}
	}
};
</script>

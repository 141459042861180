import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados à notificações dos projetos de apiários;
 * @typedef {ProjectDateService}
 */
export default class ProjectDateService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'project-date/';
    
    getProjectDate() {
        this.path = "project-date/get";
        return this;
    }

    createProjectDate(id = null) {
        if(id){
            this.path = "project-date/update";
        }else{
            this.path = "project-date/create";
        }
        return this;
    }
    updateProjectDate() {
        this.path = "project-date/update";
        return this;
    }
    deleteProjectDate() {
        this.path = "project-date/delete";
        return this;
    }
}
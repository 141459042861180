import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {MovementTypeService}
 */
export default class MovementTypeService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'movement-types/';
    
    getMovementType() {
        this.path = "movement-types/get";
        return this;
    }
    checkOpeningBalanceMovementType() {
        this.path = "movement-types/check-opening-balance";
        return this;
    }

    createMovementType(id = null) {
        if(id){
            this.path = "movement-types/update";
        }else{
            this.path = "movement-types/create";
        }
        return this;
    }
    updateMovementType() {
        this.path = "movement-types/update";
        return this;
    }
    deleteMovementType() {
        this.path = "movement-types/delete";
        return this;
    }

}
<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row row>
          <v-col xs="12" >
            <!-- <v-subheader>
              <h2>Saldos</h2>
            </v-subheader>-->
            <h2 class="lighten-4">
              Saldo dos tambores que estão fora
              <b
                style="color: #b9041c"
              >{{ supplierFilter.filters.product_type == 'AV' ? '(Tipo AV)' : (supplierFilter.filters.product_type == 'BV' ? '(Tipo BV)' : '(Todos os tipos)')}}</b>
            </h2>

            <v-expansion-panel>
              <v-expansion-panel-content>
                <div style="font-size: 18px" slot="header">
                  <v-icon color="gray lighten-2">search</v-icon>
                  <b style="color: gray">Filtros</b>
                </div>
                <v-card>
                  <v-card-text>
                    <v-row row wrap>
                      <v-col md="12">
                        <v-card class="elevation-0">
                          <v-row row>
                            <v-col xs="12">
                              <v-row row>
                                <v-col  xs="6" class="text-xs-right"></v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row row>
                            <v-col style="margin-right: 20px"  sm="6">
                              <v-autocomplete
                                @change="onSupplierProductTypeChange"
                                clearable
                                style="margin-top: 15px"
                                v-model="supplierFilter.filters.supplier_id"
                                :items="supplierSuppliers"
                                :loading="isLoading"
                                :search-input.sync="supplierTerm"
                                hide-no-data
                                hide-selected
                                item-text="name"
                                item-value="id"
                                label="Fornecedor"
                                placeholder="Pesquisar..."
                                prepend-icon="mdi-database-search"
                              ></v-autocomplete>
                            </v-col>
                            <v-col style="margin-top: 11px" sm="2">
                              <v-select
                                clearable
                                @change="onSupplierProductTypeChange"
                                v-model="supplierFilter.filters.product_type"
                                label="Tipo de produto"
                                :items="[{id: 'AV', text: 'AV'}, { id: 'BV', text: 'BV'}]"
                                item-value="id"
                                item-text="text"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="clearSupplierFilters"
                              style="color: white"
                              color="red"
                            >Limpar</v-btn>
                            <v-btn
                              @click="getSuppliersBalance"
                              style="color: white"
                              color="green"
                            >Buscar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-data-table
              :headers="headers"
              :items="supplierTable.items"
              :loading="supplierTable.loading"
              :pagination.sync="supplierTable.pagination"
              :total-items="supplierTable.totalItems"
              :no-results-text="supplierTable.noResultText"
              :no-data-text="supplierTable.noDataText"
              hide-default-footer
              item-key="B1_COD"
              class="elevation-1"
              @click:row="goToMovements"
            >
              <template v-slot:[`item.balance`]="{ item }">
                <v-chip
                  color="blue darken-1"
                  text-color="white"
                >
                {{ formatAmount(item.balance) }}
                </v-chip>
              </template>
              <template slot="no-data">
                <div class="text-xs-center">{{ supplierTable.noDataText }}</div>
              </template>
            </v-data-table>

            <div>
              <v-card class="pa-3">
                <v-row row>
                  <v-col md="3">
                    <v-row>
                      <v-col  sm="6" pa-2>
                        <span class="actionPaginationText">{{ supplierTable.rowPerPageText }}</span>
                      </v-col>
                      <v-col sm="2">
                        <v-select
                          v-model="supplierTable.rowsPerPage"
                          :items="supplierTable.rowPerPageItems"
                          label
                          :hide-details="true"
                          class="actionPagination"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col md="6" class="text-xs-center">
                    <v-pagination
                      v-model="supplierTable.currentPage"
                      :length="supplierTable.totalPages"
                      v-bind:disabled="supplierTable.disabled"
                      @input="supplierPageChange"
                      :total-visible="supplierTable.totalPagesVisible"
                      prev-icon="arrow_left"
                      next-icon="arrow_right"
                      primary
                    />
                  </v-col>

                  <v-col md="3">&nbsp;</v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row row>
          <v-col xs="12" >
            <h2 class="lighten-4">
              Saldo dos tambores que a Minamel deve
              <b
                style="color: #b9041c"
              >{{ minamelFilter.filters.product_type == 'AV' ? '(Tipo AV)' : (minamelFilter.filters.product_type == 'BV' ? '(Tipo BV)' : '(Todos os tipos)')}}</b>
            </h2>

            <v-expansion-panel>
              <v-expansion-panel-content>
                <div style="font-size: 18px" slot="header">
                  <v-icon color="gray lighten-2">search</v-icon>
                  <b style="color: gray">Filtros</b>
                </div>
                <v-card>
                  <v-card-text>
                    <v-row row wrap>
                      <v-col md="12">
                        <v-card class="elevation-0">
                          <v-row row>
                            <v-col xs="12">
                              <v-row row>
                                <v-col  xs="6" class="text-xs-right"></v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row row>
                            <v-col style="margin-right: 20px"  sm="6">
                              <v-autocomplete
                                @change="onMinamelProductTypeChange"
                                clearable
                                style="margin-top: 15px"
                                v-model="minamelFilter.filters.supplier_id"
                                :items="minamelSuppliers"
                                :loading="isLoading"
                                :search-input.sync="minamelTerm"
                                hide-no-data
                                hide-selected
                                :item-text="getText"
                                item-value="id"
                                return-object
                                label="Fornecedor"
                                placeholder="Pesquisar..."
                                prepend-icon="mdi-database-search"
                              ></v-autocomplete>
                            </v-col>
                            <v-col style="margin-top: 11px" sm="2">
                              <v-select
                                clearable
                                @change="onMinamelProductTypeChange"
                                v-model="minamelFilter.filters.product_type"
                                label="Tipo de produto"
                                :items="[{id: 'AV', text: 'AV'}, { id: 'BV', text: 'BV'}]"
                                item-value="id"
                                item-text="text"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="clearMinamelFilters"
                              style="color: white"
                              color="red"
                            >Limpar</v-btn>
                            <v-btn
                              @click="getMinamelBalance"
                              style="color: white"
                              color="green"
                            >Buscar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-data-table
              :headers="headers"
              :items="minamelTable.items"
              :loading="minamelTable.loading"
              :total-items="minamelTable.totalItems"
              :no-results-text="minamelTable.noResultText"
              :no-data-text="minamelTable.noDataText"
              item-key="B1_COD"
              class="elevation-1"
              hide-default-footer
              @click:row="goToMovements"
            >
              <template v-slot:[`item.balance`]="{ item }">
                <v-chip
                  color="blue darken-1"
                  text-color="white"
                >
                {{ formatAmount(item.balance) }}
                </v-chip>
              </template>
              <template slot="no-data">
                <div class="text-xs-center">{{ minamelTable.noDataText }}</div>
              </template>
            </v-data-table>

            <div>
              <v-card class="pa-3">
                <v-row row>
                  <v-col md="3">
                    <v-row>
                      <v-col  sm="6" pa-2>
                        <span class="actionPaginationText">{{ minamelTable.rowPerPageText }}</span>
                      </v-col>
                      <v-col sm="2">
                        <v-select
                          v-model="minamelTable.rowsPerPage"
                          :items="minamelTable.rowPerPageItems"
                          label
                          :hide-details="true"
                          class="actionPagination"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col md="6" class="text-xs-center">
                    <v-pagination
                      v-model="minamelTable.currentPage"
                      :length="minamelTable.totalPages"
                      v-bind:disabled="minamelTable.disabled"
                      @input="minamelPageChange"
                      :total-visible="minamelTable.totalPagesVisible"
                      prev-icon="arrow_left"
                      next-icon="arrow_right"
                      primary
                    />
                  </v-col>

                  <v-col md="3">&nbsp;</v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import jwt from 'vue-jwt-decode'

export default {
	name: 'SupplierBalance',
	mounted () {
		this.token = this.minamelServices.getToken()
		const { product_type } = this.$route.params

		if (product_type) {
			this.supplierFilter.filters.product_type = product_type
			this.minamelFilter.filters.product_type = product_type
		}

		this.getSuppliersBalance()
		this.getMinamelBalance()
	},
	methods: {
    getText(value) {
      return `${value.erp_code} - ${value.name}`;
    },
		goToMovements (item) {
			const data = { id: item.id, name: item.name, product_type: this.minamelFilter.filters.product_type }
             
      this.$router.push({
				name: 'InventoryMovements',
				params: {
					product_type: data.product_type,
					supplier_id: data.id,
					supplier_name: data.name
				}
			})
		},
		onSupplierProductTypeChange () {
			this.supplierTable.currentPage = 1
			this.supplierTable.totalPages = 1
			this.getSuppliersBalance()
		},
		onMinamelProductTypeChange () {
			this.supplierTable.currentPage = 1
			this.supplierTable.totalPages = 1
			this.getMinamelBalance()
		},
		minamelPageChange (page) {
			this.minamelTable.currentPage = page
		},
		supplierPageChange (page) {
			this.supplierTable.currentPage = page
		},
		clearSupplierFilters () {
			this.supplierFilter.filters.supplier_id = null
			this.supplierFilter.filters.product_type = null

			this.getSuppliersBalance()
		},
		clearMinamelFilters () {
			this.minamelFilter.filters.supplier_id = null
			this.minamelFilter.filters.product_type = null

			this.getMinamelBalance()
		},
		formatAmount (value) {
			let val = (value / 1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		},
		getSuppliersBalance () {
			this.supplierTable.loading = true

			const pagination = {
				sort: '',
				orientation: 'ASC',
				page: this.supplierTable.currentPage,
				rowsPerPage: this.supplierTable.rowsPerPage
			}

			this.$http
				.get(this.minamelServices.getUrl() + 'inventory-movements/get-suppliers-balance', {
					params: {
						pagination,
						filters: {
							supplier_id: this.supplierFilter.filters.supplier_id,
							product_type: this.supplierFilter.filters.product_type,
							only_debit: 'N'
						}
					},
					headers: { token: this.token }
				})
				.then((response) => {
					if (response.body.error) {
						this.snackbar.color = 'error'
						this.snackbar.message = response.body.message
						this.$root.$SnackBar.show(this.snackbar)
						return
					}

					this.supplierTable.items = response.body.data

					this.supplierTable.items = response.body.data
					this.supplierTable.totalItems = response.body.total_records

					this.supplierTable.totalPages = Math.ceil(this.supplierTable.totalItems / this.supplierTable.rowsPerPage)
				})
				.catch(() => {
					this.snackbar.color = 'error'
					this.snackbar.message = this.CONSTANTS.texts.errors.connection
					this.$root.$SnackBar.show(this.snackbar)
				})
				.finally(() => {
					this.supplierTable.loading = false
				})
		},
		getMinamelBalance () {
			this.minamelTable.loading = true

			const pagination = {
				sort: '',
				orientation: 'ASC',
				page: this.minamelTable.currentPage,
				rowsPerPage: this.minamelTable.rowsPerPage
			}

			this.$http
				.get(this.minamelServices.getUrl() + 'inventory-movements/get-suppliers-balance', {
					params: {
						pagination,
						filters: {
							supplier_id: this.minamelFilter.filters.supplier_id ? this.minamelFilter.filters.supplier_id.id : null,
							product_type: this.minamelFilter.filters.product_type,
							only_debit: 'Y'
						}
					},
					headers: { token: this.token }
				})
				.then((response) => {
					if (response.body.error) {
						this.snackbar.color = 'error'
						this.snackbar.message = response.body.message
						this.$root.$SnackBar.show(this.snackbar)
						return
					}

					this.minamelTable.items = response.body.data

					this.minamelTable.items = response.body.data
					this.minamelTable.totalItems = response.body.total_records

					this.minamelTable.totalPages = Math.ceil(this.minamelTable.totalItems / this.minamelTable.rowsPerPage)
				})
				.catch(() => {
					this.snackbar.color = 'error'
					this.snackbar.message = this.CONSTANTS.texts.errors.connection
					this.$root.$SnackBar.show(this.snackbar)
				})
				.finally(() => {
					this.minamelTable.loading = false
				})
		}
	},
	watch: {
		'minamelTable.currentPage': {
			handler () {
				this.getMinamelBalance()
			},
			deep: true
		},
		'supplierTable.currentPage': {
			handler () {
				this.getSuppliersBalance()
			},
			deep: true
		},
		supplierTerm (val) {
			if (!val) return

			if (this.isLoading) return

			this.isLoading = true

			this.$http
				.get(this.minamelServices.getUrl() + 'suppliers/get', {
					params: {
						term: val
					},
					headers: { token: this.token }
				})
				.then((response) => {
					this.supplierSuppliers = response.body.data
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		minamelTerm (val) {
			if (!val) return

			if (this.isLoading) return

			this.isLoading = true

			this.$http
				.get(this.minamelServices.getUrl() + 'suppliers/get', {
					params: {
						term: val
					},
					headers: { token: this.token }
				})
				.then((response) => {
					this.minamelSuppliers = [...response.body.data, this.minamelFilter.filters.supplier_id]
				})
				.finally(() => {
					this.isLoading = false
				})
		}
	},
	data () {
		return {
			token: null,
			supplierFilter: {
				filters: {
					supplier_id: null,
					product_type: null
				}
			},
			minamelFilter: {
				filters: {
					supplier_id: null,
					product_type: null
				}
			},
			supplierTerm: '',
			minamelTerm: '',
			isLoading: false,
			supplierSuppliers: [],
			minamelSuppliers: [],
			headers: [
				{
					text: 'Código',
					align: 'left',
					value: 'id',
					sortable: false,
					width: '600'
				},
				{
					text: 'Nome',
					align: 'left',
					value: 'name',
					sortable: false,
					width: '600'
				},
				{
					text: 'Saldo',
					align: 'left',
					value: 'balance'
				}
			],
			supplierTable: {
				disabled: false,
				loading: false,
				totalPagesVisible: this.CONSTANTS.integer.table.totalPagesVisible,
				noResultText: this.CONSTANTS.texts.table.noResultText,
				rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
				noDataText: this.CONSTANTS.texts.table.noDataText,
				currentPage: 1,
				totalPages: 1,
				rowsPerPage: 5,
				rowPerPageItems: this.CONSTANTS.arrays.table.itensToView,
				pagination: {
					rowsPerPage: 10,
					sortBy: 'balance',
					descending: true,
					page: 1,
					totalItems: 0
				},
				totalItems: 0,
				items: []
			},
			minamelTable: {
				disabled: false,
				loading: false,
				totalPagesVisible: this.CONSTANTS.integer.table.totalPagesVisible,
				noResultText: this.CONSTANTS.texts.table.noResultText,
				rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
				noDataText: this.CONSTANTS.texts.table.noDataText,
				currentPage: 1,
				totalPages: 1,
				rowsPerPage: 5,
				rowPerPageItems: this.CONSTANTS.arrays.table.itensToView,
				pagination: {
					rowsPerPage: 10,
					sortBy: 'balance',
					descending: true,
					page: 1,
					totalItems: 0
				},
				totalItems: 0,
				items: []
			}
		}
	}
}
</script>

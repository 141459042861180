<template>
  <v-container fluid :class="projectId ? ' pa-0' : ''">
    <v-row row>
      <v-col xs="12" v-if="!projectId">
        <v-subheader>
          <h2>Caderno de campo</h2>
        </v-subheader>
      </v-col>
    </v-row>
    <v-card :class="!projectId ? 'elevation-1' : 'elevation-0 mt-8'">
      <v-tabs v-model="tab" show-arrows>
        <v-tabs-slider :color="$vuetify.theme.primary"></v-tabs-slider>

        <v-tab
          :style="{ color: $vuetify.theme.primary }"
          v-for="(item, i) of tabs"
          :key="i"
          :to="item.route"
          >{{ item.title }}</v-tab
        >
      </v-tabs>

      <v-divider></v-divider>

      <v-tabs-items>
        <v-tab-item v-for="(item, i) of tabs" :key="i" :value="item.route">
          <!-- <component v-bind:is="item.component" :apiaries="apiaries" :suppliers="suppliers"></component> -->
        </v-tab-item>
        <router-view
          :class="projectId ? 'ma-4' : ''"
          :apiaries="apiaries"
          :suppliers="suppliers"
          :projectId="projectId"
          :routeName="routeName"
        ></router-view>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialog" v-if="dialog" persistent width="1200">
      <HoneyAppointments
        v-if="componentName === 'HoneyAppointments'"
        :apiaries="apiaries"
        :suppliers="suppliers"
        :projectId="projectId"
        :routeName="routeName"
        :importToProject="true"
      />
      <BeeswaxAppointments
        v-if="componentName === 'BeeswaxAppointments'"
        :apiaries="apiaries"
        :suppliers="suppliers"
        :projectId="projectId"
        :routeName="routeName"
        :importToProject="true"
      />
      <CleaningRecords
        v-if="componentName === 'CleaningRecords'"
        :suppliers="suppliers"
        :projectId="projectId"
        :routeName="routeName"
        :importToProject="true"
      />
      <FeedRecords
        v-if="componentName === 'FeedRecords'"
        :suppliers="suppliers"
        :projectId="projectId"
        :routeName="routeName"
        :importToProject="true"
      />
      <DiseaseTreatmentRecords
        v-if="componentName === 'DiseaseTreatmentRecords'"
        :suppliers="suppliers"
        :projectId="projectId"
        :routeName="routeName"
        :importToProject="true"
      />
      <BeeSwarmCaptures
        v-if="componentName === 'BeeSwarmCaptures'"
        :suppliers="suppliers"
        :projectId="projectId"
        :routeName="routeName"
        :importToProject="true"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { bus } from "../../../../../../src/main";

export default {
  name: "FieldNotes",
  components: {
    HoneyAppointments: () => import("./HoneyAppointments.vue"),
    BeeswaxAppointments: () => import("./BeeswaxAppointments.vue"),
    CleaningRecords: () => import("./CleaningRecords.vue"),
    FeedRecords: () => import("./FeedRecords.vue"),
    DiseaseTreatmentRecords: () => import("./DiseaseTreatmentRecords.vue"),
    BeeSwarmCaptures: () => import("./BeeSwarmCaptures.vue"),
  },
  props: {
    routeName: { type: String, default: "/field-notes" },
    type: { type: String, default: "RECORD" },
    projectId: { type: Number, default: null },
    importToProject: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      tab: null,
      componentName: "",
      tabs: [
        {
          title: "Mel",
          route: this.routeName + "/honey-appointments",
        },
        {
          title: "Cera",
          route: this.routeName + "/beeswax-appointments",
        },
        {
          title: "Limpeza / Manejo",
          route: this.routeName + "/cleaning-records",
        },
        {
          title: "Alimentação artificial",
          route: this.routeName + "/feed-records",
        },

        {
          title: "Tratamento de doenças",
          route: this.routeName + "/disease-treatment-records",
        },
        {
          title: "Integração de enxames",
          route: this.routeName + "/bee-swarm-captures",
        },
      ],
      apiaries: [],
      suppliers: [],
    };
  },
  created() {
    bus.$on("openDialog", (data) => {
      this.dialog = !this.dialog;
      this.componentName = data.params.componentName;
    });

    bus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },
  async mounted() {
    await this.getApiary();
    await this.getBeekpeers();
  },
  methods: {
    async getApiary() {
      try {
        let response = await this.minamelServices.ApiaryServiceV2().getAll();

        this.apiaries = response.data;
      } catch (err) {
        console.error(err);
      }
    },
    async getBeekpeers() {
      try {
        const response = await await this.minamelServices
          .SupplierServiceV2()
          .getBeekpeers();

        this.suppliers = response.data;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<template>
  <v-container fluid class="my-4">
    <v-card class="elevation-1 pa-3">
      <v-card-subtitle>
        <slot name="title" />
      </v-card-subtitle>

      <v-divider />

      <v-card-text>
        <slot name="body" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "DefaultFormLayout",
};
</script>

<style></style>

<template>
    <v-container fluid>
        <v-row row>
            <v-col xs="12" lg12 md="12">
                <v-subheader>
                    <h2>Templates</h2>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row row justify-space-between>
            <v-col xs="7" md="5">
                <v-text-field v-model="filter.term" ref="search" label="Faça a sua busca" clear-icon="close" clearable
                    append-icon="search" @click:append="getData" @keyup="getData" @keyup.enter="getData" />
            </v-col>

            <v-col></v-col>

            <v-col class="text-xs-right d-flex">
                <v-btn large class="flex-one ml-auto" color="primary" to="/document-templates/register">
                    + Incluir
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table :headers="table.headers" :items="table.items" :loading="table.loading" class="elevation-1"
            hide-default-footer>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="print(item)" icon v-on="on">
                            <v-icon color="#707070">download</v-icon>
                        </v-btn>
                    </template>
                    <span>Baixar Template</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :to="`/document-templates/edit/${item.id}`" icon v-on="on">
                            <v-icon color="#707070">edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="remove(item.id)" icon v-on="on">
                            <v-icon color="#707070">clear</v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <div>
            <v-card class="elevation-2 pa-3">
                <v-row wrap>
                    <v-col xs="12" md="12" class="text-xs-center">
                        <v-pagination v-if="table.pagination.perPage != -1" v-model="table.pagination.page"
                            :length="table.pagination.pages" v-bind:disabled="table.disabled"
                            :total-visible="table.pagination.totalVisible" prev-icon="arrow_left"
                            next-icon="arrow_right" primary />
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "DocumentTemplates",
    data() {
        return {
            expandFilters: false,
            filter: {
                term: "",
                active: "",
            },
            table: {
                headers: [
                    {
                        text: "Nome",
                        align: "left",
                        value: "title",
                    },
                    {
                        text: "",
                        value: "actions",
                        align: "right",
                        sortable: false,
                    },
                ],
                items: [],
                loading: false,
                pagination: {
                    page: 1,
                    rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
                    perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
                    totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
                },
            },
            snackbar: {
                message: "",
                visible: false,
                color: "success",
                timeout: 6000,
                x: "right",
                y: "top",
                multiLine: false,
            },
        }
    },
    async mounted() {
        const term = this.$functions.getSessionStorageSearchTerm("template");
        if (term && term != "") this.filter.term = term;

        await this.getData();
    },
    methods: {
        async getData() {
            this.$functions.setSessionStorageSearchTerm("template", this.filter.term);

            try {
                this.table.loading = true;

                const params = {
                    page: this.table.pagination.page,
                    perPage: this.table.pagination.perPage,
                    term: this.filter.term ? this.filter.term : "",
                };

                const res = await this.minamelServices.DocumentTemplateService().get(params);

                this.table.items = res.data;
                this.table.pagination.pages = res.meta.last_page;
                this.table.loading = false;
            } catch (err) {
                this.table.loading = false;
            }
        },
        async remove(id) {
            const question = "Deseja realmente excluir este template?";
            const type = "question";

            const confirm = await this.alertConfirm(question, type);

            if (!confirm) return;

            const res = await this.minamelServices.DocumentTemplateService().destroy(id);
            if (res) await this.getData();
        },
        async print(item) {
            try {
                const response = await this.minamelServices
                    .DocumentTemplateService()
                    .print(item.id);

                const url = window.URL.createObjectURL(response);
                const link = document.createElement("a");

                link.href = url;
                link.setAttribute(
                    "download",
                    `${item.title.replace(/\//g)}.pdf`
                );

                document.body.appendChild(link);

                link.click();
            } catch (err) {
                this.snackbar.color = "error";
                this.snackbar.message =
                    "Ocorreu um erro ao realizar o download do formulário";
                this.$root.$SnackBar.show(this.snackbar);
            }
        },
        async alertConfirm(ask, type) {
            let response = await this.$swal.fire({
                title: `${ask}`,
                type: type,
                showCancelButton: true,
                confirmButtonColor: "#4caf50",
                cancelButtonColor: "#ff1744",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                allowOutsideClick: false,
            });

            if (response.value) return true;
            return false;
        },
    },
    watch: {
        "table.pagination.page": function () {
            this.getData();
        },
    },
}
</script>
<template>
  <v-select
    label="Produtor para gerar"
    :items="items"
    v-model="model"
    item-text="name"
    item-value="id"
    return-object
  />
</template>

<script>
import ModelManagementMixin from "@/helpers/ModelManagementMixin";
import NotificationHelper from "@/shared/helpers/NotificationHelpers";

export default {
  name: "SupplierSelect",
  mixins: [ModelManagementMixin],
  data() {
    return {
      loading: false,
      items: [],
      error: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const params = {
          type: "PROJECT",
          projectId: this.$route.params.id,
          page: 1,
          perPage: 1000,
        };

        const response = await this.minamelServices
          .ApiaryServiceV2()
          .getAll(params);

        const suppliers = response.data.data.map((apiary) => apiary.supplier);

        const notRepeatedSupplier = [];

        suppliers.forEach((supplier) => {
          if (!notRepeatedSupplier[supplier.id]) {
            notRepeatedSupplier[supplier.id] = supplier;
          }
        });

        this.items = suppliers;
      } catch (error) {
        NotificationHelper.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>


/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {TreatRequest}
 */
export default class TreatRequest {

    constructor(instance, response){
        this.instance = instance;
        this.response = response;
    }

    result(instance, response = null){
        if(instance.$root.$LoadingBar) instance.$root.$LoadingBar.hide();

        if(instance.blockButton) instance.blockButton = false;

        if (response && response.error) {
            return this.error(instance, response.message);
        }
             
        return this.success(instance, response);           
    }

    success(instance, response){
        if(response){
            if(instance.snackbar && instance.$root.$SnackBar) {
                instance.snackbar.color = "success";
                instance.snackbar.message = response.message;
                instance.$root.$SnackBar.show(instance.snackbar);
            }
        }
        return true;
    }
    error(instance, error){
        if(error){
            if(instance.snackbar && instance.$root.$SnackBar) {
                instance.snackbar.color = "error";
                instance.snackbar.message = error;
                instance.$root.$SnackBar.show(instance.snackbar);
            }
        }
        return;
    }

    static build() {
		return new this(this.instance, this.response);
	}
}
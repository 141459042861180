
import components from './components';

const MinamelLibrary = {
	install (Vue) {
		for (const componentName in components) {
			const component = components[componentName];

			Vue.component(component.name, component);
		}
	}
};

export default components;

if (typeof window !== 'undefined' && window.Vue) {
	window.Vue.use(MinamelLibrary);
}

<template>
	<v-autocomplete
		:class="required ? 'required' : ''"
		clearable
		color="primary"
		chips
		deletable-chips
		:items="listEstados"
		v-model="selectedEstado"
		item-value="sigla"
		:placeholder="placeholder"
		item-text="nome"
		:label="label ? label : 'Estado'"
		:rules="rules"
	></v-autocomplete>
</template>

<script>
export default {
	name: "EstadosComponent",
	data () {
		return {
			cidade: null,
			// listEstados: this.CONFIG.ESTADOS_CIDADES
			listEstados: this.ESTADOS_CIDADES
		};
	},
	props: ["value", "placeholder", "label", "required", "rules"],
	computed: {
		selectedEstado: {
			get () {
				return this.value;
			},
			set (val) {
				this.$emit("updateEstado", val, this.listEstados);
			}
		}
	}
};
</script>

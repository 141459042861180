/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {UserService}
 */
export default class UserService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = 'users/';

	getUser () {
		this.path = "users/get";
		return this;
	}
	getAvatar () {
		this.path = "avatar/";
		return this;
	}

	createUser (id = null) {
		if (id) {
			this.path = "users/update";
		} else {
			this.path = "users/create";
		}
		return this;
	}
	updateUser () {
		this.path = "users/update";
		return this;
	}

	deleteUser () {
		this.path = "users/delete";
		return this;
	}

	getAll (params) {
		return super.get(`/?${this.queryString(params)}`);
	}

	show (id, filter = {}) {
		return super.get(`/${id}`, filter);
	}

	store (data) {
		return super.post('/', data);
	}

	update (id, data) {
		return super.put(`/${id}`, data);
	}

	destroy (id) {
		return super.delete(`/${id}`);
	}

	getProjectUsers (projectId) {
		return super.get(`/actions/project-users/${projectId}`);
	}

	// access() {
	//     this.path = "users/access";
	//     return this;
	// }

	// group() {
	//     this.path = "users/group";
	//     return this;
	// }

	// permission() {
	//     this.path = "users/permission";
	//     return this;
	// }
}

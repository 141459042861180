import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {ParameterService}
 */
export default class ParameterService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'parameters/';
    
    getParameter() {
        this.path = "parameters/get";
        return this;
    }

    createParameter(id = null) {
        if(id){
            this.path = "parameters/update";
        }else{
            this.path = "parameters/create";
        }
        return this;
    }
    updateParameter() {
        this.path = "parameters/update";
        return this;
    }
    deleteParameter() {
        this.path = "parameters/delete";
        return this;
    }

}
<template>
	<v-container grid-list-md fluid :class="projectId ? 'mt-5 pa-0' : ''">
		<v-row row>
			<v-col xs="12"  v-if="!projectId">
				<v-subheader>
					<h2>Documentos</h2>
				</v-subheader>
			</v-col>
		</v-row>
		<v-card :class="!projectId ? 'elevation-1' : 'elevation-0'">
			<v-tabs v-model="tab" show-arrows>
				<v-tabs-slider :color="$vuetify.theme.primary"></v-tabs-slider>

				<!-- :disabled="!projectId && item.title === 'Respostas'" -->
				<v-tab
					:style="{ color: $vuetify.theme.primary }"
					v-for="(item, i) of documentTabs"
					:key="i"
					class="cursor-time"
					:to="item.route"
				>
					{{ item.title }}
				</v-tab>
			</v-tabs>

			<v-divider></v-divider>

			<v-tabs-items :class="projectId ? 'ma-4' : ''">
				<v-tab-item
					v-for="(item, i) of documentTabs"
					:key="i"
					:value="item.route"
				>
				</v-tab-item>
			</v-tabs-items>

			<router-view
				:class="projectId ? 'ma-4' : 'ma-2'"
				:projectId="projectId"
				:routeName="routeName"
			></router-view>
		</v-card>

		<v-dialog v-model="dialog" v-if="dialog" persistent width="900">
			<Forms
				:projectId="projectId"
				:routeName="routeName"
				:importToProject="true"
			/>
		</v-dialog>
	</v-container>
</template>
<script>
import { bus } from "../../../../../../src/main";

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Documents",
	components: {
		Forms: () => import("./Forms.vue")
	},
	props: {
		routeName: { type: String, default: "/documents" },
		type: { type: String, default: "RECORD" },
		projectId: { type: Number, default: null },
		importToProject: { type: Boolean, default: false }
	},
	data() {
		return {
			tab: null,
			componentName: "",
			dialog: false,
			tabs: [
				{
					title: "Formulários",
					route: this.routeName + "/forms"
				},
				{
					title: "Respostas",
					route: this.routeName + "/responses"
				}
			]
		};
	},

	created() {
		bus.$on("openDialogDocuments", data => {
			this.dialog = !this.dialog;
			this.componentName = data.params.componentName;
		});

		bus.$on("closeDialogDocuments", () => {
			this.dialog = false;
		});
	},

	computed: {
		documentTabs() {
			const areOutsideAProject = this.$route.path == "/documents/forms";

			if (areOutsideAProject) {
				return [this.tabs[0]];
			} else {
				return this.tabs;
			}
		}
	},

	mounted() {
		this.tab = "s";
	},

	methods: {}
};
</script>

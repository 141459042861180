<template>
  <div class="w-100 text-center">
    <div class="image-preview elevation-0">
      <v-img
        class="image-preview"
        :max-width="responsivity.maxWidth"
        :max-height="responsivity.maxHeight"
        :src="imageSrc"
        :class="noCenter ? '' : 'mx-auto'"
      />
    </div>

    <!-- Change Button -->
    <div class="d-flex" :class="noCenter ? '' : 'justify-center'">
      <v-btn
        class="flex-item w-100 my-1"
        :style="{ 'max-width': `${responsivity.maxWidth}px` }"
        depressed
        text
        @click="change"
      >
        <v-icon class="mx-1"> mdi-pen </v-icon>

        Editar
      </v-btn>
    </div>
  </div>
</template>

<script>
import ModelManagementMixin from "@/shared/mixins/ModelManagementMixin";
import Responsivity from "../domain/Responsivity";
import AuthHelpers from "@/shared/helpers/AuthHelpers";

export default {
  name: "ImagePreview",
  mixins: [ModelManagementMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    skipCrop: Boolean,
    folder: String,
    noCenter: Boolean,
    ...Responsivity.props(),
  },
  computed: {
    imageSrc() {
      const image = this.model
        ? AuthHelpers.getUrlWithToken(
            this.model.preview || this.model,
            this.folder
          )
        : "";

      return image;
    },
  },
  methods: {
    change() {
      this.$emit("change");
    },
  },
};
</script>

<style></style>

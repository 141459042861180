<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col md="8">
        <v-card class="elevation-1 pa-3 mb-4">
          <v-form ref="form">
            <v-row row wrap>
              <v-col xs="12">
                <v-subheader>
                  <h2>Meu usuário</h2>
                </v-subheader>
              </v-col>

              <v-divider></v-divider>

              <v-col xs="12" md="4" class="pa-3">
                <v-text-field
                  :rules="formRules"
                  v-model="register.name"
                  label="Nome"
                  class="required"
                  ref="name"
                />
              </v-col>

              <v-col xs="12" md="4" class="pa-3">
                <v-text-field
                  :rules="formRules"
                  v-model="register.email"
                  label="E-mail"
                  class="required"
                  ref="email"
                />
              </v-col>

              <v-col xs="12" md="4" class="pa-3">
                <v-text-field
                  :rules="formRules"
                  v-model="register.login"
                  label="Usuário"
                  class="required"
                  ref="user"
                />
              </v-col>

              <v-col xs="12" md="4" class="pa-3">
                <v-text-field
                  v-model="register.password"
                  :append-icon="!e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  label="Senha"
                  ref="password"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn style="color: white" color="green" @click="save">
              Salvar
            </v-btn>
            <v-btn color="error" text @click="$router.go(-1)"> Cancelar </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" md="4" pl-3>
        <v-card height="333" class="elevation-1 pa-3">
          <v-avatar class="mx-auto d-block" style="color: grey" size="130">
            <img v-if="avatar" :src="avatar" />
          </v-avatar>

          <v-card-text class="text-xs-center">
            <h2 class="card-title font-weight-light">
              {{ register.name }}
            </h2>
            <h4 class="category text-gray font-weight-thin mb-3">
              {{ register.email }}
            </h4>
          </v-card-text>

          <v-card-actions class="d-block text-xs-center">
            <input
              @change="uploadImage"
              ref="myImage"
              type="file"
              name="file"
              id="file"
              accept="image/*"
              class="inputfile"
              style="display: none"
            />
            <label
              for="file"
              class="v-btn theme--light primary cursor-hand"
              style="cursor: pointer"
            >
              Alterar imagem de perfil
            </label>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwt from "vue-jwt-decode";

export default {
  name: "MyUser",
  data: () => ({
    formValid: true,
    formRules: [(v) => !!v || "Campo obrigatório!"],
    e1: true,
    userData: {},
    register: {},
    avatar: "",
    snackbar: {
      message: "",
      visible: false,
      color: "success",
      timeout: 6000,
      x: "right",
      y: "top",
      multiLine: false,
    },
  }),

  async mounted() {
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;

    this.avatar = await this.getAvatar();

    await this.getUser();
  },

  methods: {
    async getUser() {
      const response = await this.minamelServices
        .UserServiceV2()
        .show(this.userData.id);

      if (response) {
        this.register = response.data;
        this.register.password = "";
      }
    },

    async getAvatar() {
      let response = await this.minamelServices
        .UserService()
        .getAvatar()
        .search({ id: this.userData.id });

      return response && response.data ? response.data : null;
    },

    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);

        return;
      }

      try {
        const response = await this.minamelServices
          .UserServiceV2()
          .update(this.register.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Dados atualizados com sucesso.";
          this.$root.$SnackBar.show(this.snackbar);
        }
      } catch (err) {
        this.snackbar.color = "danger";
        this.snackbar.message = "Ocorreu um erro ao atualizar os dados.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    uploadImage(event) {
      this.getBase64(event.target.files[0]).then((result) => {
        this.minamelServices
          .UploadService()
          .sendUpload()
          .send({ user_id: this.register.id, base64: result })
          .then((response) => {
            this.avatar = response.data;

            this.snackbar.message = "Imagem de perfil atualizada com sucesso.";

            this.$root.$SnackBar.show(this.snackbar);
          });
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style></style>

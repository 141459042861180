<template>
    <div style="height: 100vh;
    padding-top: 64px;">
        <div id="map" style="height: 100% !important;"></div>
        <template v-if="!!this.google && !!this.map">
            <map-provider :google="google" :map="map">
                <slot />
            </map-provider>
        </template>
    </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import MapProvider from "./MapProvider";

export default {
	data () {
		return {
			newGoogle: null,
			newMap: null
		};
	},
	props: {
		google: Object,
		map: Object
	},
	components: {
		MapProvider
	},
	methods: {
		async initializeMap (mapConfig) {
			this.newGoogle = this.google;
			this.newMap = this.map;
			if (!this.google) {
				try {
					await GoogleMapsApiLoader({
						libraries: ['geometry', "geocoder"],
						apiKey: this.CONFIG.VUE_APP_API_KEY_GOOGLE_MAPS
					}).then(async google => {
						this.newGoogle = google;
					// await this.initializeMap();
					});
				} catch (err) {
					console.error(err);
				}
			}

			const mapContainer = this.$el.querySelector("#map");
			const { Map } = this.newGoogle.maps;
			this.newMap = await new Map(mapContainer, mapConfig);

			await this.$emit('setGoogleData', this.newGoogle, this.newMap);
		}
	}
};

</script>

<style scoped>
    #map {
        height: 100vh;
        width: 100%;
    }

</style>

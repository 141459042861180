<template>
  <div>
    <v-row align-end ml-3 mr-3>
      <v-col>
        <h2 class="subtitle">Notificações</h2>
      </v-col>
      <v-col class="text-xs-right">
        <v-btn pr-2 color="primary" @click="addItem">+ Nova notificação</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="1" class="ma-3">
          <v-data-table
            :headers="table.headers"
            :items="projectDate.projectDateNotifies"
            :loading="table.loading"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:items="props">
              <tr>
                <td class="text-xs-left">
                  {{ props.item.name }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.days }}
                </td>
                <td class="text-xs-left">
                  {{ getNotificationNames(props.item.type) }}
                </td>
                <td class="text-xs-left">
                  {{
                    getUserNames(props.item && props.item.projectNotifyUsers)
                  }}
                  <!-- {{ props.item.projectNotifyUsers}} -->
                </td>
                <td class="text-xs-right" style="min-width: 185px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="editItem(props.item, props.index)"
                        icon
                        v-on="on"
                      >
                        <v-icon color="#707070">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="remove(props.item)" icon v-on="on">
                        <v-icon color="#707070">clear</v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ headerText }} notificação</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form ref="form">
            <v-container grid-list-md ma-0 pa-0>
              <v-row wrap>
                <v-col xs="12">
                  <span class="font-weight-bold">
                    {{ projectDate.name }} -
                    {{ $functions.formatDate(projectDate.date) }}
                  </span>
                </v-col>

                <v-col xs="12">
                  <v-text-field
                    label="Descrição"
                    v-model="register.name"
                    :rules="[formRules.required]"
                  ></v-text-field>
                </v-col>

                <v-col md="4" lg4 xs="12">
                  <v-text-field
                    v-model="register.days"
                    label="Dias de antecedencia"
                    type="number"
                    :rules="[formRules.required]"
                  ></v-text-field>
                </v-col>

                <v-col md="8" lg8 xs="12">
                  <v-select
                    small-chips
                    deletable-chips
                    :rules="[formRules.required]"
                    v-model="register.type"
                    :items="notificationTypes"
                    multiple
                    label="Tipo"
                  ></v-select>
                </v-col>

                <v-col xs="12" class="text-xs-center">
                  <v-select
                    chips
                    deletable-chips
                    :rules="[formRules.required]"
                    v-model="register.projectNotifyUsers"
                    :items="projectUsers"
                    multiple
                    item-text="name"
                    item-value="id"
                    label="Usuários"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 white--text" @click="save">Salvar</v-btn>
          <v-btn color="red darken-1" text @click="dialog = false"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ProjectNotifications",
  props: {
    projectId: { type: String },
    projectDate: { type: Object },
  },

  data() {
    return {
      dialog: false,
      projectDateNotify: {},
      headerText: "Adicionar",
      editing: false,
      formRules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      notificationTypes: [
        // { value: 1, text: "E-mail" },
        // { value: 2, text: "Navegador" },
        { value: 3, text: "Agenda" },
      ],
      register: {
        id: null,
        days: null,
        name: "",
        projectNotifyUsers: [],
        type: "",
      },
      projectUsers: [],
      table: {
        headers: [
          { text: "Descrição", value: "name" },
          { text: "Dias de antecedência", value: "days" },
          { text: "Tipo", value: "type" },
          { text: "Usuários", value: "users" },
          {
            text: "",
            value: "actions",
            align: "left",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },

  async mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    this.projectId = this.$route.params.id;

    if (this.projectDate.projectDateNotifies) {
      this.projectDate.projectDateNotifies.map((item) => {
        if (item) {
          item.projectNotifyUsers = item.projectNotifyUsers.map(
            (item) => item.user_id
          );
        }
      });
    }

    await this.getProjectUsers();
  },

  methods: {
    async getProjectUsers() {
      let response = await this.minamelServices
        .UserServiceV2()
        .getProjectUsers(this.projectId);

      if (!response.error) {
        this.projectUsers = response.data;
      }
    },

    addItem() {
      this.editing = false;

      this.register = {
        type: [3],
      };

      this.dialog = true;
    },

    editItem(item, index) {
      this.headerText = "Editar";
      this.editing = true;

      this.register = item;

      this.register.projectNotifyUsers = this.register.projectNotifyUsers.map(
        (item) => (item.user_id ? item.user_id : item)
      );

      if (!Array.isArray(this.register.type)) {
        this.register.type = this.register.type.split(",").map(Number);
      }

      this.register.index = index;

      this.dialog = true;
    },

    remove(item) {
      const index = this.projectDate.projectDateNotifies.indexOf(item);
      // eslint-disable-next-line vue/no-mutating-props
      this.projectDate.projectDateNotifies.splice(index, 1);
    },

    save() {
      const formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);

        return;
      }

      this.register.type = JSON.stringify(this.register.type)
        .replace("[", "")
        .replace("]", "");

      if (!this.editing) {
        // eslint-disable-next-line vue/no-mutating-props
        this.projectDate.projectDateNotifies.push({ ...this.register });
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.projectDate.projectDateNotifies[this.register.index] = {
          ...this.register,
        };
      }

      this.dialog = false;
    },

    getNotificationNames(value) {
      if (value) {
        let newTypes = [];
        this.notificationTypes.map((type) => {
          if (value.includes(type.value)) {
            newTypes.push(type.text);
          }
          return type;
        });
        return newTypes.join(", ");
      }
    },

    getUserNames(users) {
      let data = "";
      let cont = 1;

      users.forEach((item) => {
        if (item.user) {
          data += item.user.name;
        } else {
          const user = this.projectUsers.find((item2) => item2.id === item);
          data += user.name;
        }

        if (users.length !== cont) {
          data += ", ";
          cont += 1;
        }
      });

      return data;
    },
  },
};
</script>

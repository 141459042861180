<template>
  <map-loader
    @setGoogleData="getGoogleData"
    :google="google"
    :map="map"
    ref="mapLoader"
  >
    <child-marker ref="childMarker" :mapBlocked="mapBlocked" />
  </map-loader>
</template>

<script>
import MapLoader from "./MapLoader.vue";
import ChildMarker from "./ChildMarker";

export default {
  name: "MyMap",
  props: {
    needUpdate: Boolean,
  },
  data() {
    return {
      mapConfig: null,
      array_circles: null,
      apiary: null,
      observations: null,
      google: null,
      map: null,
      mapBlocked: false,

      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  watch: {
    needUpdate() {
      this.$forceUpdate();
    },
  },
  components: {
    MapLoader,
    ChildMarker,
  },
  mounted() {
    this.loadDataMap();
  },
  methods: {
    getMarkers() {
      if (this.$refs.childMarker) {
        let markers = this.$refs.childMarker.getMarkers();
        return markers;
      } else {
        return { marker: [], observations: [] };
      }
    },
    getGoogleData(google, map) {
      this.google = google;
      this.map = map;
    },
    async loadDataMap(apiary, observations, blockMap) {
      // load apiary markers
      this.$root.$LoadingBar.show("Carregando...");
      let center;

      this.mapBlocked = blockMap;

      this.observations = observations;
      if (!apiary) return;

      let circles = await this.minamelServices
        .CircleMapService()
        .getCircleMap()
        .search({ active: 1 });

      if (!circles.error && circles.data.length && circles.data.length > 0) {
        this.array_circles = circles.data;
      }

      this.apiary = apiary;

      if (!this.apiary.latitude) {
        this.apiary.latitude = "-28.673584";
      }

      if (!this.apiary.longitude) {
        this.apiary.longitude = "-49.418704";
      }

      let latitude = await this.minamelServices
        .LatLngService()
        .checkLatLngType(this.apiary.latitude, { typeLatLng: "lat" });
      let longitude = await this.minamelServices
        .LatLngService()
        .checkLatLngType(this.apiary.longitude, { typeLatLng: "lng" });
      if (latitude && longitude) {
        this.apiary.latLng = {
          lat: parseFloat(latitude.latLng),
          lng: parseFloat(longitude.latLng),
        };
        center = this.apiary.latLng;
      }

      if (!center) {
        center = await this.getCurrentPosition();
      }

      this.mapConfig = {
        zoom: 12,
        mapTypeControl: true,
        center,
        mapTypeId: "hybrid",
        fullscreenControl: false,
      };

      await this.$refs.mapLoader.initializeMap(this.mapConfig);
      await this.$refs.childMarker.loadChildMarkers(
        this.apiary,
        this.observations,
        this.array_circles,
        this.google,
        this.map
      );

      this.$root.$LoadingBar.hide();
    },
    async getCurrentPosition() {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => {
            resolve(this.CONSTANTS.defaultPosition);
            // reject(error);
          }
        );
      });
    },
    async updateApiaryLocation(apiary) {
      try {
        await this.minamelServices.ApiaryServiceV2().update(apiary.id, {
          latitude: apiary.getPosition().lat().toString(),
          longitude: apiary.getPosition().lng().toString(),
        });

        this.snackbar.color = "success";
        this.snackbar.message = "Apiário alterado com sucesso";
        this.$root.$SnackBar.show(this.snackbar);
      } catch (err) {
        this.$refs.childMarker.alertError("Um erro ocorreu");
      }

      // return await this.minamelServices
      // 	.ApiaryService()
      // 	.updateApiary()
      // 	.update({
      // 		id: apiary.id,
      // 		latitude: apiary.getPosition().lat().toString(),
      // 		longitude: apiary.getPosition().lng().toString()
      // 	})
      // 	.then((response) => {
      // 		if (response.error) {
      // 			this.$refs.childMarker.alertError(response.message);
      // 		}
      // 	})
      // 	.catch((error) => {
      // 		this.$refs.childMarker.alertError(error);
      // 	});
    },
    async updateApiaryRemark(apiary_id, remarks) {
      return this.minamelServices
        .ApiaryRemarkService()
        .updateApiaryRemark()
        .update({ apiary_id, remarks })
        .then((response) => {
          if (response.error) {
            // eslint-disable-next-line no-undef
            console.error(response.error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getApiaryRemark(apiary_id) {
      if (apiary_id) {
        let response = await this.minamelServices
          .ApiaryRemarkService()
          .getApiaryRemark()
          .search({ apiary_id });
        try {
          if (response.data.length > 0) {
            return response.data;
          } else if (response.error) {
            console.error(response.error);
            return [];
          }
        } catch (error) {
          console.error(error);
          return [];
        }
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
#map {
  height: 90vh !important;
}
</style>

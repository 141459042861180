<template>
  <v-container fluid>
    <v-subheader>
      <h2>Extrato de tambores</h2>
    </v-subheader>
    <v-row row>
      <v-col xs="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4 style="color: gray">Selecione os filtros do extrato:</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row row>
                  <v-col xs="6">
                    <v-autocomplete
                      clearable
                      style="margin-top: 15px"
                      v-model="report.supplier"
                      :items="suppliers"
                      :loading="isLoading"
                      :search-input.sync="term"
                      :item-text="getText"
                      item-value="id"
                      return-object
                      label="Fornecedor"
                      placeholder="Pesquisar..."
                      prepend-icon="mdi-database-search"
                    />
                  </v-col>

                  <v-col xs="3">
                    <v-select
                      clearable
                      style="padding-left: 32px"
                      color="primary"
                      multiple
                      chips
                      deletable-chips
                      :items="movement_types"
                      v-model="report.movement_type"
                      item-value="id"
                      item-text="name"
                      label="Tipo de Movimentação"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row style="margin-left: 32px" row>
                  <v-col xs="2">
                    <v-select
                      clearable
                      multiple
                      chips
                      deletable-chips
                      color="primary"
                      :items="product_types"
                      v-model="report.product_type"
                      item-value="id"
                      item-text="id"
                      label="Tipo do Produto"
                    ></v-select>
                  </v-col>
                  <v-col xs="2">
                    <v-select
                      style="padding-left: 15px"
                      clearable
                      multiple
                      chips
                      deletable-chips
                      color="primary"
                      :items="product_situations"
                      v-model="report.product_situation"
                      item-value="id"
                      item-text="text"
                      label="Situação do Produto"
                    ></v-select>
                  </v-col>
                  <v-col xs="2">
                    <v-select
                      style="padding-left: 15px"
                      clearable
                      multiple
                      chips
                      deletable-chips
                      color="primary"
                      :items="discounts"
                      v-model="report.discount"
                      item-value="id"
                      item-text="text"
                      label="Desconto"
                    ></v-select>
                  </v-col>
                  <v-col xs="2">
                    <v-menu
                      ref="menufrom"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          clearable
                          class="required"
                          style="margin-top: 14px; padding-left: 20px"
                          v-model="from"
                          label="Data inicial"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-br"
                        v-model="report.from"
                        type="date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menufrom.save(report.from)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col xs="2">
                    <v-menu
                      ref="menuto"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          class="required"
                          clearable
                          style="
                            margin-top: 14px;
                            padding-left: 15px;
                            padding-right: 17px;
                          "
                          v-model="to"
                          label="Data final"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-br"
                        v-model="report.to"
                        type="date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuto.save(report.to)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="text-xs-center">
                    <v-btn
                      @click="pdf('A')"
                      style="margin-right: 10px"
                      color="#999999"
                      dark
                      v-on="on"
                      >Gerar PDF</v-btn
                    >
                  </div>
                  <v-btn @click="clearFilters" style="color: white" color="red"
                    >Limpar</v-btn
                  >
                  <v-btn
                    @click="getMovements"
                    style="color: white"
                    color="green"
                    >Buscar</v-btn
                  >
                </v-card-actions>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div v-if="hasContent">
          <v-row row wrap>
            <v-col style="margin-top: 10px">
              <v-subheader>
                <h2>Saldo inicial ({{ report.from | formatDate }})</h2>
              </v-subheader>
            </v-col>
          </v-row>

          <v-row row wrap>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">A Vazio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["AV_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">B Vazio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["BV_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">A Cheio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["AC_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">B Cheio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["BC_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-data-table
            :headers="types.headers"
            :items="table.items"
            :loading="table.loading"
            :no-results-text="table.noResultText"
            :no-data-text="table.noDataText"
            item-key="B1_COD"
            class="elevation-1"
            hide-default-footer
            style="margin-top: 10px"
            @click:row="goToOperation"
          >
            <template v-slot:[`item.movement_date`]="{ item }">
              {{ item.movement_date | formatDate }}
            </template>
            <template v-slot:[`item.operation_name`]="{ item }">
              {{
                item.movement_type_name +
                (item.is_conversion == 2
                  ? " (Transf. " +
                    (item.product_type == "A" ? "B" : "A") +
                    " -> " +
                    item.product_type +
                    ")"
                  : "")
              }}
            </template>
            <template v-slot:[`item.supplier_name`]="{ item }">
              {{ item.supplier_name ? item.supplier_name : "MINAMEL" }}
            </template>
            <template v-slot:[`item.product_type`]="{ item }">
              <v-chip outlined color="#949494" text-color="black">{{
                item.product_type
              }}</v-chip>
            </template>
            <template v-slot:[`item.situation`]="{ item }">
              <div v-if="item.supplier_id !== null">
                <v-chip outlined color="#949494" text-color="black">{{
                  item.type == 1
                    ? situationNames[
                        item.movement_type_recipient_barrel_situation
                      ]
                    : situationNames[item.movement_type_sender_barrel_situation]
                }}</v-chip>
              </div>
              <div v-if="item.supplier_id == null">
                <v-chip outlined color="#949494" text-color="black">{{
                  item.movement_type_id == 2
                    ? "Cheio"
                    : item.type == 1
                    ? situationNames[item.movement_type_sender_barrel_situation]
                    : situationNames[
                        item.movement_type_recipient_barrel_situation
                      ]
                }}</v-chip>
              </div>
            </template>
            <template v-slot:[`item.debit`]="{ item }">
              {{
                item.final_debit == 0
                  ? "Sem débitos"
                  : "R$ " + formatAmount(item.final_debit)
              }}
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              {{
                item.discount == 0
                  ? "Sem descontos"
                  : "R$ " + formatAmount(item.discount)
              }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <v-chip v-if="item.type == '1'" color="green" text-color="white"
                >+ {{ formatAmount(item.amount) }}</v-chip
              >
              <v-chip v-if="item.type == '2'" color="red" text-color="white"
                >- {{ formatAmount(item.amount) }}</v-chip
              >
            </template>
            <template slot="no-data">
              <div class="text-xs-center">{{ table.noDataText }}</div>
            </template>
          </v-data-table>

          <div>
            <v-card class="pa-3">
              <v-row row>
                <v-col md="3">
                  <v-row>
                    <v-col sm="6" pa-2>
                      <span class="actionPaginationText">{{
                        table.rowPerPageText
                      }}</span>
                    </v-col>
                    <v-col sm="2">
                      <v-select
                        v-model="table.rowsPerPage"
                        :items="table.rowPerPageItems"
                        label
                        :hide-details="true"
                        class="actionPagination"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col md="6" class="text-xs-center">
                  <v-pagination
                    v-model="table.currentPage"
                    :length="table.totalPages"
                    v-bind:disabled="table.disabled"
                    @input="pageChange"
                    :total-visible="table.totalPagesVisible"
                    prev-icon="arrow_left"
                    next-icon="arrow_right"
                    primary
                  />
                </v-col>

                <v-col md="3">&nbsp;</v-col>
              </v-row>
            </v-card>
          </div>

          <v-row row wrap>
            <v-col style="margin-top: 10px">
              <v-subheader>
                <h2>Saldo final ({{ report.to | formatDate }})</h2>
              </v-subheader>
            </v-col>
          </v-row>

          <v-row row wrap>
            <v-col class="inventory_m" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">A Vazio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["AV_FINAL"] + reportBalance["AV_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">B Vazio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["BV_FINAL"] + reportBalance["BV_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">A Cheio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["AC_FINAL"] + reportBalance["AC_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
            <v-col class="inventory_movements_container" xs="2">
              <v-card class="inventory_movements_card">
                <v-card-title>
                  <v-icon large left>mdi-barrel</v-icon>
                  <span class="title font-weight-light">B Cheio</span>
                </v-card-title>

                <v-card-text style="font-size: 45px">{{
                  reportBalance["BC_FINAL"] + reportBalance["BC_INITIAL"]
                }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwt from "vue-jwt-decode";
const moment = require("moment");

export default {
  name: "InventoryMovements",
  mounted() {
    this.token = this.minamelServices.getToken();
    this.userData = sessionStorage.token
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.systemId = sessionStorage.systemId ? sessionStorage.systemId : null;
    this.systemData = sessionStorage.token
      ? jwt.decode(sessionStorage.token).systemData[this.systemId]
      : null;

    const { product_type, supplier_id, supplier_name } = this.$route.params;

    this.report.from = "2016-01-01";

    if (product_type) {
      this.report.product_type = product_type[0];
      this.report.product_situation = product_type[1];
    }
    if (supplier_name) {
      this.term = supplier_name;
    }
    if (product_type || supplier_id || supplier_name) {
      this.getData();
    }

    this.getMovementTypes();
  },
  filters: {
    formatDate: function (value) {
      if (!value) return null;
      const [year, month, day] = value.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    from: {
      get: function () {
        return this.formatDate(this.report.from);
      },
      set: function (newDate) {
        this.report.from = newDate;
      },
    },
    to: {
      get: function () {
        return this.formatDate(this.report.to);
      },
      set: function (newDate) {
        this.report.to = newDate;
      },
    },
  },
  methods: {
    getText(value) {
      return `${value.erp_code} - ${value.name}`;
    },
    pdf(reportType) {
      let itensToSearch = [];

      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        if (this.search.fieldToSearch[i].value) {
          itensToSearch.push({
            field: this.search.fieldToSearch[i].field,
            value: this.search.fieldToSearch[i].value,
          });
        }
      }

      const params = {
        sort: "",
        orientation: "ASC",
        page: this.table.currentPage,
        rowsPerPage: this.table.rowsPerPage,
        term: this.search.term,
        fieldsToSearch: itensToSearch.length <= 0 ? "" : itensToSearch,
        isPdf: true,
      };

      this.$http
        .post(
          this.minamelServices.getUrl() + "pdf/extract",
          {
            ...params,
            ...{
              report_type: reportType,
            },
            ...{
              dateFilter: {
                initial_date: this.report.from,
                final_date: this.report.to,
              },
            },
            ...{
              supplier_id: this.report.supplier
                ? this.report.supplier.id
                : null,
            },
            ...{
              fieldsToSearch: [
                {
                  field: "im.product_type",
                  value: this.report.product_type,
                },
                {
                  field: "im.product_situation",
                  value: this.report.product_situation,
                },
                {
                  field: "op.movement_type_id",
                  value: this.report.movement_type,
                },
              ],
            },
          },
          {
            headers: { token: this.token },
          }
        )
        .then((response) => {
          if ("pdf_error" in response.body) {
            this.snackbar.color = "gray";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);
            return;
          }
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);
            return;
          }

          var date = new Date();
          var ms = date.getMilliseconds();
          var stringDate =
            date.getFullYear() + "_" + date.getMonth() + "_" + date.getDate();

          let a = document.createElement("a");
          a.href = "data:application/pdf;base64," + response.body.response;
          a.download = "EXTRATO_" + stringDate + "_" + ms + ".pdf";
          a.click();
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        })
        .finally(() => {});
    },
    goToOperation(operation) {
      let routeData = this.$router.resolve({
        name: "ViewOperation",
        params: {
          id: operation.operation_id,
        },
      });

      window.open(routeData.href, "_blank");
    },
    pageChange() {
      this.getData();
    },
    getMovementTypes() {
      this.$http
        .get(this.minamelServices.getUrl() + "movement-types/get", {
          params: {},
          headers: { token: this.token },
        })
        .then((response) => {
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);

            return;
          }

          this.movement_types = response.body.data;

          if (this.search.onSearch) {
            this.search.onSearch = !this.search.onSearch;
          }
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        });
    },
    getMovements() {
      if (!this.report.from) {
        this.hasContent = false;
        return;
      }

      if (!this.report.to) {
        this.hasContent = false;
        return;
      }

      const supplier = this.report.supplier;

      if (supplier.aggregate_name && supplier.aggregate_name != supplier.name) {
        this.snackbar.color = "error";
        this.snackbar.message = `Este fornecedor está inserido no agregado ${supplier.aggregate_name}`;
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      console.log(this.report.supplier);

      this.getData();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    doRequest({ endpoint, method, dataToSend }) {
      const url = this.minamelServices.getUrl() + endpoint;

      switch (method) {
        case "GET":
          return this.$http.get(url, dataToSend);
        case "POST":
          return this.$http.post(url, dataToSend);
        case "PUT":
          return this.$http.put(url, dataToSend);
        case "DELETE":
          return this.$http.delete(url, dataToSend);
      }
    },
    filter() {
      this.getData();
    },
    clearFilters() {
      this.report.supplier = null;
      this.report.movement_type = "";
      this.report.from = "";
      this.report.to = "";
      this.report.product_type = "";
      this.hasContent = false;
    },
    formatAmount(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    loadTable(status) {
      this.table.loading = status;

      if (this.search.onSearch) {
        this.table.totalPages = 1;
      }
    },
    searchData() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.search.onSearch = true;
        this.getData();
      }, 800);
    },
    getData() {
      if (this.table.loading) {
        return;
      }

      const { sortBy, descending } = this.table.pagination;
      let itensToSearch = [];

      this.loadTable(true);
      this.table.disabled = false;
      this.table.items = [];

      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        if (this.search.fieldToSearch[i].value) {
          itensToSearch.push({
            field: this.search.fieldToSearch[i].field,
            value: this.search.fieldToSearch[i].value,
          });
        }
      }

      const params = {
        sort: sortBy,
        orientation: descending == null ? "" : !descending ? "ASC" : "DESC",
        page: this.table.currentPage,
        rowsPerPage: this.table.rowsPerPage,
        term: this.search.term,
        fieldsToSearch: itensToSearch.length <= 0 ? "" : itensToSearch,
      };

      this.$http
        .get(
          this.minamelServices.getUrl() +
            "inventory-movements/get-balance-by-period",
          {
            params: {
              movement_type_id: this.report.movement_type,
              product_type: this.report.product_type,
              product_situation: this.report.product_situation,
              supplier_id: this.report.supplier
                ? this.report.supplier.id
                : null,
              from: this.report.from,
              to: this.report.to,
            },
            headers: { token: this.token },
          }
        )
        .then((response) => {
          if (response.body.error) {
            this.snackbar.color = "error";
            this.snackbar.message = response.body.message;
            this.$root.$SnackBar.show(this.snackbar);

            return;
          }

          this.reportBalance = response.body.data[0];

          this.$http
            .get(this.minamelServices.getUrl() + "inventory-movements/get", {
              params: {
                ...params,
                ...{
                  dateFilter: {
                    initial_date: this.report.from,
                    final_date: this.report.to,
                  },
                },
                ...{
                  supplier_id: this.report.supplier
                    ? this.report.supplier.id
                    : null,
                },
                ...{
                  hasDiscount: this.report.discount
                    ? this.report.discount
                    : null,
                },
                ...{
                  fieldsToSearch: [
                    {
                      field: "im.product_type",
                      value: this.report.product_type,
                    },
                    {
                      field: "im.product_situation",
                      value: this.report.product_situation,
                    },
                    {
                      field: "op.movement_type_id",
                      value: this.report.movement_type,
                    },
                  ],
                },
              },
              headers: { token: this.token },
            })
            .then((response) => {
              if (response.body.error) {
                this.snackbar.color = "error";
                this.snackbar.message = response.body.message;
                this.$root.$SnackBar.show(this.snackbar);

                return;
              }

              this.table.items = response.body.data;

              this.hasContent = this.table.items.length > 0;

              this.table.totalItems = response.body.total_records;

              if (this.search.onSearch) {
                this.search.onSearch = !this.search.onSearch;
              }

              this.table.totalPages = Math.ceil(
                this.table.totalItems / this.table.rowsPerPage
              );
            })
            .catch(() => {
              this.snackbar.color = "error";
              this.snackbar.message = this.CONSTANTS.texts.errors.connection;
              this.$root.$SnackBar.show(this.snackbar);
            })
            .finally(() => {
              this.$forceUpdate();
              this.loadTable(false);

              if (!this.hasContent) {
                this.snackbar.color = "gray";
                this.snackbar.message = "Nenhuma movimentação encontrada.";
                this.$root.$SnackBar.show(this.snackbar);
              }
            });
        })
        .catch(() => {
          this.snackbar.color = "error";
          this.snackbar.message = this.CONSTANTS.texts.errors.connection;
          this.$root.$SnackBar.show(this.snackbar);
        })
        .finally(() => {
          this.loadTable(false);
        });
    },
    back() {},
    searchFocus() {
      this.$refs.search.focus();
    },
  },
  watch: {
    term(val) {
      if (!val) return;

      if (this.isLoading) return;

      this.isLoading = true;

      this.doRequest({
        endpoint: "suppliers/get",
        method: "GET",
        dataToSend: {
          params: {
            term: val,
            active: 1,
          },
          headers: { token: this.token },
        },
      })
        .then((response) => {
          this.suppliers = [...response.body.data, this.report.supplier];
        })
        .catch((err) => {
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    "report.supplier": function (value) {
      console.log("value", value);
    },
    "table.rowsPerPage": {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      on: null,
      userData: {},
      systemId: null,
      systemData: null,
      token: null,
      hideProductType: false,
      hideProductSituation: false,
      situationNames: {
        V: "Vazio",
        C: "Cheio",
      },
      reportBalance: {
        AV_INITIAL: 0,
        BV_INITIAL: 0,
        AC_INITIAL: 0,
        BC_INITIAL: 0,
        AV_FINAL: 0,
        BV_FINAL: 0,
        AC_FINAL: 0,
        BC_FINAL: 0,
      },
      hasContent: false,
      operationIndex: [],
      report: {
        supplier: "",
        movement_type: "",
        from: moment().subtract(1, "days").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        product_type: "",
        product_situation: "",
        discount: "",
      },
      types: {
        headers: [
          {
            text: "Data",
            align: "left",
            value: "movement_date",
          },
          {
            text: "Nº Documento",
            align: "left",
            value: "operation_id",
          },
          {
            text: "Operação",
            align: "left",
            value: "operation_name",
          },
          {
            text: "Fornecedor",
            align: "left",
            value: "supplier_name",
          },
          {
            text: "Tipo de Produto",
            align: "left",
            value: "product_type",
          },
          {
            text: "Situação do Produto",
            align: "left",
            value: "situation",
          },
          {
            text: "Débitos",
            align: "left",
            value: "debit",
          },
          {
            text: "Descontos",
            align: "left",
            value: "discount",
          },
          {
            text: "Quantidade",
            align: "left",
            value: "amount",
          },
        ],
        items: [],
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
      filters: true,
      suppliers: [],
      operations: [],
      movement_types: [],
      product_types: [
        {
          id: "A",
        },
        {
          id: "B",
        },
      ],
      product_situations: [
        {
          id: "C",
          text: "Cheio",
        },
        {
          id: "V",
          text: "Vazio",
        },
      ],
      discounts: [
        {
          id: "S",
          text: "Sim",
        },
        {
          id: "N",
          text: "Não",
        },
      ],
      isOpen: [],
      isLoading: false,
      term: "",
      search: {
        checkAll: true,
        onSearch: false,
        term: "",
        fieldToSearch: [
          {
            description: "Tipo de Movimentação",
            field: "im.type",
            value: "",
            options: [
              { id: "1", text: "Entrada" },
              { id: "2", text: "Saída" },
            ],
          },
        ],
      },
      table: {
        disabled: false,
        loading: false,
        totalPagesVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,
        currentPage: 1,
        totalPages: 1,
        rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
        rowPerPageItems: this.CONSTANTS.arrays.table.itensToView,
        pagination: {
          rowsPerPage: 0,
          sortBy: ["B1_DESC"],
          descending: false,
          page: 1,
          totalItems: 0,
        },
        totalItems: 0,
      },
    };
  },
};
</script>

<style scoped>
.inventory_movements_container {
  padding: 4px 10px 18px;
}

.inventory_movements_card {
  padding: 8px;
}

.inventory_movements_card > .v-card__text {
  margin-top: 10px;
}
</style>

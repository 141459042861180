<template>
  <v-card-actions class="pa-3">
    <v-btn @click="addItemOnStart" color="lightgrey" text outlined>
      + Adicionar item no início
    </v-btn>

    <v-spacer />

    <v-btn style="color: white" color="green" @click="saveForm"> Salvar </v-btn>

    <v-btn color="error" text :to="`/documents/forms`"> Cancelar </v-btn>
  </v-card-actions>
</template>

<script>
import FormStore from "../../store/FormStore";
export default {
  name: "FormRegisterActions",
  data() {
    return {
      formStore: new FormStore(),
    };
  },
  methods: {
    saveForm() {
      this.$emit("saveForm");
    },
    addItemOnStart() {
      this.$emit("addItemOnStart");
    },
  },
};
</script>

<style></style>

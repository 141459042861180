import Vue from "@/main";

class NotificationHelper {
    /**
         * Usado na construcao dos erros no sistema para todas as telas.
         * trata os erros recebidos notificando-os com uma snackbar
         * @param {Throwable} error
         * @param {String} defaultMessage
         *
         * @return {void}
         */
    static async  defaultCatchError (
        error,
        context,
        defaultMessage = "Ocorreu um erro desconhecido"
    ) {
        if (!error) {
            return "";
        }

        this.logErrorInDevelopmentMode(error);

        const message = await this.getExceptionResponseMessage(error) || defaultMessage;

        this.showToast({ message, status: "error", context });
    }

    static showToast ({ message, status,context }) {
        if (!status) {
            status = "success";
        }

        (Vue || context).$swal({
            text: message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            // confirmButtonClass: "swal2-confirm__close",
            timer: 12000,
            // confirmButtonText: "X",
            type: status,
        });
    }

    static getExceptionResponseMessage (error) {
        try {
            const dataError = error.response;

            if (error.data && error.data.message) {
                return error.data.message;
            }

            if (!dataError) {
                return "Conexão não estabelecida";
            }

            if (dataError.status == 401) {
                return "Usuário sem permissão para essa operação, realize login e tente novamente.";
            }

            if (error.request.responseType == "arraybuffer") {
                const bufferToStringResponse = this.arrayBufferResponseToJson(dataError);

                return bufferToStringResponse.message;
            }

            if (!dataError.data) {
                return;
            }

            const decodedData = JSON.parse(dataError.data);

            return decodedData.message;
        // eslint-disable-next-line id-blacklist
        } catch (e) {
            console.error(e);

            return;
        }
    }

    static logErrorInDevelopmentMode (error) {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV !== "production") {
            console.error(error);
        }
    }

    static async blobToJson(data) {
        let blob = await this.readBlob(data);

        if (!blob) {
            return null;
        }

        return JSON.parse(blob);
    }

    static readBlob(data) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                
                fileReader.onload = function() {
                    resolve(this.result);
                };
                
                fileReader.readAsText(data);
            } catch (error) {
                reject(error);
            }

        });
    }

    static arrayBufferResponseToJson (data) {
        const bufferToStringResponse = decodeURIComponent(
            String.fromCharCode.apply(
                null,
                Array.prototype.slice.apply(new Uint8Array(data))
            )
        );

        return JSON.parse(bufferToStringResponse);
    }
}

export default NotificationHelper;

<template>
  <default-list-layout>
    <template v-slot:title>
      <h2>Variáveis do sistema</h2>
    </template>

    <template v-slot:actions>
      <v-col xs="7" md="4">
        <v-text-field
          v-model="table.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>
      <v-col class="text-xs-right">
        <v-btn color="primary" large to="system-variables/register"
          >+ Incluir</v-btn
        >
      </v-col>
    </template>

    <template v-slot:content>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :loading="table.loading"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ getTypeName(item.type) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn :to="`/system-variables/edit/${item.id}`" icon v-on="on">
                <v-icon color="#707070">edit</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="remove(item.id)" icon v-on="on">
                <v-icon color="#707070">clear</v-icon>
              </v-btn>
            </template>
            <span>Excluir</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>

    <template v-slot:pagination>
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </template>
  </default-list-layout>
</template>
<script>
import DefaultListLayout from "@/shared/components/layout/DefaultListLayout.vue";
export default {
  components: { DefaultListLayout },
  name: "SystemVariables",
  data() {
    return {
      table: {
        headers: [
          {
            text: "ID",
            align: "left",
            sortable: false,
            value: "id",
          },
          {
            text: "Tipo",
            align: "left",
            value: "type",
          },
          {
            text: "Valor",
            align: "left",
            value: "value",
          },
          {
            text: "",
            align: "right",
            sortable: false,
            value: "actions",
          },
        ],
        items: [],
        term: "",
        loading: false,
        disabled: false,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,

        pagination: {
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          perPageItems: this.CONSTANTS.arrays.table.itensToView,
          page: 1,
          total: 0,
          pages: 0,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.table.term,
        };
        const res = await this.minamelServices
          .SystemVariableService()
          .get(params);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.SystemVariableService().delete(id);

      if (res) {
        this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    getTypeName(value) {
      const data = this.CONSTANTS.standardTypesHoneyRecord.find((item) => {
        return item.value === value;
      });

      return data.name.toUpperCase();
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>

<template>
  <v-dialog v-model="value" scrollable max-width="300px">
    <v-card>
      <v-card-title class="font-weight-bold">
        Selecione uma variável
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="height: 300px">
        <v-radio-group class="text--primary" v-model="selectedVariable" column>
          <v-radio
            v-for="(item, i) of CONSTANTS.formVariables"
            :key="i"
            :label="item.name"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary darken-1"
          text
          @click="selectVariableDialog = false"
        >
          Cancelar
        </v-btn>

        <v-btn color="primary" @click="onSelectVariable"> Confirmar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelManagementMixin from "@/helpers/ModelManagementMixin";

export default {
  name: "ItemVariableDialog",
  mixins: [ModelManagementMixin],
  data() {
    return {
      selectedVariable: "",
    };
  },
  methods: {
    onSelectVariable() {
      this.$emit("onSelectVariable", this.selectedVariable);
    },
  },
};
</script>

<style></style>

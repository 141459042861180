<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-row row wrap justify-start align-center gr>
    <v-col md="6">
      <v-text-field
        v-model="item.text"
        label="Texto"
        required
        :prepend-icon="
          fieldType === 'CHECKBOX' ? 'check_box' : 'radio_button_checked'
        "
      ></v-text-field>
    </v-col>
    <v-col md="2">
      <v-text-field
        v-model="item.weight"
        type="number"
        label="Peso"
        required
      ></v-text-field>
    </v-col>
    <v-col md="3">
      <v-select
        v-model="item.in_accordance"
        label="Conforme"
        :items="inAccordanceItems"
      ></v-select>
    </v-col>
    <v-col md1>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="removeFieldItem"
            style="margin-top: -15px !important"
          >
            <v-icon color="primary">clear</v-icon>
          </v-btn>
        </template>
        <span>Remover item</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
import { bus } from "@/main";

export default {
  name: "FieldItemRegister",
  props: {
    fieldType: { type: String },
    item: { type: Object },
    field: { type: Object },
  },
  data() {
    return {
      inAccordanceItems: [
        {
          text: "SIM",
          value: 1,
        },
        {
          text: "Não",
          value: 0,
        },
        {
          text: "Não aplicável",
          value: 2,
        },
      ],
    };
  },
  methods: {
    removeFieldItem() {
      bus.$emit("removeFieldItemDocument", {
        id: this.item.id,
        field: this.field,
      });
    },
  },
};
</script>

<template>
  <v-col>
    <v-tooltip transition="fab-transition" top
      >]
      <template v-slot:activator="{ on }">
        <v-text-field
          :on="on"
          style="margin-top: 15px"
          :value="value"
          :rules="[verify]"
          :label="newLabel"
          :placeholder="placeholder"
          :class="required ? 'required' : ''"
        />
      </template>
      <span
        v-if="
          minamelServices.LatLngService().checkLatLngType(value, { typeLatLng })
        "
      >
        <ul
          v-for="lat in minamelServices
            .LatLngService()
            .checkLatLngType(value, { typeLatLng }).extra"
          :key="lat.type"
        >
          <li>{{ `${lat.type}: ${lat.newValue}` }}</li>
        </ul>
      </span>
      <span v-else>
        {{ verify(value) }}
      </span>
    </v-tooltip>
  </v-col>
</template>

<script>
export default {
  name: "LatLngComponent",
  data: () => ({
    newLabel: "Latitude",
  }),
  props: ["value", "typeLatLng", "placeholder", "required"],
  mounted() {
    let computedValue = this.minamelServices
      .LatLngService()
      .checkLatLngType(this.value, { typeLatLng: this.typeLatLng });
    let isLatLng = this.typeLatLng == "lat" ? "Latitude" : "Longitude";
    this.newLabel = computedValue
      ? `${isLatLng} (${computedValue.type})`
      : `${isLatLng}`;
  },
  methods: {
    verify(value) {
      let computedValue = this.minamelServices
        .LatLngService()
        .checkLatLngType(value, { typeLatLng: this.typeLatLng });
      let isLatLng = this.typeLatLng == "lat" ? "Latitude" : "Longitude";
      this.newLabel = computedValue
        ? `${isLatLng} (${computedValue.type})`
        : `${isLatLng}`;
      this.$emit("updateValue", value);
      return computedValue
        ? true
        : `Informe uma ${
            this.typeLatLng == "lat" ? "latitude" : "longitude"
          } válida.`;
    },
  },
};
</script>

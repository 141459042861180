<template>
  <v-file-input
    ref="file_input"
    v-model="files"
    :accept="acceptedFormats"
    :rules="required ? [requiredIfIsToSave] : []"
    type="file"
    class="image-dialog-file-input"
    prepend-icon="none"
    @change="onFileChange"
  />
</template>

<script>
import ModelManagementMixin from "@/shared/mixins/ModelManagementMixin";
// import FileValidator from "../domain/FileValidator";

export default {
  name: "ImageUploadInput",
  mixins: [ModelManagementMixin],
  props: {
    required: Boolean,
  },
  data() {
    return {
      files: null,
      acceptedFormats: "image/png, image/jpeg",
    };
  },
  methods: {
    requiredIfIsToSave(v) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!v) {
        return true;
      }

      if (
        this.$route.params &&
        this.$route.params.id &&
        this.$route.params.id != "new"
      ) {
        return true;
      }

      return "Campo Obrigatório";
    },
    selectFile() {
      this.$refs.file_input.$refs.input.click();
    },
    clear() {
      this.files = "";
    },
    onFileChange(file) {
      try {
        const oldFile = { ...this.selectedFile };

        if (this.selectedFile && !file) {
          this.files = oldFile;
        }

        // FileValidator.validate(file);

        this.selectedFile = file;

        this.$emit("input", this.selectedFile);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.image-dialog-file-input {
  margin: 0px;
  padding: 0px;
  margin-top: -38px !important;

  & > .v-input__control {
    flex-direction: column-reverse !important;

    & > .v-input__slot {
      transform: translate(0px, 8px);

      & > .v-text-field__slot {
        height: 1px !important;
        min-height: 1px !important;
      }

      &:before {
        border-color: transparent !important;
      }
    }

    & > .v-text-field__details {
      transform: translate(0px, 32px);
    }
  }

  &.v-input--is-dirty {
    & > .v-input__control {
      display: none !important;
    }
  }
}
</style>

<template>
  <v-hover v-slot="{ hover }">
    <v-container
      fluid
      class="w-100"
    >
      <v-row>
        <v-col
          xs="12"
          class="
            text-center
            d-flex
            flex-column
            pa-12
            w-100
            cursor-pointer"
          :class="hover ? 'bg-primary-300' : 'bg-primary-100'"
          @click="onClick"
        >
          <span
            class="flex-item text-subtitle font-weight-bold my-4"
            :class="hover ? '' : 'text--secondary'"
          >
            Clique aqui para adicionar uma imagem (Máximo 2mb)
          </span>

          <v-icon
            class="flex-item mx-auto"
            :class="hover ? 'black--text' : 'text--secondary'"
            x-large
          >
            mdi-paperclip
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-hover>
</template>

<script>
export default {
    name: "ImageUploadButtonNew",
    methods: {
        onClick () {
            this.$emit("click");
        }
    }
};
</script>

<style>
.bg-primary-300 {
    background-color: #eeeeee;
}

.bg-primary-100 {
    background-color: #fafafa;
}
</style>

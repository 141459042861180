/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {UserGroupService}
 */
export default class UserGroupService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'user-groups/';

    getUserGroup () {
    	this.path = "user-groups/get";
    	return this;
    }

    createUserGroup (id = null) {
    	if (id) {
    		this.path = "user-groups/update";
    	} else {
    		this.path = "user-groups/create";
    	}
    	return this;
    }
    updateUserGroup () {
    	this.path = "user-groups/update";
    	return this;
    }

    updateUserGroupSystems () {
    	this.path = "user-groups/update-user-groups-systems";
    	return this;
    }
    deleteUserGroup () {
    	this.path = "user-groups/delete";
    	return this;
    }

    getAll (params) {
    	return super.get(`/?${this.queryString(params)}`);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    // access() {
    //     this.path = "user-groups/access";
    //     return this;
    // }

    // group() {
    //     this.path = "user-groups/group";
    //     return this;
    // }

    // permission() {
    //     this.path = "user-groups/permission";
    //     return this;
    // }
}

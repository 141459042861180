import Rest from './base/Rest';

/**
 * Lida com todos os servicos para buscar e manipular globais no banco de dados
 * @typedef {GlobalService}
 */
export default class GlobalService extends Rest {
	/**
	 * @type {String}
	 */
    static resource = 'global/';

    getGlobal() {
        this.path = "global/get";
        return this;
    }
}
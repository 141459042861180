<template>
    <v-card class="card">
        <span class="title">{{ title }}</span>

        <div class="data-info">
            <span class="data-text">{{ count }}</span>
            <span class="data-list" @click="navigateTo">Listar {{ title.split(" ")[0] }}</span>
        </div>

        <div class="divider"></div>
    </v-card>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Totalizer",
    props: {
        title: String,
        count: Number,
        route: String,
    },
    methods: {
        navigateTo() {
            if (this.route) window.location.href = this.route;
        }
    }
}
</script>

<style>
.card {
    padding: 20px;
    width: 100%;
    background-color: white;
}

.title {
    color: rgb(45, 92, 223);
    font-size: xx-large;
    font-weight: bold;
}

.data-info {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 5px 0;
}

.data-text {
    color: black;
    font-size: large;
    font-weight: bold;
}

.data-list {
    color: gray;
    font-size: small;
    font-weight: 300;
}

.data-list:hover {
    cursor: pointer;
    text-decoration: underline;
}

.divider {
    background-color: rgb(226, 123, 53);
    height: 3px;
    width: 100%;
    border-radius: 10px;
}
</style>
<template>
  <v-card :class="!projectId ? 'elevation-0 ma-4' : 'elevation-0 ma-0 '">
    <v-toolbar dark color="primary" v-if="projectId && importToProject">
      <v-btn icon dark @click="closeDialog()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>Importar tratamento de doenças</v-toolbar-title>
    </v-toolbar>

    <v-row
      row
      justify-space-between
      :class="importToProject ? 'mt-4 ml-4 mr-4' : projectId ? 'ma-0 mt-4' : ''"
    >
      <v-col xs="12" sm="12" md="5">
        <v-text-field
          v-model="table.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>
      <v-col xs="3" md="2" class="text-xs-left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              large
              color="normal"
              v-on="on"
              @click="toggleFilters"
              v-shortkey="['ctrl', 'f']"
            >
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex text-xs-right" style="justify-content: end;">
        <v-btn
          v-if="!importToProject"
          color="primary"
          large
          :to="!projectId ? 'disease-treatment-records/register' : ''"
          @click="importTo"
          >+ {{ projectId && !importToProject ? "Importar" : "Incluir" }}</v-btn
        >
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-card
        v-show="expandFilters"
        class="elevation-1 pa-2"
        :class="importToProject ? 'ml-4 mr-4 mb-4' : 'mb-4'"
      >
        <v-row row>
          <v-col xs="12">
            <v-label>Selecione os campos para fazer a busca</v-label>
          </v-col>
        </v-row>

        <v-row row wrap>
          <v-col xs="12" md="4" pa-3>
            <v-autocomplete
              clearable
              chips
              deletable-chips
              color="primary"
              :items="suppliers"
              v-model="filter.supplier"
              item-value="id"
              item-text="name"
              label="Produtor"
              @change="getData"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
                <span class="font-weight-bold">
                  - COD.
                  {{ data.item.id }}</span
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col xs="12" md="3" pa-3>
            <v-autocomplete
              clearable
              chips
              deletable-chips
              color="primary"
              :items="apiaries"
              v-model="filter.apiary"
              item-value="import_key"
              item-text="name"
              label="Apiário"
              @change="getData"
            ></v-autocomplete>
          </v-col>
          <v-col xs="12" md="3" pa-3 pt-4>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              full-width
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="$functions.formatDate(filter.date)"
                  clearable
                  @click:clear="filter.date = null"
                  label="Data"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="filter.date"
                @change="datePickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>

    <v-data-table
      v-model="table.selected"
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1 scroll"
      :class="!projectId ? 'ma-0' : importToProject ? 'ml-4 mr-4' : ''"
      :show-select="importToProject"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:[`item.supplier`]="{ item }">
        {{ item.supplier ? item.supplier.name : "-" }}
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ $functions.formatDate(item.date) || "-" }}
      </template>

      <template v-slot:[`item.apiary`]="{ item }">
        {{ item.apiary ? item.apiary.name : "-" }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!importToProject"
              :to="`disease-treatment-records/edit/${item.id}`"
              icon
              v-on="on"
            >
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!importToProject"
              @click="remove(item.id)"
              icon
              v-on="on"
            >
              <v-icon color="#707070">clear</v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-xs-center pa-4">
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </div>
    <v-divider v-if="importToProject"></v-divider>
    <v-card-actions v-if="importToProject">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="!table.selected.length"
        @click="importDataToProject"
        >Importar selecionado(s)</v-btn
      >
      <v-btn text color="error" @click="closeDialog">Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { bus } from "../../../../../../src/main";

export default {
  name: "DiseaseTreatmentRecords",
  props: {
    apiaries: { type: Array },
    suppliers: { type: Array },
    projectId: { type: Number, default: null },
    importToProject: { type: Boolean, default: false },
    routeName: { type: String, default: "/field-notes" },
  },
  data() {
    return {
      expandFilters: false,
      filter: {
        supplier: null,
        apiary: null,
        date: null,
      },
      datePickerMenu: false,
      table: {
        selected: [],
        headers: [
          {
            text: "Produtor",
            width: "250px",
            align: "left",
            value: "supplier",
          },
          {
            text: "Apiário",
            width: "200px",
            align: "left",
            value: "apiary",
          },
          {
            text: "Data",
            align: "left",
            value: "date",
            width: "100px",
          },
          {
            text: "Colméias",
            align: "left",
            value: "hives_quantity",
            width: "150px",
          },
          {
            text: "Doenças",
            align: "left",
            value: "diseases",
            width: "150px",
          },
          {
            text: "Remédio",
            align: "left",
            value: "medicine_name",
            width: "150px",
          },
          {
            text: "Origem Remédio",
            align: "left",
            value: "medicine_origin",
            width: "200px",
          },
          {
            text: "Qtd. por colméia",
            align: "left",
            value: "medicine_per_hive",
            width: "200px",
          },
          {
            text: "Concentração",
            align: "left",
            value: "concentration",
            width: "150px",
          },
          {
            text: "Duração do tratamento",
            align: "left",
            value: "treatment_duration",
            width: "250px",
          },
          {
            text: "Aplicador",
            align: "left",
            value: "medicine_applicator",
            width: "150px",
          },
          {
            text: "",
            align: "right",
            value: "actions",
            width: "190px",
          },
        ],
        items: [],
        term: "",
        loading: false,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,

        pagination: {
          perPage: !this.importToProject
            ? this.CONSTANTS.integer.table.numberOfRowPerPage
            : 10,
          rowsPerPage: 10,
          page: 1,
          total: 0,
          pages: 0,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      pagination: {
        sortBy: "date",
        descending: true,
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  created() {
    bus.$on("refreshDiseaseTreatmentRecordList", () => {
      this.getData();
    });
  },
  async mounted() {
    this.table.term =
      this.$functions.getSessionStorageSearchTerm("diseaseTreatment");

    await this.getData();
  },

  methods: {
    async getData() {
      this.$functions.setSessionStorageSearchTerm(
        "diseaseTreatment",
        this.table.term
      );

      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.table.term ? this.table.term : "",
          date: this.filter.date ? this.filter.date : "",
          supplierId: this.filter.supplier ? this.filter.supplier : "",
          apiaryImportKey: this.filter.apiary ? this.filter.apiary : "",
          projectId: this.importToProject
            ? ""
            : this.projectId
            ? this.projectId
            : "",
          exceptProjectId: this.projectId ? this.projectId : "",
          type: this.importToProject
            ? "RECORD"
            : this.projectId
            ? "PROJECT"
            : "RECORD",
        };

        const res = await this.minamelServices
          .DiseaseTreatmentRecordService()
          .get(params);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices
        .DiseaseTreatmentRecordService()
        .delete(id);

      if (res) {
        await this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    async importDataToProject() {
      if (!this.table.selected) {
        return;
      }
      try {
        let data = [];

        this.table.selected.forEach((item) => {
          data.push(item.id);
        });

        await this.minamelServices
          .DiseaseTreatmentRecordService()
          .importToProject(this.projectId, data);

        this.snackbar.color = "success";
        this.snackbar.message = "Dados importados com sucesso";
        this.$root.$SnackBar.show(this.snackbar);

        bus.$emit("refreshDiseaseTreatmentRecordList");

        this.table.selected = [];
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao importar os dados para o projeto";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    importTo() {
      bus.$emit("openDialog", {
        params: {
          componentName: "DiseaseTreatmentRecords",
          importToProject: false,
        },
      });
    },

    closeDialog() {
      bus.$emit("closeDialog", { params: {} });
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
    "filter.date": function () {
      this.getData();
    },
  },
};
</script>

<style>
/* .scroll :-webkit-scrollbar-track:horizontal {
	margin-right: 90px !important;
}
.scroll ::-webkit-scrollbar {
	width: 10px !important;
	margin-right: 90px !important;
}
.scroll ::-webkit-scrollbar-thumb {
	background-color: #dfdfdf !important;
	border-radius: 5% !important;
} */
</style>

import 'material-design-icons-iconfont/dist/material-design-icons.css'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "core-js/stable";
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueResource from 'vue-resource';
import Money from 'v-money';
import VueShortKey from 'vue-shortkey';
import VueMeta from 'vue-meta';

import App from './App';
import router from './router';
import vuetify from './plugins/vuetify'
import constants from '../config/constants';

import numeral from './plugins/numeral';
import moment from './plugins/moment';
import download from './plugins/download';
import validate from './plugins/validate';

import "vue-wysiwyg/dist/vueWysiwyg.css";
import wysiwyg from "vue-wysiwyg";
import './utils/filters';
import './utils/directives';

import GlobalFunctions from './utils/functions';

var textTruncate = function(text, length, clamp) {
	clamp = clamp || '...';
	var node = document.createElement('div');
	node.innerHTML = text;
	var content = node.textContent;
	return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('toCurrency',
	/**
	 * Transforma um valor numerico em um valor de String formatado para string
	 *
	 * @param {Number} value
	 * @param {Object} config - valores adicionais de configuracoes que podem ser usados com a funcao toCurrency
	 *
	 * @returns {String}
	 * @throws {Error}
	 */
	(value, config = {}) => {
		const toCurrency = Number(value);

		if (Number.isNaN(toCurrency)) {
			return value;
		}

		const defaultConfigs = {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 2
		};

		// eslint-disable-next-line
		let formatter = new Intl.NumberFormat([], {...defaultConfigs, ...config});

		let formated = formatter.format(toCurrency);

		return formated.replace('R$', '');
	}
);

// Vue.use(VueFroala);

Vue.filter('truncate', textTruncate);

Vue.prototype.CONFIG = process.env;
Vue.prototype.$functions = GlobalFunctions;
Vue.prototype.CONSTANTS = constants;
Vue.prototype.ESTADOS_CIDADES = require('../src/assets/estados_cidades.min.json');
Vue.config.productionTip = false;

Vue.use(VueResource);

Vue.use(VueSweetalert2);
Vue.use(numeral);
Vue.use(moment);
Vue.use(download);
Vue.use(validate);
Vue.use(Money);
Vue.use(VueShortKey);
Vue.use(wysiwyg, {}); // config is optional. more below
Vue.use(VueMeta);

/* eslint-disable no-new */
const vue = new Vue({
	vuetify,
	el: '#app',
	router,
	components: { App },
	template: '<App/>'
});

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
	// `trace` is the component hierarchy trace
	if (msg === ignoreWarnMessage) {
		msg = null;
		vm = null;
		// eslint-disable-next-line no-unused-vars
		trace = null;
	}
};


export const bus = new Vue();


export default vue;
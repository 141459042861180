<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="dialog.open"
    min-width="300"
    width="600"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog.open = toggle(dialog.open)">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ dialog.text }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col xs="12">
            <v-checkbox
              v-model="projectDuplicateSelected"
              label="Config. Documentos"
              :disabled="
                projectDocument && projectDocument.length ? false : true
              "
              value="project_document"
            ></v-checkbox>
            <v-checkbox
              v-model="projectDuplicateSelected"
              label="Datas e Notificações"
              :disabled="projectDates && projectDates.length ? false : true"
              value="project_dates"
            ></v-checkbox>
            <v-checkbox
              v-model="projectDuplicateSelected"
              label="Apiários"
              :disabled="projectApiary && projectApiary.length ? false : true"
              value="project_apiary"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col xs="12" class="d-flex mt-2">
            <v-spacer />
            <v-btn
              color="error"
              text
              @click="dialog.open = toggle(dialog.open)"
            >
              Voltar
            </v-btn>
            <v-btn class="mx-2" dark color="green" @click="save">
              Duplicar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProjectDate",
  props: [
    "registerUsers",
    "project",
    "projectDates",
    "projectApiary",
    "dialog",
    "projectDocument",
  ],
  data: () => ({
    // data
    projectDuplicateSelected: [],
  }),
  async mounted() {},
  methods: {
    close() {
      this.innerDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async send(validate) {
      // eslint-disable-next-line vue/no-mutating-props
      if (!validate) return (this.dialog.open = this.toggle(this.dialog.open));

      let newProject = Object.assign({}, this.project);
      newProject.name = `${newProject.name} - Cópia`;
      delete newProject.apiary_group_name;
      delete newProject.apiaryGroup;
      let arrayUsers = newProject.users;
      delete newProject.users;
      newProject.id = null;
      newProject.updated_at = null;

      let projectObj = {
        project: newProject,
        users: arrayUsers,
        dates: [],
        apiary: [],
      };

      if (
        this.projectDuplicateSelected.length &&
        this.projectDuplicateSelected.length > 0
      ) {
        if (this.projectDuplicateSelected.includes("project_dates")) {
          projectObj.dates = this.projectDates;
        }
        if (this.projectDuplicateSelected.includes("project_apiary")) {
          projectObj.apiary = [...this.projectApiary];
          projectObj.apiary = projectObj.apiary.map((ap) => {
            ap.id = null;
            return ap;
          });
        }
      }

      await this.minamelServices
        .ProjectService()
        .createProject()
        .create(projectObj)
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this, response)) {
            this.$router.push({
              name: "EditProject",
              params: {
                id: response.insertId,
              },
            });
            window.location.reload();
            // this.$forceUpdate();
            // this.$router.go({
            // 	name: "EditProject",
            // 	params: {
            // 		id: response.insertId
            // 	},
            // 	force: true
            // });
            // this.$router.push({ name: "Project" });
          }
        })
        .catch((error) => {
          this.minamelServices.TreatRequest().error(this, error);

          // this.$router.push({ name: "Project" });
        });
    },
    save() {
      return this.$emit("saveProjectBefore", true);
    },
    toggle(value) {
      if (value) {
        return false;
      } else {
        return true;
      }
    },
    alertConfirm(ask) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },
  },
};
</script>

<template>
	<v-card
		class="elevation-0"
		:class="!projectId ? 'ma-2' : 'ma-0 project-margin-adjustment'"
	>
		<v-form ref="form">
			<v-row row wrap>
				<v-col xs="12" md="12" pa-3>
					<span class="required grey--text"
						>* Preenchimento obrigatório.</span
					>
				</v-col>

				<v-col xs="12" md="4" pl-3 pr-3 pt-2>
					<v-autocomplete
						:cache-items="true"
						chips
						:deletable-chips="true"
						:rules="[formRules.required]"
						v-model="register.supplier_id"
						:items="suppliers"
						item-text="name"
						item-value="id"
						label="Produtor"
						class="required"
						@change="onChangeSupplier()"
					>
						<template slot="item" slot-scope="data">
							{{ data.item.name }}
							<span class="font-weight-bold">
								- COD.
								{{ data.item.id }}</span
							>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col xs="12" md="4" pl-3 pr-3 pt-2 v-if="!register.there_is_not">
					<v-select
						chips
						:deletable-chips="true"
						:rules="[formRules.validApiary, formRules.required]"
						v-model="register.apiary_import_key"
						:items="selectableApiaries"
						item-text="name"
						item-value="import_key"
						label="Apiário"
						class="required"
					/>
				</v-col>
				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-menu
						v-model="datePickerMenu"
						:close-on-content-click="false"
						full-width
						max-width="290"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								:rules="[formRules.required]"
								:value="$functions.formatDate(register.date)"
								clearable
								label="Data"
								readonly
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							locale="pt-br"
							v-model="register.date"
							@change="datePickerMenu = false"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						type="number"
						:rules="[formRules.required]"
						v-model="register.hives_quantity"
						label="Nº de colméias"
						class="required"
					/>
				</v-col>
				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						:rules="[formRules.required]"
						v-model="register.diseases"
						label="Doenças"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						:rules="[formRules.required]"
						v-model="register.medicine_name"
						label="Remédio aplicado"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						:rules="[formRules.required]"
						v-model="register.medicine_origin"
						label="Origem do remédio"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						type="number"
						:rules="[formRules.required]"
						v-model="register.medicine_per_hive"
						label="Quantidade por colméia"
						class="required"
					/>
				</v-col>
				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						:rules="[formRules.required]"
						v-model="register.concentration"
						label="Concentração"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						:rules="[formRules.required]"
						v-model="register.treatment_duration"
						label="Duração do tratamento"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 v-if="!register.there_is_not">
					<v-text-field
						:rules="[formRules.required]"
						v-model="register.medicine_applicator"
						label="Aplicador"
						class="required"
					/>
				</v-col>
			</v-row>

			<v-divider></v-divider>

			<v-card-actions class="pa-3">
				<v-spacer></v-spacer>
				<v-btn style="color: white" color="green" @click="save"
					>Salvar</v-btn
				>
				<v-btn
					color="error"
					flat
					:to="`${routeName}/disease-treatment-records`"
					>Cancelar</v-btn
				>
			</v-card-actions>
		</v-form>
	</v-card>
</template>
<script>
import jwt from "vue-jwt-decode";

export default {
	name: "DiseaseTreatmentRecordsRegister",
	props: {
		apiaries: { type: Array },
		suppliers: { type: Array },
		projectId: { type: Number, default: null },
		routeName: { type: String, default: "/field-notes" }
	},
	data () {
		return {
			userData: null,
			formRules: {
				required: (value) => !!value || "Campo obrigatório",
				validApiary: () =>
					!!this.register.supplier_id || "Selecione um produtor"
			},
			thereiIsNot: false,
			datePickerMenu: false,
			register: {
				there_is_not: 0
			},
			snackbar: {
				message: "",
				visible: false,
				color: "success",
				timeout: 6000,
				x: null,
				y: "bottom",
				multiLine: false
			},
			mutableApiaries: this.apiaries
		};
	},
	computed: {
		selectableApiaries() {
			const filtered = this.apiaries.filter((obj) => {
				return this.register.supplier_id === obj.supplier_id && obj.projectId == null;
			});

			if (filtered) {
				return filtered;
			}

			return [];
		}
	},
	async mounted () {
		this.userData = (await sessionStorage.token)
			? jwt.decode(sessionStorage.token).userData
			: null;
		this.mutableApiaries = this.apiaries;

		this.id = this.projectId
			? this.$route.params.childrenId
			: this.$route.params.id;
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		async getData () {
			const response = await this.minamelServices
				.DiseaseTreatmentRecordService()
				.show(this.id);

			this.register = response;
			this.register.date = this.register.date.split("T")[0];
		},

		async save () {
			let formValidate = this.$refs.form.validate();

			if (!formValidate) {
				this.snackbar.color = "warning";
				this.snackbar.message = "Verifique os campos em destaque.";
				this.$root.$SnackBar.show(this.snackbar);
				return;
			}

			if (this.register.id) {
				this.update();
				return;
			}

			this.create();
		},

		onChangeSupplier () {
			if (!this.register.supplier_id) {
				this.mutableApiaries = this.apiaries;
				this.register.apiary_import_key = null;
				return;
			}

			this.mutableApiaries = [];
		},

		async create () {
			this.register.user_id = this.userData.id;
			this.register.type = "RECORD";

			try {
				const response = await this.minamelServices
					.DiseaseTreatmentRecordService()
					.create(this.register);

				if (response) {
					this.snackbar.color = "success";
					this.snackbar.message =
						"Tratamento de doença incluído com sucesso!";
					this.$root.$SnackBar.show(this.snackbar);

					this.$router.push(
						`${this.routeName}/disease-treatment-records`
					);
				}
			} catch (err) {
				this.snackbar.color = "error";
				this.snackbar.message =
					"Ocorreu um erro ao realizar o cadastro.";
				this.$root.$SnackBar.show(this.snackbar);
			}
		},

		async update () {
			try {
				const response = await this.minamelServices
					.DiseaseTreatmentRecordService()
					.update(this.id, this.register);

				if (response) {
					this.snackbar.color = "success";
					this.snackbar.message =
						"Tratamento de doença alterado com sucesso!";
					this.$root.$SnackBar.show(this.snackbar);

					this.$router.push(
						`${this.routeName}/disease-treatment-records`
					);
				}
			} catch (err) {
				this.snackbar.color = "error";
				this.snackbar.message =
					"Ocorreu um erro ao atualizar o cadastro.";
				this.$root.$SnackBar.show(this.snackbar);
			}
		}
	},

	watch: {
		"register.there_is_not": {
			handler (val) {
				if (val) {
					this.register.apiary_import_key = null;
					this.register.date = null;
					this.register.hives_quantity = null;
					this.register.diseases = null;
					this.register.medicine_name = null;
					this.register.medicine_origin = null;
					this.register.medicine_per_hive = null;
					this.register.concentration = null;
					this.register.treatment_duration = null;
					this.register.medicine_applicator = null;
				}
			}
		}
	}
};
</script>

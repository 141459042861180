<template>
  <v-card elevation="0" class="ma-4">
    <v-row row justify-space-between>
      <v-col xs="7" md="4">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>

      <!-- <v-col  xs="3"  md="2"  class="text-xs-left">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn large color="normal" v-on="on" @click="toggleFilters" v-shortkey="['ctrl', 'f']">
							<v-icon>filter_list</v-icon>
						</v-btn>
					</template>
					<span>Clique para selecionar os filtros</span>
				</v-tooltip>
			</v-col> -->

      <v-col class="text-xs-right">
        <v-btn color="primary" large @click="addItem">+ Incluir</v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row row wrap v-show="expandFilters">
        <v-col md="12">
          <v-card class="elevation-1 pa-3 mb-4">
            <v-row row>
              <v-col xs="12">
                <v-label>Selecione os campos para fazer a busca</v-label>
              </v-col>
            </v-row>

            <v-row row wrap>
              <v-col xs="12" md="3" pa-3>
                <v-select
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.active"
                  :items="[
                    { value: '1', text: 'Sim' },
                    { value: '0', text: 'Não' },
                  ]"
                  label="Ativo"
                  @change="getData"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:items="props">
        <tr>
          <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.type }}</td>
          <td class="text-xs-left">{{ props.item.filename }}</td>
          <td class="text-xs-right" style="min-width: 185px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="downloadFile(props.item)">
                  <v-icon color="#707070">download</v-icon>
                </v-btn>
              </template>
              <span>Baixar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="editItem(props.item)" icon v-on="on">
                  <v-icon color="#707070">edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="remove(props.item.id)" icon v-on="on">
                  <v-icon color="#707070">clear</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-xs-center pa-4">
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </div>

    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = !dialog">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ headerText }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form ref="formAttachment" enctype="multpart/form-data">
            <v-container grid-list-md ma-0 pa-0>
              <v-row wrap>
                <v-col md7 lg8 xs="12">
                  <v-text-field
                    v-model="register.name"
                    label="Descrição"
                    rows="1"
                    :rules="[formRules.required]"
                  ></v-text-field>
                </v-col>
                <v-col md="5" lg4 xs="12">
                  <v-text-field
                    v-model="register.type"
                    label="Tipo"
                    rows="1"
                    :rules="[formRules.required]"
                  ></v-text-field>
                </v-col>
                <v-col md="12">
                  <v-text-field
                    label="Selecione..."
                    @click="onPickFile"
                    :disabled="register.id"
                    v-model="register.filename"
                    prepend-icon-i="attach_file"
                    :rules="[formRules.required]"
                    readonly
                  ></v-text-field>
                  <!-- Hidden -->
                  <input
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    accept="*/*"
                    @change="onFilePicked"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 white--text" @click="save">Salvar</v-btn>
          <v-btn color="red darken-1" text @click="dialog = !dialog"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  name: "ProjectAttachments",
  data() {
    return {
      projectId: null,
      dialog: false,
      headerText: "Adicionar anexo",
      expandFilters: false,
      register: {
        id: null,
        name: "",
        date: "",
        date_modal: false,
        expansion: 0,
        file: {},
        file2: null,
      },
      formRules: {
        required: (value) => !!value || "Campo obrigatório.",
        fileSize: (value) =>
          !value ||
          !value.some((file) => file.size > 2e6) < 2e6 ||
          "Avatar size should be less than 2 MB!",
      },
      filter: {
        term: "",
      },
      table: {
        headers: [
          { text: "Descrição", value: "name" },
          { text: "Tipo", value: "name" },
          { text: "Nome do arquivo", value: "name" },
          {
            text: "",
            value: "actions",
            align: "left",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  async mounted() {
    this.projectId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.filter.term ? this.filter.term : "",
          projectId: this.projectId,
        };

        const res = await this.minamelServices
          .ProjectAttachmentService()
          .getAll(params);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async save() {
      let formValidate = this.$refs.formAttachment.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      this.register.projectId = this.$route.params.id;

      if (this.register.id) {
        this.update();
        this.getData();

        return;
      }

      this.create();
    },

    async create() {
      const data = new FormData();

      data.append("file", this.$refs.fileInput.files[0]);
      data.append("values", JSON.stringify(this.register));

      try {
        const response = await this.minamelServices
          .ProjectAttachmentService()
          .store(data);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Arquivo incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.dialog = false;

          this.getData();
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .ProjectAttachmentService()
          .update(this.register.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Arquivo alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.dialog = false;

          this.getData();
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices
        .ProjectAttachmentService()
        .delete(id);

      if (res) {
        this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    addItem() {
      this.register.id = null;
      this.register.name = "";
      this.register.type = "";
      this.register.filename = "";
      this.register.file = {};

      this.dialog = true;
    },

    editItem(item) {
      this.register = item;
      this.dialog = true;
    },

    async downloadFile(item) {
      try {
        let response = await this.minamelServices
          .ProjectAttachmentService()
          .download(item.id);

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", item.filename);

        document.body.appendChild(link);

        link.click();
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Não foi possível fazer o download do anexo";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    onPickFile() {
      this.$refs.fileInput.click();
    },

    onFilePicked(event) {
      const files = event.target.files;

      this.register.filename = files[0].name;

      this.$forceUpdate();
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>

import Rest from './base/Rest';

/**
 * Lida com todos os servicos relacionados ao usuario e seus acessos
 * @typedef {MidiaService}
 */
export default class MidiaService extends Rest {
	/**
	 * @type {String}
	 */
	static resource = 'import/midia/';
}

<template>
    <v-container fluid>
        <v-row row>
            <v-col xs="12" lg12 md="12">
                <v-subheader>
                    <h2>Selecione um Grupo de Apiário/Produtor</h2>
                </v-subheader>
            </v-col>
        </v-row>

        <v-row row justify-space-between>
            <v-col xs="7" md="4">
                <v-text-field v-model="filter.term" ref="search" label="Faça a sua busca" clear-icon="close" clearable
                    append-icon="search" @click:append="getData" @keyup="getData" @keyup.enter="getData" />
            </v-col>

            <v-col xs="3" md="2" class="text-xs-left">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn large color="normal" v-on="on" @click="toggleFilters" v-shortkey="['ctrl', 'f']">
                            <v-icon>filter_list</v-icon>
                        </v-btn>
                    </template>
                    <span>Clique para selecionar os filtros</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-row row wrap v-show="expandFilters">
                <v-col md="12">
                    <v-card class="elevation-1 pa-3 mb-4">
                        <v-row row>
                            <v-col xs="12">
                                <v-label>Selecione os campos para fazer a busca</v-label>
                            </v-col>
                        </v-row>

                        <v-row row wrap>
                            <v-col xs="12" md="3" pa-3>
                                <v-select chips deletable-chips clearable v-model="filter.active" :items="[
                                    { value: '1', text: 'Sim' },
                                    { value: '0', text: 'Não' },
                                ]" label="Ativo" @change="getData" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-expand-transition>

        <v-data-table :headers="table.headers" :items="table.items" :loading="table.loading" class="elevation-1"
            hide-default-footer>
            <template v-slot:[`item.active`]="{ item }">
                <v-chip v-if="item.active == 1" color="green" text-color="white">Ativo</v-chip>
                <v-chip v-else color="#5e636b" text-color="white">Inativo</v-chip>
            </template>

            <template v-slot:[`item.suppliers`]="{ item }">
                <span>{{ item?.suppliers?.length }}</span>
            </template>
            

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn small @click="selectGroup(item)" class="mx-1" icon text v-on="on">
                            <v-icon color="#707070">visibility</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver produtores</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <div>
            <v-card class="pa-3">
                <v-row wrap>
                    <v-col xs="12" md="12" class="text-xs-center">
                        <v-pagination v-model="table.pagination.page" :length="table.pagination.pages"
                            v-bind:disabled="table.disabled" :total-visible="table.pagination.totalVisible"
                            prev-icon="arrow_left" next-icon="arrow_right" primary />
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "SelectGroupSupplier",
    data() {
        return {
            expandFilters: false,
            filter: {
                term: "",
                active: "",
            },
            table: {
                headers: [
                    { text: "Status", value: "active", align: "left" },
                    { text: "Descrição", value: "name" },
                    { text: "Quantidade", value: "suppliers" },
                    {
                        text: "",
                        value: "actions",
                        align: "right",
                        sortable: false,
                    },
                ],
                items: [],
                loading: false,
                // rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
                pagination: {
                    page: 1,
                    perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
                    totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
                },
            },
            snackbar: {
                message: "",
                visible: false,
                color: "success",
                timeout: 6000,
                x: "right",
                y: "top",
                multiLine: false,
            },
        };
    },
    async mounted() {
        this.filter.term =
            this.$functions.getSessionStorageSearchTerm("selectGroup");

        await this.getData();
    },

    methods: {
        selectGroup(group) {
            this.$router.push({
                name: 'Suppliers',
                query: { apiaryGroup: group.id }
            });
        },

        async getData() {
            this.$functions.setSessionStorageSearchTerm(
                "selectGroup",
                this.filter.term
            );

            try {
                this.table.loading = true;

                const params = {
                    page: this.table.pagination.page,
                    perPage: this.table.pagination.perPage,
                    active: this.filter.active ? this.filter.active : "",
                    term: this.filter.term ? this.filter.term : "",
                };

                const res = await this.minamelServices
                    .ApiaryGroupServiceV2()
                    .getAll(params);

                this.table.items = res.data;
                this.table.items.push({
                    id: null,
                    name: 'Todos Sem Grupo',
                    active: 1,
                })
                this.table.pagination.pages = res.meta.last_page;
                this.table.loading = false;
            } catch (err) {
                this.table.loading = false;
            }
        },

        toggleFilters() {
            this.expandFilters = !this.expandFilters;
        },
    },
    watch: {
        "table.pagination.page": function () {
            this.getData();
        },
    },
};
</script>
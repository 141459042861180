<template>
	<v-container grid-list-md fluid>
		<v-row row wrap>
			<v-col xs="12"  sm="6" md="4"  lg="3"  xl="2">
				<v-card class="dashboard-innbarrel-card" @click="goToOperations()">
					<v-card-title>
						<v-icon large left>mdi-barrel</v-icon>
						<span class="title font-weight-light">Pendentes</span>
					</v-card-title>
					<v-card-text style="font-size: 45px">{{pending_operations}}</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="12"  sm="6" md="4"  lg="3"  xl="2">
				<v-card class="dashboard-innbarrel-card">
					<v-card-title>
						<v-icon large left>mdi-barrel</v-icon>
						<span class="title font-weight-light">A Vazio</span>
					</v-card-title>
					<v-card-text style="font-size: 45px">{{balance['AV']}}</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="12"  sm="6" md="4"  lg="3"  xl="2">
				<v-card class="dashboard-innbarrel-card">
					<v-card-title>
						<v-icon large left>mdi-barrel</v-icon>
						<span class="title font-weight-light">B Vazio</span>
					</v-card-title>
					<v-card-text style="font-size: 45px">{{balance['BV']}}</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="12"  sm="6" md="4"  lg="3"  xl="2">
				<v-card class="dashboard-innbarrel-card">
					<v-card-title>
						<v-icon large left>mdi-barrel</v-icon>
						<span class="title font-weight-light">A Cheio</span>
					</v-card-title>
					<v-card-text style="font-size: 45px">{{balance['AC']}}</v-card-text>
				</v-card>
			</v-col>
			<v-col xs="12"  sm="6" md="4"  lg="3"  xl="2">
				<v-card class="dashboard-innbarrel-card">
					<v-card-title>
						<v-icon large left>mdi-barrel</v-icon>
						<span class="title font-weight-light">B Cheio</span>
					</v-card-title>
					<v-card-text style="font-size: 45px">{{balance['BC']}}</v-card-text>
				</v-card>
			</v-col>
			<v-col  xs="2">
				<v-card class="dashboard-innbarrel-card" color="grey lighten-4">
					<v-card-title>
						<span style="color: black" class="title">
							<b>Total</b>
						</span>
					</v-card-title>
					<v-card-text style="font-size: 45px; color: black">{{parseInt(balance['AV']) + parseInt(balance['BV']) + parseInt(balance['AC']) + parseInt(balance['BC'])}}</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<h2 class="mb-2">Tambores com os fornecedores</h2>

		<v-row class="mb-3">
			<v-card class="dashboard-innbarrel-card" @click="goToSupplierBalance('AV')">
				<v-card-title>
					<span class="title font-weight-light">A Vazio</span>
				</v-card-title>
				<v-card-text style="font-size: 45px">{{external['AV']}}</v-card-text>
			</v-card>
			<v-card class="dashboard-innbarrel-card" @click="goToSupplierBalance('BV')">
				<v-card-title>
					<span class="title font-weight-light">B Vazio</span>
				</v-card-title>
				<v-card-text style="font-size: 45px">{{external['BV']}}</v-card-text>
			</v-card>
			<v-card class="dashboard-innbarrel-card" color="grey lighten-4">
				<v-card-title>
					<span style="color: black" class="title">
						<b>Total</b>
					</span>
				</v-card-title>
				<v-card-text style="font-size: 45px; color: black">{{parseInt(external['AV']) + parseInt(external['BV'])}}</v-card-text>
			</v-card>
		</v-row>

		<h2 class="mb-2">Tambores que a minamel deve</h2>

		<v-row row wrap>
			<v-card class="dashboard-innbarrel-card" @click="goToSupplierBalance('AV')">
				<v-card-title>
					<span class="title font-weight-light">A Vazio</span>
				</v-card-title>
				<v-card-text style="font-size: 45px">{{owe['AV']}}</v-card-text>
			</v-card>
			<v-card class="dashboard-innbarrel-card" @click="goToSupplierBalance('BV')">
				<v-card-title>
					<span class="title font-weight-light">B Vazio</span>
				</v-card-title>
				<v-card-text style="font-size: 45px">{{owe['BV']}}</v-card-text>
			</v-card>
			<v-card class="dashboard-innbarrel-card" color="grey lighten-4">
				<v-card-title>
					<span style="color: black" class="title">
						<b>Total</b>
					</span>
				</v-card-title>
				<v-card-text style="font-size: 45px; color: black">{{parseInt(owe['AV']) + parseInt(owe['BV'])}}</v-card-text>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "DashboardInnBarrel",
	components: {},
	mounted () {
		this.TOKEN = sessionStorage.token ? sessionStorage.token : null;
		this.getBalance();
		this.getPendingOperations();
		this.getOutBarrelsBalance();
		this.getOweBarrelsBalance();
	},
	data () {
		return {
			pending_operations: "",
			balance: {
				AV: "",
				BV: "",
				AC: "",
				BC: ""
			},
			external: {
				AV: 0,
				BV: 0,
				AC: "",
				BC: ""
			},
			owe: {
				AV: 0,
				BV: 0
			},
			TOKEN: null
		};
	},
	methods: {
		goToOperations () {
			this.$router.push({
				name: "Operations"
			});
		},
		doRequest ({ endpoint, method, dataToSend }) {
			const url = this.CONFIG.VUE_APP_API_URL_V1 + endpoint;

			switch (method) {
			case "GET":
				return this.$http.get(url, dataToSend);
			case "POST":
				return this.$http.post(url, dataToSend);
			case "PUT":
				return this.$http.put(url, dataToSend);
			case "DELETE":
				return this.$http.delete(url, dataToSend);
			}
		},
		getPendingOperations () {
			this.doRequest({
				endpoint: "operations/get-pending",
				method: "GET",
				dataToSend: {
					params: {},
					headers: { token: this.TOKEN }
				}
			}).then(response => {
				this.pending_operations = response.body.data[0]["COUNT(*)"];
			});
		},
		goToSupplierBalance (product_type = "AV") {
			this.$router.push({
				name: 'SupplierBalance',
				params: {
					product_type
				}
			});
		},
		getOutBarrelsBalance () {
			this.doRequest({
				endpoint: "inventory-movements/get-out-barrels",
				method: "GET",
				dataToSend: {
					params: {},
					headers: { token: this.TOKEN }
				}
			}).then(response => {
				const data = response.body.data[0];

				this.external = {
					AV: Number.isNaN(parseInt(data.av_balance)) ? 0 : parseInt(data.av_balance),
					BV: Number.isNaN(parseInt(data.bv_balance)) ? 0 : parseInt(data.bv_balance)
				};
			});
		},
		getOweBarrelsBalance () {
			this.doRequest({
				endpoint: "inventory-movements/get-owe-barrels",
				method: "GET",
				dataToSend: {
					params: {},
					headers: { token: this.TOKEN }
				}
			}).then(response => {
				const data = response.body.data[0];

				this.owe = {
					AV: Number.isNaN(parseInt(data.av_balance)) ? 0 : parseInt(data.av_balance),
					BV: Number.isNaN(parseInt(data.bv_balance)) ? 0 : parseInt(data.bv_balance)
				};
			});
		},
		getBalance () {
			this.doRequest({
				endpoint: "inventory-movements/get-company-balance",
				method: "GET",
				dataToSend: {
					params: {},
					headers: { token: this.TOKEN }
				}
			}).then(response => {
				const balance = response.body.data[0];

				if (balance) {
					this.balance = {
						filled: true,
						AV: balance.AV,
						BV: balance.BV,
						AC: balance.AC,
						BC: balance.BC
					};
				}
			});
		}
	}
};
</script>

<style>
.dashboard-innbarrel-card {
	min-height: 124px;
	padding: 16px;
	margin: 16px 8px;
	max-width: 20vw;
	min-width: 13vw;
}
</style>
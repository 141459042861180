import moment from 'moment'

export default {
	install (Vue) {
		Vue.prototype.diffDays = (value, inverse) => {
			let diff = moment().diff(value, 'days')

			if (inverse) {
				diff = diff * (-1)
			}

			return diff
		}

		Vue.prototype.calculateRemaining = (date, interval) => {
			const eventTime = moment(date, 'DD/MM/YYYY HH:mm:ss').unix()
			const currentTime = moment().unix()
			const diffTime = eventTime - currentTime
			const durationTs = moment.duration(diffTime * 1000, 'milliseconds')
			let duration = ''
			let result = ''

			duration = moment.duration(durationTs - interval, 'milliseconds')

			if (duration.hours() <= 0 && (duration.minutes() < 0 || duration.seconds() < 0)) {
				return '00:00:00'
			}

			result = `${(duration.hours() < 10 ? '0' + duration.hours() : duration.hours())}:`
			result += `${(duration.minutes() < 10 ? '0' + duration.minutes() : duration.minutes())}:`
			result += `${(duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds())}`

			return result
		}

		Vue.prototype.getDate = (format) => moment().format(format)

		Vue.prototype.formatDate = (value, format) => moment(value).format(format)

		Vue.prototype.calculateTime = (value1, value2) => {
			let result = ''
			let ms = moment(value1, 'DD/MM/YYYY HH:mm:ss').diff(moment(value2, 'DD/MM/YYYY HH:mm:ss'))
			let d = moment.duration(ms)
			let hours

			hours = d.asHours()
			hours = Math.floor(hours)

			if (hours < 10) {
				hours = '0' + hours
			}

			result = hours + moment.utc(ms).format(':mm:ss')

			return result
		}

		Vue.prototype.addTime = (date, format, hour, minute, second) => {
			let aux

			aux = moment(date, format)
				.add(hour, 'hoours')
				.add(minute, 'minutes')
				.add(second, 'seconds')
				.format(format)

			return aux
		}
	}
}

<template>
  <v-form ref="form">
    <default-form-layout>
      <template v-slot:title>
        <h2>Alterar florada</h2>
      </template>
      <template v-slot:body>
        <v-col xs="12" md="4" class="pa-3">
          <v-text-field
            :rules="formRules"
            v-model="register.name"
            label="Nome"
            class="required"
            ref="name"
          />
        </v-col>

        <v-col xs="12" md="4" class="pa-3">
          <v-switch
            v-model="register.active"
            color="#b9041c"
            :label="register.active ? 'Ativo' : 'Inativo'"
          ></v-switch>
        </v-col>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text to="/flowery">Cancelar</v-btn>
      </template>
    </default-form-layout>
  </v-form>
</template>

<script>
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";
export default {
  name: "RegisterFlowery",
  components: {
    DefaultFormLayout,
  },
  data() {
    return {
      id: null,
      headerText: "Nova",
      formRules: [(v) => !!v || "Campo obrigatório!"],
      register: {
        id: "",
        active: 1,
        name: "",
      },
      status: [
        { id: 1, description: "Ativo" },
        { id: 2, description: "Inativo" },
      ],

      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.headerText = "Alterar ";
      this.getData();
    }
  },
  methods: {
    async getData() {
      const response = await this.minamelServices
        .FloweryServiceV2()
        .show(this.id);

      this.register = response.data;
    },
    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.id) {
        this.update();
        return;
      }

      this.create();
    },
    async create() {
      try {
        const response = await this.minamelServices
          .FloweryServiceV2()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Florada incluída com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/flowery");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
    async update() {
      try {
        const response = await this.minamelServices
          .FloweryServiceV2()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Floarada alterada com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/flowery");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

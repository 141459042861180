<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn @click="click()" icon v-on="on" :loading="loading">
        <v-icon color="#707070">clear</v-icon>
      </v-btn>
    </template>
    <span>Excluir</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultRemoveButton",
  props: {
    loading: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
      this.$emit("remove");
    },
  },
};
</script>

<style></style>

<template>
    <v-autocomplete
        :class="required ? 'required' : ''"
        v-model="selectedCidade"
        clearable
        chips
        deletable-chips
        :items="listCidades"
        :label="label ? label : 'Cidade'"
        :placeholder="placeholder"
        prepend-icon="mdi-database-search"
        :rules="rules"
    ></v-autocomplete>
</template>

<script>
export default {
	name: "CidadesComponent",
	data () {
		return {};
	},
	props: ['value', 'city', 'placeholder', 'label', 'required', 'rules'],
	computed: {
		listCidades() {
			let array_cidades = [];

			if (this.value) {
				array_cidades = this.ESTADOS_CIDADES.filter(element => {
					if (element.sigla == this.value) {
						return element.cidades;
					}
				});
				if (array_cidades) {
					array_cidades = array_cidades[0].cidades;
				}
			}
			return array_cidades;
		},
		selectedCidade: {
			get () {
				return this.city;
			},
			set (val) {
				this.$emit('updateCidade', val);
			}
		}
	}
};
</script>

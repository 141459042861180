<template>
  <v-dialog v-model="visible" :width="option.width" persistent>
    <v-card>
      <v-card-title class="headline primary" color="white" primary-title>
        {{ option.title }}
      </v-card-title>

      <v-card-text>
        <v-row row wrap>
          <v-col xs="12">
            <v-textarea
              v-if="option.type === 'textarea'"
              ref="fieldValue"
              v-model="option.value"
              :label="option.label"
              :maxlength="option.maxlength"
            ></v-textarea>
            <v-text-field
              v-if="option.type === 'textfield'"
              ref="fieldValue"
              v-model="option.value"
              :label="option.label"
              :maxlength="option.maxlength"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-if="option.type === 'cnpj'"
              ref="fieldValue"
              v-model="option.value"
              :label="option.label"
              :maxlength="option.maxlength"
              mask="##.###.###/####-##"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="option.list">
        <v-row v-for="item in option.list" :key="item.id">
          <div>
            <v-checkbox
              color="primary"
              v-model="item.checked"
              :label="item.description"
            ></v-checkbox>
          </div>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="saveBtn">{{ option.okBtn }}</v-btn>
        <v-btn
          color="error"
          text
          @click="cancelBtn"
          v-shortkey="['esc']"
          @shortkey="cancelBtn"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import jwt from "vue-jwt-decode";
const TOKEN = sessionStorage.token ? sessionStorage.token : null;

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dialog",
  data() {
    return {
      visible: false,
      option: {
        type: "textarea",
        width: "",
        title: "",
        value: "",
        label: "",
        maxlength: 254,
        validate: true,
        list: [],
        okBtn: "Salvar",
      },
      itensChecked: [],
      emailRules: [(v) => !!v || "Obrigatório informar o email"],
    };
  },
  methods: {
    show(option) {
      this.visible = true;
      this.option = option;
      this.setFocus();
    },
    hide() {
      this.visible = false;
    },
    saveBtn() {
      if (
        this.option.validate &&
        this.option.type !== "cnpj" &&
        !this.option.value.trim()
      ) {
        this.option.error("Informe o campo para continuar");
        this.setFocus();
        return;
      }

      if (
        this.option.validate &&
        this.option.type === "cnpj" &&
        !this.validCNPJ(this.option.value)
      ) {
        this.option.error("CNPJ informado não é válido");
        this.setFocus();
        return;
      }

      if (this.option.validate && this.option.list) {
        this.itensChecked = this.option.list.filter((obj) => {
          return obj.checked;
        });

        if (this.itensChecked.length <= 0) {
          this.option.error("Nenhum item foi selecionado");
          this.setFocus();
          return;
        }
      }

      if (this.option.validate && this.option.type === "cnpj") {
        this.checkCNPJ();
        return;
      }

      this.dispatch();
    },
    dispatch() {
      this.visible = false;
      this.resetFields();
      this.option.success(this.option.value, this.itensChecked);
    },
    checkCNPJ() {
      const params = {
        cnpj: this.option.value,
      };

      this.$root.$LoadingBar.show("Validando CNPJ");

      this.$http
        .get(this.CONFIG.API_URL + "validation/validCNPJ", {
          params: params,
          headers: { token: TOKEN },
        })
        .then((response) => {
          this.$root.$LoadingBar.hide();

          const message = response.body.message;

          if (message) {
            this.option.error(message);
            this.setFocus();
            return;
          }

          this.dispatch();
        })
        .catch((err) => {
          console.error(err);
          this.$root.$LoadingBar.hide();
        });
    },
    cancelBtn() {
      this.visible = false;
      this.resetFields();
    },
    setFocus() {
      this.$refs.fieldValue.focus();
    },
    resetFields() {
      this.$refs.fieldValue.resetValidation();
      if (this.option.list) {
        this.option.list = this.option.list.filter((obj) => {
          obj.checked = false;
          return obj;
        });
      }
    },
  },
};
</script>

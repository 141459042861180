<template>
    <div>
        <Drawer :items="items" v-model="drawer"></Drawer>

        <v-toolbar class="noPrint minamel-header" dense color="primary">
            <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
            <v-toolbar-title>
                <img width="120" style="color: white; margin-top: 6px" src="../../assets/logo.png" />
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                <template v-slot:activator="{ on }">
                    <v-btn icon large v-on="on">
                        <v-avatar :tile="avatar.tile" :size="avatar.size" color="grey lighten-4">
                            <img :src="avatarImg" alt="avatar" />
                        </v-avatar>
                    </v-btn>
                </template>
                <v-card>
                    <v-list style="cursor: pointer d-flex flex-column">
                        <v-list-item avatar @click="myUser()">
                            <v-list-item-avatar>
                                <img :src="avatarImg" alt="avatar" class="mx-auto" />
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ user.name }}
                                </v-list-item-title>
                                <v-list-item-sub-title>
                                    {{ user.email }}
                                </v-list-item-sub-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card-actions v-if="systemId != user.intranetId && listSystemDataArray.length > 1">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="backIntranet()">Intranet</v-btn>
                    </v-card-actions>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="logoff()">Sair</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-toolbar>

        <v-content class="mt-10">
            <router-view />
        </v-content>

        <Company v-if="companyVisible" :visible="companyVisible" v-on:setDialogVisible="actionCompany" />
    </div>
</template>

<script>
import jwt from "vue-jwt-decode";
import Drawer from "./Drawer";
import theme from "@/theme";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Navigation",
    async mounted() {
        let tokenDecoded = await jwt.decode(sessionStorage.token);

        this.user = tokenDecoded.userData;
        this.systemId = await sessionStorage.systemId;
        this.systemData = tokenDecoded.systemData[this.systemId];

        this.listSystemData = Object.assign({}, tokenDecoded.systemData);
        this.listAllSystemData = Object.assign({}, tokenDecoded.systemData);

        delete this.listSystemData[this.user.intranetId];

        this.listSystemDataArray = Object.keys(this.listSystemData).map(
            (key) => this.listSystemData[key]
        );

        this.items = this.systemData.MENUS;

        this.avatarImg = await this.getAvatar();
    },
    data: () => ({
        drawer: true,
        source: "",
        items: [],
        message: true,
        companyVisible: false,
        user: {},
        systemId: {},
        systemData: {},
        listSystemData: {},
        listSystemDataArray: {},
        listAllSystemData: {},
        avatarImg: "",
        avatar: {
            size: 32,
            tile: false,
            link: ""
        }
    }),
    methods: {
        async backIntranet() {
            await sessionStorage.setItem("systemId", this.user.intranetId);
            let newPath = this.listAllSystemData[this.user.intranetId].MENUS[0]
                .route;

            this.$router.push({ path: newPath });

            this.$root.$TransitionSystem.setSystem(this.user.intranetId);

            setTimeout(() => {
                window.location.reload();
            }, 350);
        },
        async getAvatar() {
            let response = await this.minamelServices
                .UserService()
                .getAvatar()
                .search({ id: this.user.id });

            return response && response.data ? response.data : null;
        },
        myUser() {
            this.$router.push({ path: "/my-user" });
        },
        logoff() {
            this.$root.$TransitionSystem.setSystem(this.user.intranetId);
            this.$vuetify.theme = theme;
            sessionStorage.clear();
            setTimeout(() => {
                this.$router.push({ path: "/login" });
            }, 350);
        },
        actionChangeDrawer(payload) {
            this.drawer = payload;
        },
        actionCompany(value) {
            this.companyVisible = value;
        }
    },
    components: {
        Drawer
    }
};
</script>

<style>
.minamel-header {
    height: 48px;
    position: fixed !important;
    width: 100% !important;
    z-index: 201 !important;
}
</style>

/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * @typedef {ProjectFormResponseService}
 */
export default class ProjectFormResponseService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'project-form-responses/';

    getAll(id, params) {
        return super.get(`/?${this.queryString(params)}`);
    }

    getByProject(id, params) {
        return super.get(`/actions/${id}/?${this.queryString(params)}`);
    }

    getByGroup(id, params) {
        return super.get(`/by-group/${id}/?${this.queryString(params)}`);
    }

    show(id) {
        return super.get(`/${id}`);
    }

    // store (data) {
    // 	return super.post('/', data);
    // }

    // update (id, data) {
    // 	return super.put(`/${id}`, data);
    // }

    // destroy (id) {
    // 	return super.delete(`/${id}`);
    // }
}

<template>
  <default-form-layout>
    <template v-slot:title>
      <h2>Tipos de Movimentação</h2>
    </template>
    <template v-slot:body>
      <v-row row>
        <v-col xs4 class="pa-3">
          <v-text-field v-model="register.name" label="Nome" class="required" />
        </v-col>
        <v-col xs4 class="pa-3">
          <v-select
            v-model="register.type"
            class="required"
            item-text="text"
            item-value="id"
            required="name"
            :items="movement_types"
            label="Tipo"
          ></v-select>
        </v-col>
      </v-row>
      <v-row row>
        <v-col xs4 class="pa-3">
          <v-select
            v-model="register.recipient_barrel_situation"
            class="required"
            item-text="text"
            item-value="id"
            :items="barrel_situations"
            label="Situação do barril do destinatário"
          ></v-select>
        </v-col>
        <v-col xs4 class="pa-3">
          <v-select
            v-model="register.sender_barrel_situation"
            class="required"
            item-text="text"
            item-value="id"
            :items="barrel_situations"
            label="Situação do barril do remetente"
          ></v-select>
        </v-col>
        <v-col xs4 class="pa-3">
          <v-select
            v-model="register.financial_generation"
            class="required"
            item-text="text"
            item-value="id"
            :items="financial_options"
            label="Geração financeira?"
          ></v-select>
        </v-col>
      </v-row>
      <v-row row>
        <v-col xs4 class="pa-3">
          <v-checkbox
            v-model="register.emit_receipt"
            label="Emite recibo"
            color="primary"
            value="1"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col xs4 class="pa-3">
          <v-checkbox
            v-model="register.fills_quantity"
            label="Preenche nome e quantidade do recibo"
            color="primary"
            value="1"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row row mt-4 mb-2>
        <v-col xs="12">
          <span class="caption">
            <span class="text-red">*</span>
            Campos obrigatórios
          </span>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
      <v-btn color="error" text @click="cancel">Cancelar</v-btn>
    </template>
  </default-form-layout>
</template>

<script>
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";
export default {
  components: { DefaultFormLayout },
  name: "RegisterMovementType",
  props: [],
  mounted() {
    const movementType = this.$route.params.movement_type;

    if (movementType) {
      this.register = {
        id: movementType.id,
        name: movementType.name,
        type: movementType.type,
        recipient_barrel_situation: movementType.recipient_barrel_situation,
        sender_barrel_situation: movementType.sender_barrel_situation,
        financial_generation: movementType.financial_generation,
        emit_receipt: `${movementType.emit_receipt}`,
        fills_quantity: `${movementType.fills_quantity}`,
      };
    }

    this.minamelServices
      .MovementTypeService()
      .checkOpeningBalanceMovementType()
      .search()
      .then((response) => {
        this.minamelServices.TreatRequest().result(this);

        this.openingBalanceExists = response.exists;
      })
      .catch(() => {
        this.minamelServices
          .TreatRequest()
          .error(this, this.CONSTANTS.texts.errors.connection);
      });
  },
  methods: {
    cancel() {
      this.$router.push({ name: "MovementTypes" });
    },
    validate() {
      if (this.register.name.length <= 0) {
        return "O campo nome deve ser informado";
      }

      if (this.register.type.length <= 0) {
        return "O campo tipo deve ser informado";
      }

      if (this.register.recipient_barrel_situation.length <= 0) {
        return "O campo situação do barril do destinatário deve ser informado";
      }

      if (this.register.sender_barrel_situation.length <= 0) {
        return "O campo situação do barril do remetente deve ser informado";
      }

      return false;
    },
    save() {
      const invalidMessage = this.validate();

      if (invalidMessage) {
        this.snackbar.color = "error";
        this.snackbar.message = invalidMessage;
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      this.send();
    },
    send() {
      this.$root.$LoadingBar.show(this.CONSTANTS.texts.dialog.saveSales);

      this.minamelServices
        .MovementTypeService()
        .createMovementType(this.register.id)
        .create(this.register)
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this, response)) {
            this.$router.push({ name: "MovementTypes" });
          }
        })
        .catch((error) => {
          this.minamelServices.TreatRequest().error(this, error);
          this.$router.push({ name: "MovementTypes" });
        });
    },
  },
  data() {
    return {
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
      openingBalanceExists: false,
      register: {
        id: "",
        name: "",
        type: "",
        recipient_barrel_situation: "",
        sender_barrel_situation: "",
        financial_generation: "",
        emit_receipt: "",
        fills_quantity: "",
      },
      movement_types: [
        { id: 1, text: "Entrada" },
        { id: 2, text: "Saída" },
      ],
      barrel_situations: [
        { id: "C", text: "Cheio" },
        { id: "V", text: "Vazio" },
      ],
      financial_options: [
        { id: "S", text: "Sim" },
        { id: "N", text: "Não" },
      ],
    };
  },
};
</script>

export default class AuthHelpers {
    static getUrlWithToken (imageUrl, folder) {
        if (!imageUrl || AuthHelpers.isBlob(imageUrl) || AuthHelpers.isBase64(imageUrl)) {
            return imageUrl;
        }

        const loggedData = AuthHelpers.getLoggedData();

        const token = loggedData.token;

        // eslint-disable-next-line no-undef
        const url = process.env.VUE_APP_API_CATALOG_URL;

        return `${url}api/files?file=${imageUrl}&folder=${folder}&token=${token}`;
    }

    static isBlob  (imageUrl) {
        return imageUrl.indexOf("blob:") == 0;
    }

    static isBase64 (imageUrl) {
        if (imageUrl.indexOf("data:") == -1) {
            return false;
        }

        if (imageUrl.indexOf(";base64") == -1) {
            return false;
        }

        return true;
    }

    static getLoggedData = function () {
        const jsonUser = localStorage.userData;
    
        const data = JSON.parse(jsonUser);
    
        return data;
    };
}
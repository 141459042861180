<template>
	<v-app id="inspire">
		<SnackBar ref="SnackBar" />
		<LoadingBar ref="LoadingBar" />
		<Dialog ref="Dialog" />
		<transition name="fade">
			<TransitionSystem ref="TransitionSystem"></TransitionSystem>
		</transition>

		<Navigation v-if="loading"></Navigation>
	</v-app>
</template>

<script>
import jwt from 'vue-jwt-decode'
import Navigation from './navigation/Navigation'
import SnackBar from './misc/SnackBar'
import LoadingBar from './misc/Loading'
import Dialog from './misc/Dialog'
import TransitionSystem from './misc/TransitionSystem'

import colors from 'vuetify/es5/util/colors'

export default {
	name: 'DashView',
	components: {
		'Navigation': Navigation,
		'SnackBar': SnackBar,
		'LoadingBar': LoadingBar,
		'Dialog': Dialog,
		TransitionSystem
	},
	data () {
		return {
			loading: false
		}
	},
	methods: {
		getColorName (colorHex) {
			for (const color in colors) {
				// eslint-disable-next-line no-prototype-builtins
				if (colors.hasOwnProperty(color)) {
					const element = colors[color]
					if (element != '' && element.base == colorHex) {
						return color
					}
				}
			}
		}
	},
	mounted () {
		const systemId = sessionStorage.systemId ? sessionStorage.systemId : null
		const systemData = sessionStorage.token ? jwt.decode(sessionStorage.token).systemData[systemId] : null
		if (systemData.color) {
			this.$vuetify.theme.primary = colors[this.getColorName(systemData.color)].darken4
			this.$vuetify.theme.secondary = colors[this.getColorName(systemData.color)]
		}
		this.$root.$SnackBar = this.$refs.SnackBar
		this.$root.$LoadingBar = this.$refs.LoadingBar
		this.$root.$Dialog = this.$refs.Dialog
		this.$root.$TransitionSystem = this.$refs.TransitionSystem
		this.loading = true
	}
}
</script>

<style scoped>
	.fade-enter-active, .fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>

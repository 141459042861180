<template>
  <default-list-layout>
    <template v-slot:title> <h2>Configurações</h2> </template>

    <template v-slot:actions>
      <v-tabs v-model="tab">
        <v-tab key="1" href="#1">Tipos de movimentação</v-tab>
        <v-tab key="2" href="#2">Parâmetros</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item value="1">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row row>
                    <v-col xs="12">
                      <v-row row>
                        <v-col xs="7">
                          <v-text-field
                            v-model="search.term"
                            ref="search"
                            label="Faça a sua busca"
                            clear-icon="close"
                            clearable
                            append-icon="search"
                            @click:append="getData"
                            @keyup="searchData"
                            @keyup.enter="getData"
                          />
                        </v-col>
                        <v-col xs="2" class="text-xs-left">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                large
                                color="normal"
                                v-on="on"
                                @click="filters = !filters"
                                v-shortkey="['ctrl', 'f']"
                                @shortkey="searchFocus"
                              >
                                <v-icon>filter_list</v-icon>
                              </v-btn>
                            </template>
                            <span>Clique para selecionar os filtros</span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="text-xs-right">
                          <v-btn color="primary" large @click="goToRegister"
                            >+ Incluir</v-btn
                          >
                        </v-col>
                      </v-row>

                      <v-expand-transition>
                        <v-row row wrap v-show="filters">
                          <v-col md="12">
                            <v-card class="elevation-1 pa-3 mb-4">
                              <v-row row>
                                <v-col xs="12">
                                  <v-row row>
                                    <v-col xs="6" class="caption"
                                      >Selecione os campos para fazer a
                                      busca</v-col
                                    >
                                    <v-col xs="6" class="text-xs-right">
                                      <v-btn
                                        @click="clearFilters"
                                        small
                                        color="primary"
                                      >
                                        <v-icon>clear</v-icon>Limpar todos os
                                        filtros
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row row>
                                <v-col
                                  v-for="field in search.fieldToSearch"
                                  :key="field.id"
                                  xs4
                                >
                                  <v-select
                                    clearable
                                    style="padding: 15px; margin-top: 15px"
                                    color="primary"
                                    :items="field.options"
                                    v-model="field.value"
                                    item-value="id"
                                    item-text="text"
                                    :label="field.description"
                                    @change="filter"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expand-transition>

                      <v-data-table
                        :headers="types.headers"
                        :items="table.items"
                        :loading="table.loading"
                        :no-results-text="table.noResultText"
                        :no-data-text="table.noDataText"
                        item-key="B1_COD"
                        :items-per-page="-1"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template
                          v-slot:[`item.recipient_barrel_situation`]="{ item }"
                        >
                          {{
                            item.recipient_barrel_situation == "C"
                              ? "Cheio"
                              : "Vazio"
                          }}
                        </template>

                        <template
                          v-slot:[`item.sender_barrel_situation`]="{ item }"
                        >
                          {{
                            item.sender_barrel_situation == "C"
                              ? "Cheio"
                              : "Vazio"
                          }}
                        </template>

                        <template v-slot:[`item.type`]="{ item }">
                          <v-chip
                            v-if="item.type == '1'"
                            color="green"
                            text-color="white"
                          >
                            <v-icon>arrow_forward</v-icon>Entrada
                          </v-chip>
                          <v-chip
                            v-if="item.type == '2'"
                            color="red"
                            text-color="white"
                          >
                            <v-icon>arrow_back</v-icon>Saída
                          </v-chip>
                        </template>

                        <template
                          v-slot:[`item.financial_generation`]="{ item }"
                        >
                          <v-chip v-if="item.financial_generation == 'S'">
                            <v-icon color="green">check</v-icon>
                          </v-chip>
                          <v-chip v-if="item.financial_generation == 'N'">
                            <v-icon color="red">close</v-icon>
                          </v-chip>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-menu offset-x left bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                color="#707070"
                                @click="edit(item)"
                                icon
                                v-on="on"
                              >
                                <v-icon>edit</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </template>
                        <template slot="no-data">
                          <div class="text-xs-center">
                            {{ table.noDataText }}
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="2">
          <v-card flat>
            <v-card-text>
              <v-card color="white" class="elevation-1 pa-4 mb-4">
                <v-row row>
                  <v-col xs4 class="pa-3">
                    <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          class="required"
                          v-model="formattedClosingDate"
                          label="Data de fechamento"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-br"
                        v-model="register.closing_date"
                        type="date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(register.closing_date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs4 class="pa-3">
                    <v-text-field
                      v-model="register.barrel_price"
                      label="Preço do tambor"
                      class="required"
                    />
                  </v-col>
                </v-row>
                <v-row row mt-4 mb-2>
                  <v-col xs="12">
                    <span class="caption">
                      <span class="text-red">*</span>
                      Campos obrigatórios
                    </span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="color: white"
                    color="green"
                    @click="updateParameters"
                    >Atualizar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:pagination>
      
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
        @change="getData"
      />
    </template>
    <v-tabs />
  </default-list-layout>
</template>

<script>
import DefaultListLayout from "@/shared/components/layout/DefaultListLayout.vue";
export default {
  name: "MovementTypes",
  components: { DefaultListLayout },
  computed: {
    formattedClosingDate: {
      get: function () {
        return this.formatDate(this.register.closing_date);
      },
      set: function (newDate) {
        this.register.closing_date = newDate;
      },
    },
  },
  mounted() {
    this.minamelServices
      .ParameterService()
      .getParameter()
      .search()
      .then((response) => {
        if (this.minamelServices.TreatRequest().result(this)) {
          this.register.closing_date = response.data[0]["closing_date"];
          this.register.barrel_price = response.data[0]["barrel_price"];
        }
      })
      .catch(() => {
        this.minamelServices
          .TreatRequest()
          .error(this, this.CONSTANTS.texts.errors.connection);
      });

    this.getData();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    validate() {
      if (!this.register.closing_date) {
        return "É necessário informar a data de fechamento";
      }

      if (!this.register.barrel_price) {
        return "É necessário informar o preço do tambor";
      }

      if (this.register.barrel_price <= 0) {
        return "O preço do tambor deve ser maior que zero";
      }
    },
    error(message) {
      this.$root.$LoadingBar.hide();
      this.snackbar.color = "error";
      this.snackbar.message = message;
      this.$root.$SnackBar.show(this.snackbar);
    },
    updateParameters() {
      const invalidMessage = this.validate();

      if (invalidMessage) {
        this.error(invalidMessage);
        return;
      }

      this.minamelServices
        .ParameterService()
        .updateParameter()
        .update(this.register)
        .then((response) => {
          this.minamelServices.TreatRequest().result(this, response);
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    filter() {
      this.getData();
    },
    clearFilters() {
      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        this.search.fieldToSearch[i].value = "";
      }
    },
    edit(item) {
      this.$router
        .push({
          name: "EditMovementType",
          params: {
            id: item.id,
            movement_type: item,
          },
        })
        .catch(() => {});
    },
    goToRegister() {
      this.$router.push({ name: "RegisterMovementType" });
    },
    loadTable(status) {
      this.table.loading = status;

      if (this.search.onSearch) {
        this.table.currentPage = 1;
        this.table.totalPages = 1;
      }

      if (status) {
        this.table.items = [];
        this.table.totalItems = 0;
      }
    },
    getData() {
      if (this.table.loading) {
        return;
      }

      const { sortBy, descending } = this.table.pagination;

      this.loadTable(true);
      this.table.noDataText = this.CONSTANTS.texts.table.loadingText;
      this.table.noResultText = this.CONSTANTS.texts.table.loadingText;
      this.table.disabled = false;

      let itensToSearch = [];

      for (let i = 0; i < this.search.fieldToSearch.length; i += 1) {
        if (this.search.fieldToSearch[i].value) {
          itensToSearch.push({
            field: this.search.fieldToSearch[i].field,
            value: this.search.fieldToSearch[i].value,
          });
        }
      }

      const params = {
        sort: sortBy,
        orientation: descending == null ? "" : !descending ? "ASC" : "DESC",
        page: this.table.pagination.page,
        rowsPerPage: this.table.pagination.perPage,
        term: this.search.term,
        fieldsToSearch: itensToSearch.length <= 0 ? "" : itensToSearch,
      };

      this.minamelServices
        .MovementTypeService()
        .getMovementType()
        .search(params)
        .then((response) => {
          this.minamelServices.TreatRequest().result(this);

          this.table.items = response.data;
          this.table.totalItems = response.total_records;

          if (this.search.onSearch) {
            this.search.onSearch = !this.search.onSearch;
          }

          this.table.pagination.pages = Math.ceil(
            this.table.totalItems / this.table.rowsPerPage
          );
        })
        .catch((error) => {
          console.error('error', error)
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        })
        .finally(() => {
          this.loadTable(false);
          this.table.noDataText = this.CONSTANTS.texts.table.noDataText;
          this.table.noResultText = this.CONSTANTS.texts.table.noResultText;
        });
    },
    back() {},
    searchFocus() {
      this.$refs.search.focus();
    },
    searchData() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.search.onSearch = true;
        this.getData();
      }, 800);
    },
  },
  watch: {
    "table.pagination.page": {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "table.pagination.sortBy": {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "table.rowsPerPage": {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      register: {
        closing_date: "",
        barrel_price: "",
      },
      tab: "1",
      types: {
        headers: [
          {
            text: "Nome",
            align: "left",
            value: "name",
          },
          {
            text: "Situação do tambor destinatário",
            align: "left",
            value: "recipient_barrel_situation",
          },
          {
            text: "Situação do tambor remetente",
            align: "left",
            value: "sender_barrel_situation",
          },
          { text: "Tipo", align: "left", value: "type" },
          {
            text: "Geração financeira?",
            align: "center",
            value: "financial_generation",
          },
          {
            text: "Ações",
            align: "left",
            value: "actions",
          },
        ],
        items: [],
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
      filters: false,
      search: {
        checkAll: true,
        onSearch: false,
        term: "",
        fieldToSearch: [
          {
            description: "Tipo de Movimentação",
            field: "type",
            value: "",
            options: [
              { id: 1, text: "Entrada" },
              { id: 2, text: "Saída" },
            ],
          },
          {
            description: "Situação do tambor destinatário",
            field: "recipient_barrel_situation",
            value: "",
            options: [
              { id: "C", text: "Cheio" },
              { id: "V", text: "Vazio" },
            ],
          },
          {
            description: "Situação do tambor remetente",
            field: "sender_barrel_situation",
            value: "",
            options: [
              { id: "C", text: "Cheio" },
              { id: "V", text: "Vazio" },
            ],
          },
          {
            description: "Geração financeira?",
            field: "financial_generation",
            value: "",
            options: [
              { id: "S", text: "Sim" },
              { id: "N", text: "Não" },
            ],
          },
        ],
      },
      table: {
        disabled: false,
        loading: false,
        totalPagesVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,
        currentPage: 1,
        totalPages: 1,
        rowsPerPage: 50,
        rowPerPageItems: this.CONSTANTS.arrays.table.itensToView,
        pagination: {
          page: 1,
          perPage: 50,
        },
        totalItems: 0,
      },
    };
  },
};
</script>

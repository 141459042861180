<template>
  <v-card
    class="elevation-0"
    :class="!projectId ? 'ma-2' : 'ma-0 project-margin-adjustment'"
  >
    <v-form ref="form">
      <v-row row wrap>
        <v-col xs="12" md="12" pa-3>
          <span class="required grey--text">* Preenchimento obrigatório.</span>
        </v-col>
        <v-col xs="12" md="4" pa-3>
          <v-autocomplete
            :rules="[formRules.required]"
            :cache-items="true"
            chips
            :deletable-chips="true"
            v-model="register.supplier_id"
            :items="suppliers"
            item-text="name"
            item-value="id"
            class="required"
            label="Produtor"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.name }}
              <span class="font-weight-bold">
                - COD.
                {{ data.item.id }}</span
              >
            </template>
          </v-autocomplete>
        </v-col>
        <v-col xs="12" md="4" pa-3 pt-4>
          <v-select v-model="register.action" :items="actions" label="Ação" />
        </v-col>
        <v-col xs="12" md="4" pa-3 pt-4>
          <v-combobox
            v-model="register.managing"
            :items="managing"
            label="Descrição"
          />
        </v-col>
        <v-col xs="12" md="4" pa-3 pt-4>
          <v-text-field
            v-model="register.localization"
            :items="managing"
            :label="getLocalizationDefinition"
          />
        </v-col>
        <v-col xs="12" md="4" pa-3 pt-4>
          <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            full-width
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :rules="[formRules.required]"
                :value="$functions.formatDate(register.date)"
                clearable
                label="Data"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="register.date"
              @change="datePickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xs="12" md="4" pa-3>
          <v-select
            chips
            deletable-chips
            v-model="register.product_system_variable_id"
            :items="systemVariables"
            item-value="id"
            item-text="value"
            label="Produto utilizado"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3>
          <v-text-field
            v-model="register.responsible"
            :rules="[formRules.required]"
            clearable
            label="Reponsável pela limpeza"
            class="required"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text :to="`${routeName}/cleaning-records`"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import jwt from "vue-jwt-decode";

export default {
  name: "CleaningRecordsRegister",
  props: {
    suppliers: { type: Array },
    projectId: { type: Number, default: null },
    routeName: { type: String, default: "/field-notes" },
  },
  data() {
    return {
      actions: ["Limpeza", "Manejo"],
      managing: [
        "Limpeza de Casa de Mel",
        "Limpeza de Equipamentos",
        "Colocação de Melgueira",
        "Roça de apiários",
      ],
      userData: null,
      formRules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      systemVariables: [],
      datePickerMenu: false,
      id: null,
      register: {},
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  async mounted() {
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;

    this.getSystemVariables();

    this.id = this.projectId
      ? this.$route.params.childrenId
      : this.$route.params.id;
    if (this.id) {
      await this.getData();
    }
  },
  computed: {
    getLocalizationDefinition() {
      if (this.register.action === "Manejo") {
        return "Apiário que está sendo manejado";
      }

      return "Oque está sendo higienizado";
    },
  },
  methods: {
    async getData() {
      const response = await this.minamelServices
        .CleaningRecordService()
        .show(this.id);

      this.register = response.data;
      this.register.date = this.register.date.split("T")[0];
    },
    async getSystemVariables() {
      const params = { type: "CLEANING_RECORD_PRODUCT" };

      const response = await this.minamelServices
        .SystemVariableService()
        .get(params);

      this.systemVariables = response.data;
    },
    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.register.id) {
        this.update();
        return;
      }

      this.create();
    },

    async create() {
      this.register.user_id = this.userData.id;
      this.register.type = "RECORD";

      try {
        const response = await this.minamelServices
          .CleaningRecordService()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Registro de limpeza incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`${this.routeName}/cleaning-records`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .CleaningRecordService()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Registro de limpeza atualizado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`${this.routeName}/cleaning-records`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao atualizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

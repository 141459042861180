<template>
  <div>
    <v-row v-if="item.type === 'TEXTAREA'">
      <v-col md="6">
        <v-text-field
          :readonly="onlyView"
          v-model="mutableItem.response"
          :label="item.label"
          :class="item.is_required === 1 ? 'required' : ''"
          prepend-icon="help"
        >
          <v-tooltip slot="prepend" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs">help</v-icon>
            </template>
            <span>{{ item.help_text }}</span>
          </v-tooltip>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="item.type === 'CHECKBOX'">
      <v-col md="6">
        <div class="mb-2">
          <v-tooltip slot="prepend" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs">help</v-icon>
            </template>
            <span>{{ item.help_text }}</span>
          </v-tooltip>
          <v-label :class="item.is_required === 1">
            {{ item.label }} *
          </v-label>
        </div>

        <v-checkbox
          v-for="(c, i) of JSON.parse(item.config).options"
          :key="i"
          :label="c.text.toUpperCase()"
          @change="checkItemOnCheckbox(c)"
          :input-value="isChecked(c)"
          color="primary"
          class="ml-4 ma-2"
          style="height: 22px"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row row wrap v-if="item.type === 'RADIO'">
      <v-col md="6">
        <v-tooltip slot="prepend" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs">help</v-icon>
          </template>
          <span>{{ item.help_text }}</span>
        </v-tooltip>

        <v-label class="d-block">
          <span :class="item.is_required === 1"> {{ item.label }} * </span>
        </v-label>

        <v-radio-group
          v-model="mutableItem.response"
          class="ml-4"
          :value-comparator="filterRadio"
        >
          <v-radio
            :readonly="onlyView"
            v-for="(c, i) of parsingItem(item)"
            :key="i"
            :label="c.text.toUpperCase()"
            :value="c"
            color="primary"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row row wrap v-if="item.type === 'SIGNATURE'">
      <v-col md="6">
        <v-tooltip slot="prepend" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs">help</v-icon>
          </template>
          <span>{{ item.help_text }}</span>
        </v-tooltip>
        <v-label class="d-block">
          <span :class="item.is_required === 1"> {{ item.label }} * </span>
        </v-label>
      </v-col>

      <v-col sm="12">
        <v-img v-if="!!image_src" :src="image_src" max-width="250"></v-img>

        <v-chip v-else dark class="mb-3 warning font-weight-bold">
          <v-icon left>info</v-icon>
          <span>Assinatura não encontrada</span>
        </v-chip>
      </v-col>
    </v-row>

    <v-row row wrap v-if="item.type === 'DRAW'">
      <v-col md="6">
        <v-tooltip slot="prepend" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs">help</v-icon>
          </template>
          <span>{{ item.help_text }}</span>
        </v-tooltip>
        <v-label class="d-block">
          <span :class="item.is_required === 1"> {{ item.label }} * </span>
        </v-label>
      </v-col>

      <v-col sm="12">
        <v-img v-if="!!image_src" :src="image_src" max-width="250"></v-img>

        <v-chip v-else dark class="mb-3 warning font-weight-bold">
          <v-icon left>info</v-icon>
          <span>Desenho não encontrado</span>
        </v-chip>
      </v-col>
    </v-row>

    <v-row row v-if="item.type === 'TEXTBLOCK'">
      <v-col md="12">
        <div ref="contentDocument"></div>
      </v-col>
    </v-row>

    <v-row row wrap v-if="item.type === 'FINAL_SIGNATURE'">
      <v-col md="6">
        <v-label class="d-block">
          <v-tooltip slot="prepend" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs">help</v-icon>
            </template>
            <span>Mostrado apenas em documentos finalizados.</span>
          </v-tooltip>
          <span :class="item.is_required === 1" class="text--secondary">
            {{ item.label }}
          </span>
        </v-label>
      </v-col>

      <v-col sm="12">
        <v-img
          v-if="!!item.help_text"
          :src="item.help_text"
          max-width="250"
        ></v-img>

        <v-chip v-else dark class="mb-3 warning font-weight-bold">
          <v-icon left>info</v-icon>
          <span>Assinatura não encontrada</span>
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ItemVisualization",

  props: {
    item: { type: Object },
    onlyView: { type: Boolean, defaul: false },
  },

  data() {
    return {
      areTrue: true,
      mutableItem: {},
      formRules: {
        required: (v) => !!v || "Campo obrigatório!",
      },
      radioGroup: null,
      checkboxGroup: null,
      image_src: "",
    };
  },

  mounted() {
    this.mutableItem = this.item;

    if (this.item.formFieldResponse && this.item.formFieldResponse.response) {
      if (this.item.type === "RADIO" || this.item.type === "CHECKBOX") {
        this.mutableItem.response = JSON.parse(
          this.item.formFieldResponse.response
        );
      } else {
        this.mutableItem.response =
          this.item.formFieldResponse.response.replace(/['"]+/g, "");
      }
    }

    if (this.item.type === "TEXTBLOCK") {
      this.renderHtml();
    }

    if (this.item.type === "SIGNATURE") {
      this.getImageSrc(this.item, "signatures");
    }

    if (this.item.type === "DRAW") {
      this.getImageSrc(this.item, "draws");
    }
  },

  methods: {
    filterRadio(group, field) {
      if (!this.mutableItem.response) {
        return false;
      }

      if (field.id == this.mutableItem.response.id) {
        return true;
      }

      return false;
    },
    checkItemOnCheckbox(field) {
      if (!this.mutableItem.response) {
        this.mutableItem.response = [];
      }

      const hasOnResponse = this.mutableItem.response.some(
        (response) => response.id == field.id
      );

      if (hasOnResponse) {
        this.mutableItem.response = this.mutableItem.response.filter(
          (response) => response.id != field.id
        );
      } else {
        this.mutableItem.response.push(field);
      }
    },
    isChecked(field) {
      if (!this.mutableItem.response) {
        return false;
      }

      const result = this.mutableItem.response.some(
        (response) => response.id == field.id
      );

      return result;
    },
    async getImageSrc(item, directory) {
      try {
        const path = `${directory}/${item.response}`;

        const response = await this.minamelServices
          .MidiaService()
          .show("", { params: { path: path }, responseType: "blob" });

        this.image_src = window.URL.createObjectURL(response);

        return this.image_src;
      } catch (error) {
        console.error(error);
        return "";
      }
    },

    parsingItem(item) {
      const jsonParsed = JSON.parse(item.config);

      return jsonParsed.options;
    },
    renderHtml() {
      const text = this.item.help_text.replace(/^"|"$/g, "");

      this.$refs.contentDocument.innerHTML = text;
    },
  },
};
</script>

<template>
  <div class="noPrint" @mouseenter="openSidebar" @mouseleave="closeSidebar">
    <v-navigation-drawer
      fixed
      persistent
      :permanent="true"
      :mini-variant="isSmall"
      class="minamel-navigation-drawer"
      clipped
      enable-resize-watcher
      app
    >
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.name"
          :to="item.route"
          link
        >
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="sidebarRoute">{{
              $functions.decode_utf8(item.name)
            }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <ShortcutsHelp
      v-if="helpVisible"
      :visible="helpVisible"
      v-on:setDialogVisible="actionCloseDialog"
    />
  </div>
</template>

<script>
import jwt from "vue-jwt-decode";

import ShortcutsHelp from "../view/help/Shortcuts";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Drawer",
  components: {
    ShortcutsHelp: ShortcutsHelp,
  },
  props: {
    items: { type: Array, required: true },
    value: { type: Boolean, default: false },
  },
  mounted() {
    this.userData = sessionStorage.token
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.systemId = sessionStorage.systemId ? sessionStorage.systemId : null;
    this.systemData = sessionStorage.token
      ? jwt.decode(sessionStorage.token).systemData[this.systemId]
      : null;

    this.roles = this.userData.roles;
  },
  data() {
    return {
      size: 1,
      userData: {},
      systemId: {},
      systemData: {},
      helpVisible: false,
      roles: [],
    };
  },
  computed: {
    isSmall() {
      return this.size === 1;
    },
  },
  methods: {
    openSidebar() {
      this.size = 2;
    },
    closeSidebar() {
      this.size = 1;
    },
    openHelp() {
      this.$router.push({ name: "MovementTypes" });
    },
    actionCloseDialog(value) {
      this.helpVisible = value;
    },
  },
};
</script>

<style>
.helpMenu {
  bottom: 0;
  position: absolute;
  margin-bottom: 5px;
  width: 100%;
}

.sidebarRoute {
  padding-left: 10px;
}

.minamel-navigation-drawer {
  top: 48px !important;
  max-height: calc(100% - 48px) !important;
}
</style>

<template>
	<div style="height: 50vh">
		<div id="map" style="height: 100% !important;"></div>
		<template v-if="!!newGoogle && !!newMap">
			<slot />
		</template>
	</div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
	name: "GoogleMapLoader",
	data() {
		return {
			newGoogle: null,
			newMap: null,
			markers: [],
			infoWindow: null
		};
	},
	mounted() { this.initializeMap() },
	props: {
		apiaries: { type: Array, required: true }
	},
	watch: {
		apiaries: {
			handler() { this.addMarkers(this.apiaries) },
		}
	},
	methods: {
		async initializeMap() {
			await GoogleMapsApiLoader({
				libraries: ["geometry", "geocoder"],
				apiKey: this.CONFIG.VUE_APP_API_KEY_GOOGLE_MAPS
			}).then(async (google) => { this.newGoogle = google });

			const { Map } = this.newGoogle.maps;
			const mapContainer = this.$el.querySelector("#map");

			this.newMap = await new Map(mapContainer);
			this.infoWindow = new this.newGoogle.maps.InfoWindow();
			this.addMarkers(this.apiaries);
		},
		addMarkers(apiaries) {
			if (!this.newGoogle) return;
			
			if (this.markers.length > 0) {
				this.markers.forEach((marker) => marker.setMap(null));
				this.markers = [];
			}

			const bounds = new this.newGoogle.maps.LatLngBounds();

			apiaries.forEach((apiary) => {
				const marker = new this.newGoogle.maps.Marker({
					map: this.newMap,
					position: {
						lat: Number(apiary.latitude),
						lng: Number(apiary.longitude),
					},
				});

				marker.addListener("click", () => {
					this.infoWindow.setContent(`
						<div style="padding: 0 10px;">
							<div style="display: flex; align-items: center; gap: 5px;">
								<span style="font-size: 16px;">Apiário:</span>
								<span style="font-size: 16px;font-weight: bold;">${apiary.name?.toUpperCase() ?? ""}</span>
							</div>
							<div style="display: flex; align-items: center; gap: 5px; padding-top: 5px;">
								<span style="font-size: 16px;">Produtor:</span>
								<span style="font-size: 16px;font-weight: bold;">${apiary.supplier?.name ?? ""}</span>
							</div>
							<div style="display: flex; align-items: center; gap: 5px; padding-top: 5px;">
								<span style="font-size: 16px;">Grupo:</span>
								<span style="font-size: 16px;font-weight: bold;">${apiary.apiaryGroup?.name ?? "Sem Grupo"}</span>
							</div>
							<div style="display: flex; align-items: center; gap: 5px; padding-top: 5px;">
								<span style="font-size: 16px;">Coordenadas:</span>
								<span style="font-size: 16px;font-weight: bold;">${apiary.latitude ?? ""}, ${apiary.longitude ?? ""}</span>
							</div>
							<div style="display: flex; align-items: center; justify-content: end; padding-top: 10px;">
								<button id="details-${apiary.id}" style="padding: 8px 12px; background-color: #007BFF; color: white; border: none; border-radius: 4px; cursor: pointer;">
									Ver Detalhes
								</button>
							</div>	
						</div>
					`);

					this.infoWindow.open(this.newMap, marker);

					const observer = new MutationObserver(() => {
						const detailsButton = document.getElementById(`details-${apiary.id}`);
						
						if (detailsButton) {
							detailsButton.addEventListener("click", () => {
								window.location.href = `/apiaries/edit/${apiary.id}`;
							});

							observer.disconnect();
						}
					});

					observer.observe(document.body, { childList: true, subtree: true });
				});

				this.markers.push(marker);
				bounds.extend(marker.position);
			});

			this.newMap.fitBounds(bounds);
		}
	}
};
</script>
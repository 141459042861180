<template>
  <v-card class="elevation-0 pa-2">
    <v-form ref="form">
      <v-row row wrap>
        <v-col xs="12" md="12" pa-3>
          <span class="required grey--text">* Preenchimento obrigatório.</span>
        </v-col>

        <v-col xs="12" md="4" pa-3>
          <v-text-field
            :rules="[formRules.required]"
            v-model="register.name"
            label="Descrição"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3>
          <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            full-width
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :rules="[formRules.required]"
                :value="$functions.formatDate(register.date)"
                clearable
                label="Data"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="register.date"
              @change="datePickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <ProjectNotifications
        v-if="id"
        :projectId="projectId"
        :projectDate="register"
      />

      <v-divider></v-divider>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn
          color="error"
          text
          :to="`/project/edit/${projectId}/notifications`"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import ProjectNotifications from "./ProjectNotifications";

export default {
  name: "ProjectDatesRegister",
  props: {
    routeName: { type: String, default: "/notifications" },
  },
  components: {
    ProjectNotifications,
  },
  data() {
    return {
      formRules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      datePickerMenu: false,
      id: null,
      projectId: null,

      register: {
        name: "",
        notifications: ["sfsdfsdfsdf"],
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },
  async mounted() {
    this.projectId = this.$route.params.id;
    this.id = this.$route.params.childrenId;

    if (this.id) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      const response = await this.minamelServices
        .ProjectNotificationService()
        .show(this.id);

      this.register = response.data;
      this.register.date = this.register.date.split("T")[0];
    },

    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.register.id) {
        this.update();
        return;
      }

      this.create();
    },

    async create() {
      this.register.project_id = this.projectId;

      try {
        const response = await this.minamelServices
          .ProjectNotificationService()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Notificação incluída com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`/project/edit/${this.projectId}/notifications`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .ProjectNotificationService()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Notificação atualizada com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`/project/edit/${this.projectId}/notifications`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao atualizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

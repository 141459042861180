<template>
  <v-card-actions class="pa-3">
    <v-spacer />

    <v-select
      outlined
      label="STATUS"
      :items="formResponseStatus"
      v-model="model.status"
      dense
      class="form-response-status-select"
      v-if="areEditting"
    />

    <v-btn v-if="areEditting" dark color="green" @click="onSave" class="mx-2">
      Salvar
    </v-btn>

    <v-btn color="error" text @click="goBack">
      {{ !areEditting ? "Voltar" : "Cancelar" }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import ModelManagementMixin from "@/helpers/ModelManagementMixin";
import EFormResponseStatus from "@/modules/forms/domain/enums/FormResponseStatusEnum";
export default {
  name: "FormResponseRegisterActions",
  mixins: [ModelManagementMixin],
  props: {
    areEditting: Boolean,
  },
  data() {
    return {
      formResponseStatus: [
        { value: EFormResponseStatus.NO_ANSWERED, text: "Não Respondido" },
        { value: EFormResponseStatus.IN_PROGRESS, text: "Em Progresso" },
        { value: EFormResponseStatus.ANSWERED, text: "Respondido" },
        { value: EFormResponseStatus.APPROVED, text: "Aprovado" },
        { value: EFormResponseStatus.DISAPPROVED, text: "Reprovado" },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSave() {
      this.$emit("onSave");
    },
  },
};
</script>

<style>
.form-response-status-select {
  max-width: 180px !important;
  margin-top: -28px !important;
  margin-bottom: -55px !important;
  padding: 0px !important;
}
</style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="dialog.open"
    min-width="300"
    width="800"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog.open = toggle(dialog.open)">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ dialog.text }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form">
          <v-row wrap>
            <v-col xs="7" md7>
              <v-autocomplete
                :cache-items="true"
                chips
                :deletable-chips="true"
                class="required"
                v-model="register.circleMapId"
                :items="circleMaps"
                :search-input.sync="term"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                clearable
                label="Círculos de mapa"
                :rules="formRules"
              ></v-autocomplete>
            </v-col>

            <v-col class="text-xs-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="save" large color="primary" v-on="on">
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Clique para adicionar um círculo de mapa</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
        <v-row wrap>
          <v-col xs="12">
            <v-data-table
              locale="pt-BR"
              :headers="table.headers"
              :items="projectCircleMaps"
              hide-default-footer
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">
                  {{ props.item.circleMap ? props.item.circleMap.name : "" }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.circleMap ? props.item.circleMap.radius : "" }}
                </td>
                <td class="text-xs-left">
                  <div
                    v-bind:style="{
                      backgroundColor: props.item.circleMap
                        ? props.item.circleMap.color
                        : '',
                      width: '20px',
                      height: '20px',
                      borderRadius: '10px',
                    }"
                  ></div>
                </td>
                <td class="text-xs-right">
                  <v-btn @click="remove(props.item.id)" icon flat>
                    <v-icon color="#707070">delete</v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-col>

          <v-col xs="12" class="text-xs-center pa-3">
            <v-pagination
              v-model="table.pagination.page"
              :length="table.pagination.pages"
              :total-visible="table.pagination.totalVisible"
              prev-icon="arrow_left"
              next-icon="arrow_right"
              primary
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProjectCircleMaps",
  props: {
    project: { type: Object },
    dialog: { type: Object },
  },
  data() {
    return {
      formRules: [(v) => !!v || "Campo obrigatório!"],
      register: {},
      circleMaps: [],
      projectCircleMaps: [],
      table: {
        headers: [
          { text: "Nome", value: "name", align: "left" },
          { text: "Raio (m)", value: "radius", align: "left" },
          { text: "Cor", value: "color", align: "left" },
          {
            text: "",
            value: "actions",
            sortable: false,
          },
        ],
        pagination: {
          page: 1,
          perPage: 5,
          totalVisible: 3,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  async mounted() {
    await this.getCircleMaps();
    await this.getData();
  },
  methods: {
    async getCircleMaps() {
      const params = {
        active: 1,
      };

      const response = await this.minamelServices
        .CircleMapServiceV2()
        .get(params);

      this.circleMaps = response.data;
    },

    async getData() {
      const params = {
        page: this.table.pagination.page,
        perPage: this.table.pagination.perPage,
        projectId: this.project.id,
      };

      const response = await this.minamelServices
        .ProjectCircleMapService()
        .getAll(params);

      this.projectCircleMaps = response.data;
      this.table.items = response.data;
      this.table.pagination.pages = response.meta.last_page;
    },

    async save() {
      if (!this.$refs.form.validate()) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      let exists;

      if (this.projectCircleMaps) {
        exists = this.projectCircleMaps.find(
          (element) => element.circle_map_id === this.register.circleMapId
        );
      }

      if (exists) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Círculo de mapa já existe";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      this.create();
    },

    async create() {
      this.register.projectId = this.project.id;

      const response = await this.minamelServices
        .ProjectCircleMapService()
        .create(this.register);

      if (response) {
        this.snackbar.color = "success";
        this.snackbar.message = "Círculo de mapa adicionado";
        this.$root.$SnackBar.show(this.snackbar);

        this.register = {};

        await this.getData();
      }
    },

    toggle(value) {
      if (value) {
        return false;
      }
      return true;
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices
        .ProjectCircleMapService()
        .delete(id);

      if (res) {
        await this.getData();
      }
    },

    alertConfirm(ask) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },
  },
  watch: {
    "dialog.open"() {
      if (this.dialog.open) {
        this.getData();
      }
    },

    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row row wrap>
          <v-col md="12">
            <v-card class="elevation-1 pa-3 mb-4">
              <v-form ref="form" v-if="register" v-model="formValid">
                <v-row row wrap>
                  <v-col xs="12">
                    <v-subheader>
                      <h2>{{ headerText }} grupo de usuário</h2>
                    </v-subheader>
                  </v-col>

                  <v-divider></v-divider>

                  <v-col xs="12" md="4" class="pa-3">
                    <v-text-field
                      :rules="formRules"
                      v-model="register.name"
                      label="Nome"
                      class="required"
                      ref="name"
                    />
                  </v-col>

                  <v-col xs="12" md="4" class="pa-3">
                    <v-switch
                      v-model="register.active"
                      color="#b9041c"
                      :label="register.active ? 'Ativo' : 'Inativo'"
                    ></v-switch>
                  </v-col>

                  <v-col xs="12" md="4" class="pa-3">
                    <v-select
                      class="required"
                      clearable
                      color="primary"
                      multiple
                      chips
                      :items="permissions"
                      v-model="register.roles"
                      item-value="id"
                      item-text="name"
                      label="Permissões"
                      :rules="[verifyRoles]"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.group + ` - ` + item.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption"
                          >+{{ register.roles.length - 1 }}
                          {{
                            register.roles.length > 2
                              ? "Permissões"
                              : "Permissão"
                          }}</span
                        >
                      </template>
                    </v-select>
                  </v-col>

                  <v-col xs="12" md="4" class="pa-3">
                    <v-select
                      class="required"
                      clearable
                      color="primary"
                      multiple
                      chips
                      deletable-chips
                      :items="systems"
                      v-model="registerSystems"
                      item-value="id"
                      item-text="name"
                      label="Sistemas"
                      :rules="[verifySystems]"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="color: white" color="green" @click="save"
                  >Salvar</v-btn
                >
                <v-btn color="error" text @click="cancel">Cancelar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegisterUserGroup",
  data: () => ({
    headerText: "Novo",
    formValid: true,
    formRules: [(v) => !!v || "Campo obrigatório!"],
    roles: [],
    permissions: [],
    edit: false,
    groupCode: "",
    blockButton: false,
    snackbar: {
      message: "",
      visible: false,
      color: "success",
      timeout: 6000,
      x: "right",
      y: "top",
      multiLine: false,
    },
    groupData: {},
    register: {
      id: "",
      active: true,
      name: "",
      roles: [],
      systems_ids: [],
    },
    systems: [],
    registerSystems: [],
    status: [
      { id: 1, description: "Ativo" },
      { id: 2, description: "Inativo" },
    ],
  }),
  async mounted() {
    this.register.id = this.$route.params.id;

    await this.getRoles();

    if (this.register.id) {
      this.headerText = "Editar";
      this.edit = true;
      await this.getUserGroup();

      await this.getUserGroupRoles();
    } else {
      this.edit = false;
    }

    await this.getSystems();
  },
  methods: {
    verifyRoles(roles) {
      if (!roles || !roles.length || roles.length <= 0) {
        return "É necessário informar ao menos uma permissão!";
      }
      return true;
    },
    verifySystems(systems) {
      if (!systems || !systems.length || systems.length <= 0) {
        return "É necessário informar ao menos um sistema!";
      }
      return true;
    },
    arraySystemsIds() {
      let array = [];
      for (let index = 0; index < this.registerSystems.length; index++) {
        const element = this.registerSystems[index];
        if (element.id) {
          array.push(element.id);
        } else {
          array.push(element);
        }
      }
      return array;
    },
    async getSystems() {
      await this.minamelServices
        .SystemService()
        .getSystem()
        .search()
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this)) {
            this.systems = response.data;
            if (this.register.id) {
              this.populateSystems();
            }
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    populateSystems() {
      if (this.register.systems_ids) {
        this.register.systems_ids = this.register.systems_ids
          .split(", ")
          .map(function (value) {
            return parseInt(value, 10);
          });

        for (let index = 0; index < this.systems.length; index++) {
          const flowObj = this.systems[index];
          if (this.register.systems_ids.includes(flowObj.id)) {
            this.registerSystems.push(flowObj);
          }
        }
      }
    },
    cancel() {
      this.$router.push({ name: "UserGroups" });
    },
    save() {
      let formValidate = this.$refs.form.validate();

      if (formValidate) {
        this.send();
      }
    },
    send() {
      let headerSystems = {};

      headerSystems.systems_ids = this.arraySystemsIds();

      this.minamelServices
        .UserGroupService()
        .createUserGroup(this.register.id)
        .create(this.register)
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this, response)) {
            headerSystems.id = response.data.insertId
              ? response.data.insertId
              : this.register.id;
            this.minamelServices
              .UserGroupService()
              .updateUserGroupSystems()
              .create(headerSystems)
              .then((response) => {
                if (
                  this.minamelServices.TreatRequest().result(this, response)
                ) {
                  this.$router.push({
                    name: "UserGroups",
                  });
                }
              })
              .catch((error) => {
                this.minamelServices.TreatRequest().error(this, error);
                this.$router.push({
                  name: "UserGroups",
                });
              });
          }
        })
        .catch((error) => {
          this.minamelServices.TreatRequest().error(this, error);
          this.$router.push({
            name: "UserGroups",
          });
        });
    },
    async getUserGroupRoles() {
      await this.minamelServices
        .RoleService()
        .getUserGroupRoles()
        .search({ id: this.register.id })
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this)) {
            this.register.roles = response.data.map((role) => {
              return `${role.role_id}.${role.permission_id}`;
            });
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    async getPermissions() {
      await this.minamelServices
        .RoleService()
        .getPermissions()
        .search()
        .then((response) => {
          if (this.minamelServices.TreatRequest().result(this)) {
            this.permissions = [];
            this.roles.map((role) => {
              this.permissions.push({ header: role.name });

              response.data.map((permission) => {
                this.permissions.push({
                  name: permission.name,
                  group: role.name,
                  id: `${role.id}.${permission.id}`,
                });
              });
              this.permissions.push({ divider: true });
            });
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    async getRoles() {
      await this.minamelServices
        .RoleService()
        .getRole()
        .search()
        .then(async (response) => {
          let treat = await this.minamelServices.TreatRequest().result(this);

          if (treat) {
            this.roles = response.data;
            await this.getPermissions();
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
    async getUserGroup() {
      await this.minamelServices
        .UserGroupService()
        .getUserGroup()
        .search({ id: this.register.id })
        .then(async (response) => {
          let treat = await this.minamelServices.TreatRequest().result(this);
          if (treat) {
            const userGroup = response.data[0];

            this.register = {
              id: userGroup.id,
              active: userGroup.active == "1",
              name: userGroup.name,
              roles: [],
              systems_ids: userGroup.systems_ids,
            };
          }
        })
        .catch(() => {
          this.minamelServices
            .TreatRequest()
            .error(this, this.CONSTANTS.texts.errors.connection);
        });
    },
  },
};
</script>

export default class ComponentHelpers {
    static registerDebounce (func, wait = 1200) {
        let timeoutVar;

        return (...args) => {
            clearTimeout(timeoutVar);

            timeoutVar = setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    }
}

<template>
  <v-row>
    <v-col class="form-response-summary-container">
      <form-response-summary-item
        label="Projeto"
        :info="formResponse.project ? formResponse.project.name : ''"
      />
    </v-col>

    <v-col class="form-response-summary-container">
      <form-response-summary-item
        label="Produtor/Agregado"
        :info="formResponse.supplier ? formResponse.supplier.name : ''"
      />
    </v-col>

    <v-col class="form-response-summary-container">
      <form-response-summary-item
        label="Data do Preenchimento"
        :info="answered_at"
      />
    </v-col>

    <v-col class="form-response-summary-container">
      <form-response-summary-item label="Status" :info="serialized_status" />
    </v-col>
  </v-row>
</template>

<script>
import FormResponseSummaryItem from "./FormResponseSummaryItem";
import EFormResponseStatus from "../../../domain/enums/FormResponseStatusEnum";

export default {
  name: "FormResponseSummary",
  components: {
    FormResponseSummaryItem,
  },
  props: {
    formResponse: undefined,
  },
  computed: {
    answered_at() {
      if (!this.formResponse || !this.formResponse.answered_at) {
        return "Não Definido";
      }

      const serializedDate = this.$functions.formatDate(this.formResponse);

      return serializedDate;
    },

    serialized_status() {
      if (!this.formResponse || !this.formResponse.status) {
        return "Não Definido";
      }

      return EFormResponseStatus.serializedStatus(this.formResponse.status);
    },
  },
};
</script>

<style>
.form-response-summary-container {
  min-width: 120px;
}
</style>

<template>
	<v-card
		class="elevation-0"
		:class="!projectId ? 'ma-2' : 'ma-0 project-margin-adjustment'"
	>
		<v-form ref="form">
			<v-row row wrap>
				<v-col xs="12" md="12" pa-3>
					<span class="required grey--text"
						>* Preenchimento obrigatório.</span
					>
				</v-col>
				<v-col xs="12" md="4" pl-3 pa-3>
					<v-autocomplete
						chips
						:deletable-chips="true"
						:rules="[formRules.required]"
						v-model="register.supplier_id"
						:items="suppliers"
						item-text="name"
						item-value="id"
						label="Produtor"
						class="required"
						@change="onChangeSupplier()"
					>
						<template slot="item" slot-scope="data">
							{{ data.item.name }}
							<span class="font-weight-bold">
								- COD.
								{{ data.item.id }}</span
							>
						</template>
					</v-autocomplete>
				</v-col>

				<v-col xs="12" md="4" pa-3>
					<v-select
						chips
						:deletable-chips="true"
						:rules="[formRules.required]"
						v-model="register.swarm_type_system_variable_id"
						:items="systemVariables"
						item-value="id"
						item-text="value"
						label="Ação"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 mt-2>
					<v-text-field
						type="number"
						:rules="[formRules.required]"
						v-model="register.quantity"
						label="Quantidade"
						suffix="Enxames"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3>
					<v-select
						chips
						:deletable-chips="true"
						:rules="[formRules.validApiary, formRules.required]"
						v-model="register.apiary_import_key"
						:items="mutableApiaries"
						item-text="name"
						item-value="import_key"
						label="Apiário"
						class="required"
					/>
				</v-col>

				<v-col xs="12" md="4" pa-3 pt-4>
					<v-menu
						v-model="datePickerMenu"
						:close-on-content-click="false"
						full-width
						max-width="290"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								:rules="[formRules.required]"
								:value="$functions.formatDate(register.date)"
								clearable
								label="Data"
								readonly
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							locale="pt-br"
							v-model="register.date"
							@change="datePickerMenu = false"
						></v-date-picker>
					</v-menu>
				</v-col>
			</v-row>

			<v-divider></v-divider>

			<v-card-actions class="pa-3">
				<v-spacer></v-spacer>
				<v-btn style="color: white" color="green" @click="save"
					>Salvar</v-btn
				>
				<v-btn
					color="error"
					flat
					:to="`${routeName}/bee-swarm-captures`"
					>Cancelar</v-btn
				>
			</v-card-actions>
		</v-form>
	</v-card>
</template>
<script>
import jwt from "vue-jwt-decode";

export default {
	name: "BeeSwarmCapturesRegister",
	props: {
		apiaries: { type: Array },
		suppliers: { type: Array },
		projectId: { type: Number, default: null },
		routeName: { type: String, default: "/field-notes" }
	},
	data () {
		return {
			userData: null,
			formRules: {
				required: (value) => !!value || "Campo obrigatório",
				validApiary: () =>
					!!this.register.supplier_id || "Selecione um produtor"
			},
			systemVariables: [],
			datePickerMenu: false,
			id: null,
			register: {},
			snackbar: {
				message: "",
				visible: false,
				color: "success",
				timeout: 6000,
				x: null,
				y: "bottom",
				multiLine: false
			},
			mutableApiaries: this.apiaries
		};
	},
	async mounted () {
		this.userData = (await sessionStorage.token)
			? jwt.decode(sessionStorage.token).userData
			: null;
		this.mutableApiaries = this.apiaries;

		this.getSystemVariables();

		this.id = this.projectId
			? this.$route.params.childrenId
			: this.$route.params.id;
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		async getData () {
			const response = await this.minamelServices
				.BeeSwarmCaptureService()
				.show(this.id);

			this.register = response.data;
			this.register.date = this.register.date.split("T")[0];
		},

		async getSystemVariables () {
			const params = { type: "BEE_SWARM_CAPTURE_TYPE" };

			const response = await this.minamelServices
				.SystemVariableService()
				.get(params);

			this.systemVariables = response.data;
		},

		async save () {
			let formValidate = this.$refs.form.validate();

			if (!formValidate) {
				this.snackbar.color = "warning";
				this.snackbar.message = "Verifique os campos em destaque.";
				this.$root.$SnackBar.show(this.snackbar);
				return;
			}

			if (this.register.id) {
				this.update();
				return;
			}

			this.create();
		},
		onChangeSupplier () {
			const filtered = this.apiaries.filter((obj) => {
				return this.register.supplier_id === obj.supplier_id;
			});

			if (filtered.length) {
				this.mutableApiaries = filtered;
				this.register.apiary_id = "";
				return;
			}

			if (!this.register.supplier_id) {
				this.mutableApiaries = this.apiaries;
				this.register.apiary_id = "";
				return;
			}

			this.mutableApiaries = [];
		},

		async create () {
			this.register.user_id = this.userData.id;
			this.register.type = "RECORD";

			try {
				const response = await this.minamelServices
					.BeeSwarmCaptureService()
					.create(this.register);

				if (response) {
					this.snackbar.color = "success";
					this.snackbar.message =
						"Integração de enxame incluída com sucesso!";
					this.$root.$SnackBar.show(this.snackbar);

					this.$router.push(`${this.routeName}/bee-swarm-captures`);
				}
			} catch (err) {
				this.snackbar.color = "error";
				this.snackbar.message =
					"Ocorreu um erro ao realizar o cadastro.";
				this.$root.$SnackBar.show(this.snackbar);
			}
		},

		async update () {
			try {
				const response = await this.minamelServices
					.BeeSwarmCaptureService()
					.update(this.id, this.register);

				if (response) {
					this.snackbar.color = "success";
					this.snackbar.message =
						"Integração de enxame atualizada com sucesso!";
					this.$root.$SnackBar.show(this.snackbar);

					this.$router.push(`${this.routeName}/bee-swarm-captures`);
				}
			} catch (err) {
				this.snackbar.color = "error";
				this.snackbar.message =
					"Ocorreu um erro ao atualizar o cadastro.";
				this.$root.$SnackBar.show(this.snackbar);
			}
		}
	}
};
</script>

<template>
  <v-form ref="form" v-model="valid">
    <default-form-layout>
      <template v-slot:title>
        <h2>Alterar Usuário</h2>
      </template>
      <template v-slot:body>
        <v-row row wrap>
          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="formRules"
              v-model="register.name"
              label="Nome"
              class="required"
              ref="name"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="[() => validFields.app_login]"
              :loading="loading.app_login"
              v-model="register.app_login"
              label="CPF"
              @input="verifyUniqueCpf"
              mask="###.###.###-##"
              class="required"
              ref="app_login"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="[...formRules, () => validFields.email]"
              :loading="loading.email"
              v-model="register.email"
              @input="verifyUniqueEmail"
              label="Email"
              class="required"
              ref="email"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="[...formRules, () => validFields.login]"
              :loading="loading.login"
              v-model="register.login"
              label="Usuário"
              @input="verifyUniqueLogin"
              class="required"
              ref="login"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              v-model="register.user_group_id"
              :items="userGroups"
              item-value="id"
              item-text="name"
              label="Grupo de usuário"
              class="required"
              :rules="formRules"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              v-model="register.password"
              :append-icon="!e1 ? 'visibility' : 'visibility_off'"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
              label="Senha"
              ref="password"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select :items="apiaryGroups" v-model="register.user_apiary_groups" label="Liberar acesso aos grupos" chips multiple clearable
            item-text="name" item-value="id" />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-switch
              v-model="register.active"
              color="#b9041c"
              :label="register.active ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          style="color: white"
          color="green"
          @click="save"
          :disabled="!valid"
          :loading="loading.save"
        >
          Salvar
        </v-btn>
        <v-btn color="error" text to="/users">Cancelar</v-btn>
      </template>
    </default-form-layout>
  </v-form>
</template>

<script>
import Helpers from "@/helpers/helpers.js";
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";

export default {
  components: { DefaultFormLayout },
  name: "RegisterUsers",
  data() {
    return {
      id: null,
      headerText: "Novo",
      formRules: [(v) => !!v || "Campo obrigatório!"],
      e1: false,
      userGroups: [],
      apiaryGroups: [],
      valid: false,
      register: {
        id: "",
        name: "",
        app_login: "",
        login: "",
        password: "",
        active: 1,
      },
      status: [
        { id: 1, description: "Ativo" },
        { id: 0, description: "Inativo" },
      ],
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
      verifyUniqueLogin: () => {},
      verifyUniqueCpf: () => {},
      verifyUniqueEmail: () => {},
      loading: {
        login: false,
        app_login: false,
        email: false,
        save: false,
      },
      validFields: {
        login: null,
        app_login: null,
        email: null,
      },
    };
  },
  created() {
    this.verifyUniqueLogin = Helpers.registerDebounce(
      () => this.checkField("login"),
      500
    );
    this.verifyUniqueCpf = Helpers.registerDebounce(
      () => this.checkField("app_login"),
      500
    );
    this.verifyUniqueEmail = Helpers.registerDebounce(
      () => this.checkField("email"),
      500
    );
  },
  async mounted() {
    await this.getUserGroups();
    await this.getApiaryGroups();

    this.id = this.$route.params.id;
    if (this.id) {
      this.e1 = true;
      this.headerText = "Alterar ";
      this.getData();
    }
  },
  methods: {
    async getApiaryGroups() {
      const response = await this.minamelServices
        .ApiaryGroupServiceV2()
        .getAll({ getByUser: false });

      this.apiaryGroups = response.data;
    },

    async checkField(field) {
      try {
        if (!this.register[field]) {
          return;
        }

        this.loading[field] = true;

        const params = {
          field: field,
          value: this.register[field],
        };

        const response = await this.minamelServices
          .UserServiceV2()
          .show("/validate", { params });

        if (response.id != this.register.id) {
          this.validFields[field] = this.getFieldRuleDescription(field);
        }
      } catch (error) {
        this.validFields[field] = null;
      } finally {
        this.loading[field] = false;

        this.$refs[field].validate();
      }
    },

    getFieldRuleDescription(field) {
      if (field == "login") {
        return "Esse nome de usuário já está cadastrado";
      }

      if (field == "app_login") {
        return "CPF já cadastrado";
      }

      if (field == "email") {
        return "Email já cadastrado";
      }

      return null;
    },

    async getUserGroups() {
      const res = await this.minamelServices
        .UserGroupService()
        .getUserGroup()
        .search({ active: 1 });

      this.userGroups = res.data;
    },

    async getData() {
      const response = await this.minamelServices.UserServiceV2().show(this.id);

      this.register = response.data;

      if (response.data?.user_apiary_groups) {
        this.register.user_apiary_groups = response.data.user_apiary_groups.map((group) => group.apiary_group);
      }
    },

    async save() {
      try {
        this.loading.save = true;

        let formValidate = this.$refs.form.validate();

        if (!formValidate) {
          this.loading.save = false;

          this.snackbar.color = "warning";
          this.snackbar.message = "Verifique os campos em destaque.";
          this.$root.$SnackBar.show(this.snackbar);
          return;
        }

        if (this.id) {
          this.update();
          return;
        }

        this.create();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.save = false;
      }
    },

    async create() {
      try {
        const response = await this.minamelServices
          .UserServiceV2()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Usuário incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/users");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .UserServiceV2()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Usuário alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push("/users");
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>
